import { Avatar, Box, Divider, makeStyles } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import React, { useState } from 'react';
import { DropDown } from './DropDown';
import { useSession } from '../../hooks/session';
import { useOne } from '../../hooks/api';
import { DefaultComp, DefaultZone, IComp, IZone } from '../../models/emp';
import { getTelWithoutHyphen } from '../../utils/utils';

interface calling {
  image: string;
  caller: string;
  hp: string;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: '7px',
    alignItems: 'center',
    // width: '77px',
    minWidth: '7rem',
  },
  margin: {
    marginLeft: '13%',
  },
  img: {
    width: '24px',
    height: '24px',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  divi: {
    margin: '10% 5%',
  },
  mw7: {
    minWidth: '7rem',
  },
});

interface Props {}

export const CallDropDown: React.FC<Props> = () => {
  const classes = useStyles();
  const { session } = useSession();
  const [isOpen, setOpen] = useState<HTMLElement | null>(null);

  const { data: comp } = useOne<IComp>('comp', {
    cmd: 'read',
    id: session.user.cid,
  });

  const { data: zone } = useOne<IZone>('zone', {
    cmd: 'read',
    id: session.user.zone,
  });

  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (session.user.id && (comp?.tel || zone?.mobile))
      setOpen(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log('handleClose.event>>>', event);
    setOpen(null);
  };
  const open = Boolean(isOpen);

  const Callers = ({ image, caller, hp }: calling) => (
    <>
      <span className={classes.root}>
        <Avatar src={image} variant='rounded' className={classes.img} />
        <a className={classes.margin} href={hp}>
          {caller}
        </a>
      </span>
    </>
  );

  return (
    <DropDown
      labelIcon={<CallIcon />}
      contents={
        <Box p={0.5}>
          <Callers
            image='/static/img/hometelier_logo.svg'
            caller='사무실'
            hp={`tel: ${getTelWithoutHyphen(
              (session.user.zone === 1 ? comp?.tel : zone?.mobile) || ''
            )}`}
          />
        </Box>
      }
      isOpen={isOpen}
      open={open}
      handleChange={handleChange}
      handleClose={handleClose}
    />
  );
};
