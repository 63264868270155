import { DefaultEmp, IEmp } from './emp';

export interface IApprove {
  id: number;
  createdate?: string;
  workdate?: string;
  name: string;
  kind?: number;
}

export interface IApproveEmp {
  id: number;
  createdate?: string;
  workdate?: string;
  approve: number;
  emp: number;
  currseq?: number;
  title: string;
  sdt: string;
  edt: string;
  cnt?: number;
  contents?: string;
  state?: number;
  kind?: number;
}

export interface IApproveLine {
  id: number;
  createdate?: string;
  workdate?: string;
  approve: IApprove;
  seq: number;
}

export const DefaultApprove: IApprove = {
  id: 0,
  name: '',
  kind: 1,
};

export const DefaultApproveEmp: IApproveEmp = {
  id: 0,
  approve: 0,
  emp: 1,
  currseq: 0,
  title: '',
  sdt: '',
  edt: '',
  cnt: 0,
  state: 0,
  kind: 0,
};

export const DefaultApproveLine: IApproveLine = {
  id: 0,
  approve: DefaultApprove,
  seq: 0,
};
