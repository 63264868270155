import React, { useEffect } from 'react';
import { Card, IconButton, Divider, makeStyles } from '@material-ui/core';
import { AxiosError } from 'axios';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useMany, useRemover } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import { IApproveEmp } from '../../models/approve';
import { ApprovalEdit } from './ApprovalEdit';
import { Modal } from '../HT/Modal';

interface Props {}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
    fontSize: '1.2em',
  },
  container: {
    padding: '3%',
    marginBottom: '1em',
  },
  topcontent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2% 0 4%',
    marginTop: '.1em',
  },
  seq: {
    margin: '0 0 .4em .2em',
  },
  bottomcontent: {
    padding: '0 4%',
    lineHeight: 1.5,
  },
  divi: {
    margin: '.6em',
  },
  btn: {
    marginLeft: '.5em',
  },
});

export const ApprovalHistory: React.FC<Props> = () => {
  const classes = useStyles();
  const { session } = useSession();
  const { data: approveemp, refetch: refetchApproveEmp } = useMany<
    IApproveEmp[]
  >('approveemp', {
    cmd: 'gets-by-emp',
    emp: session.user.id,
  });
  // console.log('approveemp>>>>>>>>>>>>', approveemp);

  const approvalDelete = useRemover(
    'approveemp',
    () => {
      console.log('deleteap>>');
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const state = (st: number | undefined) => {
    switch (st) {
      case 0:
        return <FiberManualRecordIcon style={{ color: '#43A047' }} />;
      case 1:
        return <FiberManualRecordIcon style={{ color: '#616161' }} />;
      case 8:
        return <FiberManualRecordIcon style={{ color: '#FF1744' }} />;
      case 9:
        return <FiberManualRecordIcon style={{ color: '#F5F5F5' }} />;
      default:
        return '';
    }
  };
  const currseq = (cs: number | undefined) => {
    switch (cs) {
      case 0:
        return '결재 대기';
      case 1:
        return '매니저 마스터 승인 중';
      case 2:
        return '지점장 승인 중';
      case 3:
        return '결재 완료';
      default:
        return '기타';
    }
  };

  const dateSetting = (date: any) => {
    const dateDivid = date.split('-');
    return `${dateDivid[1]}월${dateDivid[2]}일`;
  };

  useEffect(() => {
    refetchApproveEmp();
  });

  return (
    <div style={{ padding: '3%' }}>
      <div className={classes.root}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIosIcon />
        </IconButton>
        이용내역
      </div>
      {approveemp?.map((ae) => (
        <div key={ae.id}>
          <Card className={classes.container}>
            <div className={classes.topcontent}>
              <div className='flex'>
                <span>{state(ae.state)}</span>
                <span className={classes.seq}>{currseq(ae.currseq)}</span>
              </div>
              <div>
                <ApprovalEdit approveEmp={ae} />
                <Modal
                  title='결재 취소'
                  msg='결재를 취소하시겠습니까?'
                  clickMsg='삭제'
                  isSubmit={() => approvalDelete.mutate(ae.id)}
                />
              </div>
            </div>
            <Divider className={classes.divi} />
            <div className={classes.bottomcontent}>
              <span className='mr1'>{ae.title}</span>
              <span>
                {ae.sdt !== ae.edt
                  ? `${ae.sdt.split('-')[0]}년 ${dateSetting(
                      ae.sdt
                    )}~${dateSetting(ae.edt)}`
                  : `${ae.sdt.split('-')[0]}년 ${dateSetting(ae.sdt)}`}
              </span>
              <span>
                {ae.cnt !== 0
                  ? `(${ae.cnt}일)`
                  : ae.kind === 0
                  ? '(1일)'
                  : ae.kind === 1
                  ? '(오전반차)'
                  : '(오후반차)'}
              </span>
              <div>{ae.contents}</div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};
