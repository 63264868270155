import { Button, IconButton } from '@material-ui/core';
import { AxiosError } from 'axios';
import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useRef,
  useState,
} from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useMany, useRemover, useSaver } from '../hooks/api';
import { useSession } from '../hooks/session';
import { Store } from '../hooks/store';
import { IBillService, IBillSvc } from '../models/bill';
import { DefaultEmp, IEmp } from '../models/emp';
import { DefaultUpfile, IUpfile } from '../models/etc';
import {
  DefaultBoard,
  DefaultBoardConfirm,
  IBoard,
  IBoardConfirm,
  IFolder,
} from '../models/folder';
import { callNative } from '../utils/native-connector';
import { Fetch } from './Fetch';
import { Sample } from './Sample';

interface Props {}

export const Samples: React.FC<Props> = () => {
  // const params = useParams();
  // console.log('samples.params>>', params);

  const { session, login, logout, getItem } = useSession();

  // const {
  //   isError,
  //   error,
  //   isLoading,
  //   data: folder,
  // }: UseQueryResult<IFolder, Error> = useQuery<IFolder, Error>(
  //   ['folder', 1],
  //   async () => {
  //     const res = await fetch(
  //       `${process.env.REACT_APP_SERVER_URL}/api/hometel/mgr/0/folders/${1}`
  //     );
  //     return res.json();
  //   }
  // );
  const dayTostring = (newday: number) => {
    switch (newday) {
      case 1:
        return '월';
      case 2:
        return '화';
      case 3:
        return '수';
      case 4:
        return '목';
      case 5:
        return '금';
      case 6:
        return '토';
      default:
        return '일';
    }
  };
  const dateToisoString = (newDate: Date) =>
    newDate.toISOString().split('T')[0];
  const [date, setDate] = useState(dateToisoString(new Date()));
  const [day, setDay] = useState(dayTostring(new Date().getDay()));
  const dateView = date.split('-');
  const qqq = `${dateView[0]}${dateView[1]}${dateView[2]}`;

  const onChangeDateBefore = () => {
    const newDate = new Date(date);
    const beforeDate = new Date(newDate.setDate(newDate.getDate() - 1));
    setDay(dayTostring(beforeDate.getDay()));
    setDate(dateToisoString(beforeDate));
  };
  const onChangeDateAfter = () => {
    const newDate = new Date(date);
    const afterDate = new Date(newDate.setDate(newDate.getDate() + 1));
    setDay(dayTostring(afterDate.getDay()));
    setDate(dateToisoString(afterDate));
  };

  const { data: emps } = useQuery('emps', Store.findAll<IEmp>('emp'));

  // console.log('emps>>>>>>>>>>>>>>>', emps);

  // const { data: billservices, refetch: refetchBillServices } = useMany<
  //   IBillService[]
  // >(
  //   'billservice',
  //   {
  //     cmd: 'finds-by-mgremp-visitdt',
  //     mgremp: session.user.id,
  //     visitdt: date,
  //   },
  //   );
  const { data: billservices, refetch: refetchBillServices } = useMany<
    IBillSvc[]
  >(
    'billsvc',
    {
      cmd: 'finds-by-mgr',
      dt: date,
      emp: session.user.id,
    },
    session.user.name ? undefined : [] // 초기값이 있으면 서버로 안감!!
  );
  // console.log('bbb>', session.user, billservices);

  const { data: folders } = useMany<IFolder[]>('folder', {
    cmd: 'finds-by-usetype',
    usetype: 3,
  });
  // console.log('folder>>>>>>>>>>>>', folders);

  const onSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    callNative({ cmd: 'ttt', payload: { id: 1, name: 'hong' } });
  };

  const clicks = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    console.log('mouse clicked');
  };
  const { data: loginUser, refetch: refetchLoginUser } = useMany<IEmp>('emp', {
    cmd: 'read',
    id: session.user.id,
  });
  // console.log('login확인>>>>>>>', loginUser);

  const billServiceRemover = useRemover(
    'billservice',
    () => {
      refetchBillServices();
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const registSaver = useSaver<IEmp>(
    'emp',
    (newEmp) => {
      console.log('newEmp>>', newEmp);
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );
  const registRemover = useRemover(
    'emp',
    () => {
      console.log('deleteEmp>>');
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const boardSaver = useSaver<IBoard>(
    'board',
    (newb) => console.log('newb>>', newb),
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const boardConfirmSaver = useSaver<IBoardConfirm>(
    'boardConfirm',
    (newbc) => console.log('newbc>>', newbc),
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const profileImgSaver = useSaver<IUpfile>(
    'upfile',
    (newpi) => console.log('newpi>>', newpi),
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const { data: board } = useMany<IBoard[]>('board', {
    cmd: 'finds-by-folder',
    folder: 5,
  });

  // const AboutBoard = board && board[0];

  // console.log('aboutboard', AboutBoard);

  // const { data: userImg, refetch: refetchUserImg } = useMany<IUpfile>(
  //   'upfile',
  //   {
  //     cmd: 'create',
  //     id: loginUser?.profileimg,
  //   }
  // );
  const { data: boardConfirm } = useMany<IBoardConfirm[]>('boardconfirm', {
    cmd: 'finds-by-emp',
  });
  // console.log('boardconfirm>>>>>>>>>>', boardConfirm);

  const testRef = useRef(null);

  const [imga, setimga] = useState<FileList | null>(null);

  return (
    <>
      <h2>
        loginUser: <small>{session.user.id}</small> {session.user.name} : (
        {emps?.map((emp: IEmp) => (
          <small key={emp.id}>{emp.name} |</small>
        ))}
        )
      </h2>
      <button
        onClick={() =>
          session.didLogin
            ? logout()
            : login({ loginid: '01011112222', passwd: '12456' }, (err) => {
                console.log('aa', err.response?.status);
                if (err.response?.status === 404) {
                  console.log('잘못된 정보야');
                }
              })
        }
      >
        {!session.didLogin && 'LogIn 오류'}
      </button>
      <button
        onClick={() =>
          session.didLogin
            ? logout()
            : login({ loginid: '01088351112', passwd: '123456' }, (err) => {
                console.log('aa', err.response?.status);
              })
        }
      >
        {!session.didLogin && 'LogIn 이동혁'}
      </button>
      <button
        onClick={() =>
          session.didLogin
            ? logout()
            : login({ loginid: '01011112222', passwd: '123456' }, (err) => {
                console.log('aa', err.response?.status);
              })
        }
      >
        {session.didLogin ? 'LogOut' : 'LogIn 홈텔'}
      </button>
      <button
        onClick={() =>
          session.didLogin
            ? logout()
            : login({ loginid: '01023161632', passwd: '121212' }, (err) => {
                console.log('aa', err.response?.status);
              })
        }
      >
        {session.didLogin ? 'LogOut' : 'LogIn 테스트'}
      </button>
      {/* <h3>param of {params.usertype}</h3> */}
      {/* <Sample title='Sample Title' onSubmit={onSubmit} /> */}
      {/* <button onClick={clicks}>TTT</button> */}
      {/* <hr /> */}
      {/* <Fetch uri='/dummy.json'>
        {(data) => <pre>{JSON.stringify(data)}</pre>}
      </Fetch> */}

      <hr />
      <IconButton onClick={onChangeDateBefore}>-</IconButton>
      <span>
        {dateView[1]}월 {dateView[2]}일({day})
      </span>
      <IconButton onClick={onChangeDateAfter}>+</IconButton>
      {billservices?.map((bs) => (
        <p key={bs.id}>
          {bs.addr} - {bs.visitdt} -{' '}
          {bs.haspet ? <strong>{bs.petmemo}</strong> : ''}
          {/* :: {getItem(bs.item || 0).name}|{' '} */}
          <span
            onClick={async () => {
              console.log('ddd>>', bs.state);
              const res = await Store.post('billservice', {
                cmd: 'update-state',
                state: 3,
                id: bs.id,
              });
              refetchBillServices();
            }}
            aria-hidden='true'
          >
            STATE ({bs.state})
          </span>
          |
          {/* <button
            onClick={() => {
              billServiceRemover.mutate(bs.id);
            }}
          >
            Delete
          </button> */}
        </p>
      ))}

      <hr />
      {/* <button
        onClick={() => {
          if (loginUser) {
            const newer = {
              ...loginUser,
              offday: 9,
            };
            registSaver.mutate(newer);
          }
        }}
      >
        Regist
      </button> */}
      {/* <button
        onClick={() => {
          registRemover.mutate(15);
        }}
      >
        rm
      </button> */}
      {/* <button
        onClick={() => {
          const newer = {
            ...loginUser,
          };
          registSaver.mutate(newer);
        }}
      >
        ㄹㄹㄹㄹㄹㄹㄹㄹㄹㄹㄹㄹ
      </button> */}
      {/* <Button
        variant='contained'
        color='secondary'
        onClick={() => {
          const newbb = {
            ...DefaultBoard,
            folder: 5,
            title: '청소 후 물기제거',
            foldertag: 5,
            contents:
              '화장실청소를 마무리한 후 걸렐와 스퀴즈를 통해 모든 물기를 제거한다.',
          };
          boardSaver.mutate(newbb);
        }}
      >
        게시글 작성
      </Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => {
          // const newbb = {
          //   ...AboutBoard,
          //   folder: 5,
          //   title: '앱 이용에 문제가 발생시 바로 연락 부탁드립니다.',
          //   contents:
          //     '앱을 통해 서비스 진행 중 오류가 발생할 시 바로 연락 부탁드립니다. 빠르게 연락해주셔야 문제 없이 진행이 되오니 부탁드립니다.',
          //   foldertag: 1,
          // };
          if (AboutBoard)
            boardSaver.mutate({
              ...AboutBoard,
              foldertag: 5,
              // title: '첫 베딩 이용고객',
              // contents: '이용중이시던 커버를 가지런히 접어둡니다.',
            });
        }}
      >
        게시글변경
      </Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => {
          if (loginUser && AboutBoard)
            boardConfirmSaver.mutate({
              ...DefaultBoardConfirm,
              emp: loginUser.id,
              // title: 'New 매니저앱이 출시되었습니다.',
              // contents:
              //   '새로운 매니저앱이 출시되었습니다. 더욱 더 편하게 이용하실 수 있도록 만들었으니 많은 이용 부탁드립니다.',
            });
        }}
      >
        확인 생성
      </Button> */}
      <Button
        variant='contained'
        color='secondary'
        onClick={() => {
          if (imga) {
            const newpi = {
              ...DefaultUpfile,
              originalname: imga[0].name,
              mimetype: imga[0].type,
              filename: imga[0].name,
              sysname: imga[0].name,
              fsize: imga[0].size,
              relpath: `/hometel/0/${qqq}`,
            };
            profileImgSaver.mutate(newpi);
          }
        }}
        //   // const newer = {
        //   //   ...loginUser,
        //   //   profileimg:
        //   // };
        //   // registSaver.mutate(newer);
        // }}
        // onClick={async () => {
        //   console.log('upfile>>>', imga && imga[0]);
        //   if (imga) {
        //     const res = await Store.post('upfile', {
        //       cmd: 'create',
        //       originalname: imga[0].name,
        //       filename: imga[0].name,
        //       mimetype: imga[0].type,
        //       fsize: imga[0].size,
        //       relpath: `/hometel/0/${qqq}`,
        //       remark: '',
        //     });
        //   }
        // }}
      >
        이미지저장
      </Button>
      <div>
        <input
          type='file'
          accept='image/*'
          ref={testRef}
          id='profileimg'
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            console.log(evt.currentTarget.files);
            setimga(evt.currentTarget.files);
          }}
        />
        <Button
          onClick={() => {
            if (imga) {
              const nowImageUrl = URL.createObjectURL(imga[0]);
              console.log('testRef>>>', nowImageUrl);
            }
          }}
        >
          22
        </Button>
      </div>
    </>
  );
};
