import React from 'react';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { useMany, useSaver } from '../../hooks/api';
import {
  DefaultBoardConfirm,
  IBoard,
  IBoardConfirm,
  IFolder,
} from '../../models/folder';
import { IEmp } from '../../models/emp';

interface Props {
  title: string;
  folder: IFolder;
  loginuser?: IEmp;
}

const useStyles = makeStyles({
  divi: {
    padding: '0.3em',
  },
  container: {
    padding: '2% 4% 4% 4%',
  },
  title: {
    padding: '1%',
    margin: '2%',
    fontSize: '1.2em',
  },
  contents: {
    marginBottom: '0.4em',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '9px',
    },
    '& .MuiCardContent-root': {
      padding: '9px 16px',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  typo: {
    marginRight: '1em',
  },
});

export const Checklist: React.FC<Props> = ({ title, folder, loginuser }) => {
  const classes = useStyles();
  const { data: boards, refetch: refetchboards } = useMany<IBoard[]>('board', {
    cmd: 'gets-with-confirm',
    folder: folder.id,
    emp: loginuser?.id,
  });
  // console.log('board>>>>>>>>>>>', boards);

  // const { data: boardconfirm } = useMany<IBoardConfirm[]>('boardconfirm', {
  //   cmd: 'finds-by-emp',
  //   id: loginuser?.id,
  // });

  const boardConfirmSaver = useSaver<IBoardConfirm>(
    'boardconfirm',
    (newbc) => {
      // console.log('newbc>>', newbc);
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  return (
    <div
      className={clsx({
        noneDisplay: boards?.every((board) => board.didconfirm),
      })}
    >
      <Divider className={classes.divi} />
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        {boards?.map(
          (board) =>
            !board.didconfirm && (
              <Card
                className={clsx(classes.contents, {
                  // noneDisplay: board.id === boardconfirm?.board,
                })}
                key={board.id}
              >
                <CardContent className={classes.content}>
                  <div className={classes.typo}>{board.contents}</div>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      if (loginuser) {
                        boardConfirmSaver.mutate({
                          ...DefaultBoardConfirm,
                          board: board.id,
                          emp: loginuser.id,
                        });
                        refetchboards();
                      }
                    }}
                  >
                    확인
                  </Button>
                </CardContent>
              </Card>
            )
        )}
      </div>
    </div>
  );
};
