import React, { RefObject, useRef, useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';

interface Props {
  open: boolean;
  isOpen: HTMLElement | null;
  labelIcon: JSX.Element;
  contents: JSX.Element;
  handleChange: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const DropDown: React.FC<Props> = ({
  open,
  isOpen,
  handleChange,
  handleClose,
  labelIcon,
  contents,
}) => {
  const dropdownRef = useRef(null);

  return (
    <>
      <IconButton
        onClick={handleChange}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
      >
        {labelIcon}
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        anchorEl={isOpen}
        open={open}
        transitionDuration={500}
        marginThreshold={6}
        disableRestoreFocus
      >
        {contents}
      </Popover>
    </>
  );
};
