import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { useSession } from './hooks/session';
import { setNativeConnector } from './utils/native-connector';
import { ChatList } from './components/Chat/Chatlist';
import { ChatRoom } from './components/Chat/ChatRoom';
import { Folder } from './components/Folder';
import { DriverHome } from './components/Home/DriverHome';
import Home from './components/Home/Home';
import TestingPlace from './components/HT/TestingPlace';
import { WaitingSignup } from './components/HT/WaitingSignup';
import { HpCheckForPwChange } from './components/Login/HpCheckForPwChange';
import { Login } from './components/Login/Index';
import { PwChange } from './components/Login/PwChange';
import { Approval } from './components/My/Approval';
import { ApprovalHistory } from './components/My/ApprovalHistory';
import { HTMy } from './components/My/Index';
import { MyInfo } from './components/My/MyInfo';
import { Navi } from './components/Navi/Index';
import { Samples } from './components/Samples';
import { Signup } from './components/Signup/Index';
import MonthSchedule from './components/MonthSchedule';

function App() {
  const { session } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    setNativeConnector((param: string) => {
      console.log('fromNative>>', param);
      try {
        const jparam = JSON.parse(param);
        if (!jparam) return;
        console.log('jparam from Native:', jparam);
        const $nativeArea = document.getElementById('from-native');
        if ($nativeArea) $nativeArea.innerText = param;
      } catch (error) {
        console.error(error);
      }
    });

    // return removeNativeConnector();
  }, []);

  useEffect(() => {
    console.log('seeesion>>', session, session.didLogin);
    if (!session.didLogin) navigate('/login');
  }, [session.didLogin]);

  // const startRecord = () => {
  //   console.log('startRecord!!');
  //   callNative({
  //     cmd: 'starting',
  //     payload: [
  //       '11:30,htel_last20min.mp3',
  //       '11:40,htel_last10min.mp3',
  //       '11:50,htel_last.mp3',
  //     ],
  //   });
  // };

  // const stopRecord = () => {
  //   console.log('stopRecord!!');
  //   callNative({ cmd: 'closing', payload: { id: 39 } });
  // };

  // const takePhoto = () => {
  //   console.log('takePhoto!!');
  //   callNative({ cmd: 'photo', payload: { id: 39, etc: '하하' } });
  // };

  return (
    <>
      {/* <h1>Manager</h1>
      <div>
        <strong id='from-native'>fromNative Area</strong>|{' '}
        <a href='https://hometelier.net/regist?uid='>A태그 테스트</a>|{' '}
        <a
          href='https://hometelier.net/regist?uid='
          target='_blank'
          rel='noreferrer'
        >
          A태스 새탭 테스트
        </a>
      </div>
      <button onClick={startRecord}>Start Record</button>
      <button onClick={stopRecord}>Stop Record</button>
      <button onClick={takePhoto}>Take a Photo</button>
      <button
        onClick={() => {
          callNative({ cmd: 'ttt', payload: { id: 1, name: '홍' } });
        }}
      >
        TTT (브라우저테스트)
      </button>
      <hr /> */}

      {/* <ReactQueryDevtools initialIsOpen={false} /> */}

      <Navi />
      <Routes>
        <Route
          path='/home'
          // element={session.user.role === 4 ? <DriverHome /> : <Home />}
          element={<Home />}
        />
        <Route path='/samples' element={<Samples />}>
          <Route path=':usertype' element={<Samples />} />
        </Route>
        <Route path='/folder' element={<Folder />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/htmy' element={<HTMy />} />
        <Route path='/myinfo' element={<MyInfo />} />
        <Route path='/approval' element={<Approval />} />
        <Route path='/approvalhistory' element={<ApprovalHistory />} />
        <Route path='/waiting' element={<WaitingSignup />} />
        <Route path='/findingpw' element={<HpCheckForPwChange />} />
        <Route path='/pwchange' element={<PwChange />} />
        <Route path='/test' element={<TestingPlace />} />
        <Route path='/chatlist' element={<ChatList />} />
        <Route path='/chatroom' element={<ChatRoom />} />
        <Route path='/month-schedule' element={<MonthSchedule />} />
        {/* <Route path='/driverhome' element={<DriverHome />} /> */}
      </Routes>
    </>
  );
}

export default App;
