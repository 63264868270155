import React, { useState, ChangeEvent, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import {
  IconButton,
  TextField,
  Chip,
  Paper,
  makeStyles,
  Collapse,
} from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import SendIcon from '@material-ui/icons/Send';

interface Props {
  from?: string;
  msgRef: any;
  imga: any;
  setimga: Dispatch<SetStateAction<any>>;
  setNowImageUrl: Dispatch<SetStateAction<any>>;
  setchatcontents: Dispatch<SetStateAction<any>>;
  chatcontents: any;
  nowImageUrl: any;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipcontents: {
    padding: '.3em',
  },
  input: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    right: 0,
    borderTop: '1px solid #666',
    marginTop: '.4em',
  },
  chip: {
    margin: '2px 2px',
  },
  btn: {
    margin: '0 .5em',
  },
});

const CHIPS = [
  { id: 0, label: '폐기' },
  { id: 1, label: '비번' },
  { id: 2, label: '평수' },
  { id: 3, label: '사고' },
];

export const ChatInputs: React.FC<Props> = ({
  from,
  msgRef,
  imga,
  chatcontents,
  nowImageUrl,
  setimga,
  setNowImageUrl,
  setchatcontents,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const ci = document.getElementById('uploadImg');
  const onChange = () => ci?.click();

  return (
    <div className={clsx({ [classes.input]: from !== 'home' })}>
      <Paper className={classes.chipcontents}>
        <div className={classes.root}>
          {CHIPS.map((data) => (
            <Chip
              size='small'
              onClick={() => {}}
              variant='outlined'
              label={data.label}
              className={classes.chip}
              key={data.id}
            />
          ))}
          <Chip
            size='small'
            onClick={() => setIsOpen(!isOpen)}
            variant='outlined'
            label='더보기'
            className={classes.chip}
          />
        </div>
        <Collapse in={isOpen}>
          <div className={classes.root}>
            <Chip
              size='small'
              variant='outlined'
              label='더보기'
              className={classes.chip}
            />
            <Chip
              size='small'
              variant='outlined'
              label='더보기2'
              className={classes.chip}
            />
          </div>
        </Collapse>
      </Paper>
      <Paper className='flex' variant='outlined'>
        <IconButton size='small' className={classes.btn} onClick={onChange}>
          <input
            type='file'
            accept='image/*'
            id='uploadImg'
            multiple
            hidden
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              console.log(evt.currentTarget.files);
              const ig = [...imga];
              const newurl = [];
              if (evt.currentTarget.files) {
                for (let a = 0; a < evt.currentTarget.files?.length; a += 1) {
                  ig.push(evt.currentTarget.files[a]);
                  newurl.push({
                    label: evt.currentTarget.files[a].name,
                    imgPath: URL.createObjectURL(evt.currentTarget.files[a]),
                  });
                }
                setimga([...ig]);
                setNowImageUrl([...newurl]);
                const a = [...chatcontents];
                const d = new Date().toISOString().split('T');
                const dd = new Date().toLocaleTimeString();
                const ddd = dd.split(' ')[1].split(':');

                const b = {
                  id: a.length + 1,
                  img: newurl,
                  time: `${ddd[0]}:${ddd[1]}`,
                  date: d[0],
                  position: 'right',
                };
                a.push(b);
                setchatcontents([...a]);
                console.log(nowImageUrl);
              }
            }}
          />
          <AddBoxOutlinedIcon />
        </IconButton>
        <TextField
          inputRef={msgRef}
          size='small'
          variant='outlined'
          fullWidth
        />
        <IconButton
          onClick={() => {
            console.log(msgRef.current.value);
            const aa = msgRef.current.value;
            if (aa) {
              const a = [...chatcontents];
              const d = new Date().toISOString().split('T');
              const dd = new Date().toLocaleTimeString();
              const ddd = dd.split(' ')[1].split(':');
              const b = {
                id: a.length + 1,
                message: msgRef.current.value,
                time: `${ddd[0]}:${ddd[1]}`,
                date: d[0],
                position: 'right',
              };
              a.push(b);
              setchatcontents([...a]);
              msgRef.current.focus();
            }
          }}
        >
          <SendIcon />
        </IconButton>
      </Paper>
    </div>
  );
};
