import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

interface Props {}

const useStyles = makeStyles(() => ({
  btn: {
    textAlign: 'center',
    float: 'right',
    margin: '1% 10% 0 0',
  },
}));

export const SignupFooter: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <>
      <Button className={classes.btn} onClick={() => window.history.back()}>
        <strong>돌아가기</strong>
      </Button>
    </>
  );
};
