import React, { useEffect, Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HTInput } from '../HT/HTInput';

interface Props {
  hp: string;
  pw: string;
  setPw: Dispatch<SetStateAction<string>>;
  NumpadState: boolean;
  onChangeNumpad: () => void;
  submit: () => void;
}

export const PwInput: React.FC<Props> = ({
  hp,
  pw,
  setPw,
  NumpadState,
  onChangeNumpad,
  submit,
}) => {
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState<JSX.Element | string>('');
  const isSettingNumpad = () => {
    if (hp.length === 8) {
      onChangeNumpad();
      setPw('');
    } else {
      setHelperText(
        <strong style={{ color: '#1976D2' }}>
          핸드폰 번호를 먼저 입력해주세요!
        </strong>
      );
    }
  };

  useEffect(() => {
    // pw && submit();
    if (pw) {
      submit();
      // setPw('');
    }
  }, [hp.length === 8 && pw.length === 6]);
  useEffect(() => {
    if (hp) {
      setHelperText('');
    }
  }, [hp.length === 8]);

  return (
    <>
      <HTInput
        from='pw'
        length={6}
        input={pw}
        setInput={setPw}
        title='비밀번호'
        helperText={helperText}
        NumpadState={NumpadState}
        onChangeNumpad={isSettingNumpad}
      />
    </>
  );
};
