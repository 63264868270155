import { Avatar, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { useMany } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import { IEmp } from '../../models/emp';
import { IUpfile } from '../../models/etc';

interface Props {
  from: string;
}

const useStyles = makeStyles({
  my: {
    width: '3.65em',
    height: '3.65em',
    backgroundColor: '#1565C0',
    color: '#fff',
    fontWeight: 500,
  },
  navi: {
    // width: '28px',
    // height: '28px',
    width: '36px',
    height: '36px',
    // fontSize: '.57em',
    // fontSize: '.7rem',
    fontSize: '12px',
    backgroundColor: '#1565C0',
    color: '#fff',
    fontWeight: 500,
  },
  info: {
    width: '5em',
    height: '5em',
    backgroundColor: '#1565C0',
    color: '#fff',
    fontWeight: 500,
  },
});

export const HTAvatar: React.FC<Props> = ({ from }) => {
  const classes = useStyles();
  const { session } = useSession();
  // const { data: loginUser, refetch: refetchLoginUser } = useMany<IEmp>('emp', {
  const { data: loginUser } = useMany<IEmp>('emp', {
    cmd: 'read',
    id: session.user.id,
  });

  // const { data: userImg, refetch: refetchUserImg } = useMany<IUpfile>(
  const { data: userImg } = useMany<IUpfile>('upfile', {
    cmd: 'read',
    id: loginUser?.profileimg,
  });
  // console.log('profileimg확인>>>>>>>', userImg);

  // const userName =
  //   loginUser?.name && loginUser?.name.length > 2
  //     ? loginUser?.name.slice(1)
  //     : loginUser?.name;
  const userName =
    loginUser?.name && loginUser?.name.length > 3
      ? loginUser.name.slice(0, 3)
      : loginUser?.name;

  const imgRef = useRef<any>();
  const ci = document.getElementById('changeImg');
  const onChange = () => {
    if (from !== 'navibar') ci?.click();
  };

  return (
    <Avatar
      alt={userName}
      src={`https://dev.adm.hometelier.net/static${userImg?.path}/${userImg?.name}`}
      className={clsx({
        [classes.my]: from === 'my',
        [classes.navi]: from === 'navibar',
        [classes.info]: from === 'myinfo',
      })}
      onClick={onChange}
    >
      {from !== 'navibar' && (
        <input
          type='file'
          accept='image/*'
          id='changeImg'
          ref={imgRef}
          hidden
        />
      )}
      {userName}
    </Avatar>
  );
};
