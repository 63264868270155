import { Avatar, IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatInputs } from './ChatInputs';
import { Photodialog } from './Photodialog';

interface Props {
  from?: string;
}

const useStyles = makeStyles({
  avat: {
    display: 'flex',
    alignItems: 'start',
  },
  title: {
    fontSize: '1.2em',
    display: 'flex',
    justifyConents: 'center',
    alignItems: 'center',
    marginBottom: '.5em',
  },
  chatmsg: {
    overflowY: 'scroll',
    height: '65vh',
  },
  contents: {
    padding: '0 3%',
  },
  leftall: {
    margin: '0 0 1em .4em',
  },
  leftmsg: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  leftcontent: {
    borderRadius: '12px',
    padding: '.4em .5em',
    backgroundColor: '#4A6791',
    maxWidth: '60vw',
  },
  lefttime: {
    fontSize: '.6em',
    marginLeft: '.4em',
  },
  rightmsg: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  rightcontent: {
    borderRadius: '13px',
    padding: '.4em .5em',
    backgroundColor: '#37474F',
    maxWidth: '60vw',
    marginBottom: '.5em',
  },
  righttime: {
    fontSize: '.6em',
    marginRight: '.4em',
  },
  sent: {
    color: '#607D8B',
    marginBottom: '.3em',
    marginLeft: '.3em',
  },
  chatdate: {
    textAlign: 'center',
    margin: '2em 0',
    fontSize: '.7em',
  },
  chatdatecontent: {
    border: '1px solid rgb(66, 66, 66)',
    backgroundColor: 'rgb(66, 66, 66)',
    borderRadius: '30px',
    padding: '.5% 4%',
  },
});

const PHOTOSTEPS = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath: '/static/img/배경3.jpg',
  },
  {
    label: 'Bird',
    imgPath: '/static/img/그림8.png',
  },
  {
    label: 'Bali, Indonesia',
    imgPath: '/static/img/그림7.png',
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath: '/static/img/그림6.png',
  },
  {
    label: 'Goč, Serbia',
    imgPath: '/static/img/juliana-romao-xdu_0gljaQ8-unsplash.jpg',
  },
  {
    label: 'Goč, Serbias',
    imgPath: '/static/img/testingimg.jpg',
  },
];

const CHATCONTENTS = [
  {
    id: 1,
    name: '사무실',
    avatar: '/static/img/juliana-romao-xdu_0gljaQ8-unsplash.jpg',
    message: '안녕하세요 테스트 중입니다.',
    time: '11:00',
    date: '2021-11-01',
    position: 'left',
  },
  {
    id: 2,
    message: '테스트 잘 되고 있는건 가요?',
    time: '12:00',
    date: '2021-11-01',
    position: 'left',
  },
  {
    id: 3,
    name: '사무실',
    avatar: '/static/img/juliana-romao-xdu_0gljaQ8-unsplash.jpg',
    message:
      '잘 되는거 같기도 하고.. 아닌걱 같기도하고... 제대로 되고 있는게 맞는걸까요 아닌걸까요... 이렇게 한다면 되는건가... 흠 어렵네',
    time: '12:00',
    date: '2021-11-01',
    position: 'left',
  },
  {
    id: 4,
    message:
      '이렇게 한다는건 잘된다고 할 수도 있고 안된다고 할 수도 있고, 조금 애매한 부분이 없지않지만 그래도 잘되고 있다고도 볼 수 있는 거 같기도 하긴한데.. 흠 어렵네',
    time: '12:00',
    date: '2021-11-01',
    position: 'right',
  },
  {
    id: 5,
    name: '사무실',
    avatar: '/static/img/juliana-romao-xdu_0gljaQ8-unsplash.jpg',
    message:
      '잘 되는거 같기도 하고.. 아닌걱 같기도하고... 제대로 되고 있는게 맞는걸까요 아닌걸까요... 이렇게 한다면 되는건가... 흠 어렵네22222222',
    time: '12:30',
    date: '2021-11-03',
    position: 'left',
  },
  {
    id: 6,
    message:
      '이렇게 한다는건 잘된다고 할 수도 있고 안된다고 할 수도 있고, 조금 애매한 부분이 없지않지만 그래도 잘되고 있다고도 볼 수 있는 거 같기도 하긴한데.. 흠 어렵네222222222',
    time: '13:00',
    date: '2021-11-03',
    position: 'right',
  },
  {
    id: 7,
    name: '사무실',
    avatar: '/static/img/juliana-romao-xdu_0gljaQ8-unsplash.jpg',
    message:
      '잘 되는거 같기도 하고.. 아닌걱 같기도하고... 제대로 되고 있는게 맞는걸까요 아닌걸까요... 이렇게 한다면 되는건가... 흠 어렵네33333333',
    time: '12:00',
    date: '2021-11-04',
    position: 'left',
  },
  {
    id: 8,
    message:
      '이렇게 한다는건 잘된다고 할 수도 있고 안된다고 할 수도 있고, 조금 애매한 부분이 없지않지만 그래도 잘되고 있다고도 볼 수 있는 거 같기도 하긴한데.. 흠 어렵네44444444',
    time: '13:00',
    date: '2021-11-04',
    position: 'right',
  },
  {
    id: 9,
    avatar: '/static/img/juliana-romao-xdu_0gljaQ8-unsplash.jpg',
    img: [{ label: 'Goč, Serbias', imgPath: '/static/img/testingimg.jpg' }],
    time: '13:00',
    date: '2021-11-04',
    position: 'left',
  },
  {
    id: 10,
    img: PHOTOSTEPS,
    time: '13:20',
    date: '2021-11-04',
    position: 'right',
  },
];

export const ChatRoom: React.FC<Props> = ({ from }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const fromHome = from && 'home';
  const msgRef = useRef<any>();
  const [imga, setimga] = useState<any>([]);
  const [nowImageUrl, setNowImageUrl] = useState<any>([]);

  const [chatcontents, setchatcontents] = useState(CHATCONTENTS);
  const [chatHeight, setchatHeight] = useState<number | string>(490);
  const getday = (date: string) => {
    const newDay = new Date(date).getDay();
    switch (newDay) {
      case 1:
        return '월요일';
      case 2:
        return '화요일';
      case 3:
        return '수요일';
      case 4:
        return '목요일';
      case 5:
        return '금요일';
      case 6:
        return '토요일';
      default:
        return '일요일';
    }
  };
  const getdate = (date: string) => {
    const newdate = date.split('-');
    return `${newdate[0]}년 ${newdate[1]}월${newdate[2]}일`;
  };

  useLayoutEffect(() => {
    const qq = document.getElementById('chattingtitle');
    if (qq) {
      if (from !== 'home') {
        // console.log(window.innerHeight, ci.clientHeight, qq.clientHeight, 23);
        setchatHeight(window.innerHeight - qq.clientHeight - 77 - 71);
      } else {
        setchatHeight('35vh');
      }
    }
  }, []);

  useEffect(() => {
    const qq = document.getElementById('chatting');
    if (qq) {
      qq.scrollTop = qq.scrollHeight;
    }
  }, [chatcontents]);

  useEffect(() => {
    msgRef.current.value = '';
  }, [chatcontents]);

  return (
    <>
      <div className={classes.title} id='chattingtitle'>
        {from !== 'home' ? (
          <>
            <IconButton onClick={() => window.history.back()}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <span>사무실</span>
          </>
        ) : (
          <>
            <IconButton onClick={() => navigate('/chatroom')}>
              <ZoomInIcon />
            </IconButton>
          </>
        )}
      </div>
      <div
        style={{
          overflowY: 'scroll',
          height: chatHeight,
        }}
        id='chatting'
      >
        {chatcontents.map((chatcontent, i) => (
          <div key={chatcontent.id} className={classes.contents}>
            {chatcontent.date !== chatcontents[i - 1]?.date && (
              <div className={classes.chatdate}>
                <span className={classes.chatdatecontent}>
                  {getdate(chatcontent.date)} {getday(chatcontent.date)}
                </span>
              </div>
            )}
            {chatcontent.position === 'left' ? (
              <div className={classes.avat}>
                {chatcontents[i + 1]?.time === chatcontent.time ? (
                  <Avatar style={{ visibility: 'hidden' }} />
                ) : (
                  <Avatar src={chatcontent.avatar}>
                    {chatcontent.name?.slice(1, 3)}
                  </Avatar>
                )}
                <div className={classes.leftall}>
                  <div className={classes.sent}>
                    {chatcontents[i + 1]?.time !== chatcontent.time &&
                      chatcontent.name}
                  </div>
                  <div className={classes.leftmsg}>
                    <span
                      className={classes.leftcontent}
                      aria-hidden='true'
                      onClick={() => {
                        const qq = document.getElementById(
                          chatcontent.id.toString()
                        );
                        qq?.click();
                      }}
                    >
                      {chatcontent.img &&
                        chatcontent.img.map((img, idx) => (
                          <div key={img.imgPath}>
                            <img
                              src={img.imgPath}
                              alt={img.label}
                              className='wid100'
                            />
                            <Photodialog
                              contents={chatcontent.img}
                              id={chatcontent.id.toString()}
                            />
                          </div>
                        ))}
                      {chatcontent.message}
                    </span>
                    <span className={classes.lefttime}>
                      {chatcontents[i + 1]?.position !== 'left'
                        ? chatcontent.time
                        : chatcontents[i + 1]?.time === chatcontent.time
                        ? ''
                        : chatcontent.time}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.rightmsg}>
                <span className={classes.righttime}>
                  {chatcontents[i + 1]?.position !== 'right'
                    ? chatcontent.time
                    : chatcontents[i + 1]?.time === chatcontent.time
                    ? ''
                    : chatcontent.time}
                </span>
                <span
                  className={classes.rightcontent}
                  aria-hidden='true'
                  onClick={() => {
                    const qq = document.getElementById(
                      chatcontent.id.toString()
                    );
                    qq?.click();
                  }}
                >
                  {chatcontent.img &&
                    chatcontent.img.map((img, idx) => (
                      <div key={img.imgPath}>
                        <img
                          src={img.imgPath}
                          alt={img.label}
                          className='wid100'
                        />
                        <Photodialog
                          contents={chatcontent.img}
                          id={chatcontent.id.toString()}
                        />
                      </div>
                    ))}
                  {chatcontent.message}
                </span>
              </div>
            )}
          </div>
        ))}
        {from !== 'home' && <div style={{ paddingBottom: 30 }} />}
      </div>
      <ChatInputs
        from={fromHome}
        msgRef={msgRef}
        imga={imga}
        nowImageUrl={nowImageUrl}
        setimga={setimga}
        setNowImageUrl={setNowImageUrl}
        setchatcontents={setchatcontents}
        chatcontents={chatcontents}
      />
    </>
  );
};
