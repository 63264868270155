import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { Button, makeStyles, Grow } from '@material-ui/core';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';

interface Props {
  NumpadState: boolean;
  onAddNum: (evt: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDeleteNum: () => void;
  onClearNums: () => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    zIndex: 3,
    width: '74vw',
    margin: '4.5vw auto',
  },
  icon: {
    marginTop: '50%',
  },
  btn: {
    '& .MuiButton-label': {
      width: 0,
    },
    width: '18vw',
    height: '18vw',
    margin: '0 3.2vw 2.5vw 3.2vw',
    borderRadius: '50%',
    color: '#EC407A',
    fontSize: '1.3em',
    zIndex: 2,
    minWidth: 'auto',
  },
});

export const HTNumpad: React.FC<Props> = ({
  NumpadState,
  onAddNum,
  onDeleteNum,
  onClearNums,
}) => {
  const classes = useStyles();

  const nums: { id: string; value: any; click: any }[] = [
    { id: '1', value: '1', click: onAddNum },
    { id: '2', value: '2', click: onAddNum },
    { id: '3', value: '3', click: onAddNum },
    { id: '4', value: '4', click: onAddNum },
    { id: '5', value: '5', click: onAddNum },
    { id: '6', value: '6', click: onAddNum },
    { id: '7', value: '7', click: onAddNum },
    { id: '8', value: '8', click: onAddNum },
    { id: '9', value: '9', click: onAddNum },
    {
      id: 'back',
      value: (
        <BackspaceOutlinedIcon className={classes.icon} onClick={onDeleteNum} />
      ),
      click: onDeleteNum,
    },
    { id: '0', value: '0', click: onAddNum },
    {
      id: 'clear',
      value: (
        <CachedOutlinedIcon className={classes.icon} onClick={onClearNums} />
      ),
      click: onClearNums,
    },
  ];

  return (
    <Grow in={NumpadState} timeout={500}>
      <div
        className={clsx({
          noneDisplay: !NumpadState,
          [classes.container]: NumpadState,
        })}
      >
        {nums.map((num) => (
          <Button
            key={num.id}
            id={num.id}
            value={num.value}
            onClick={num.click}
            variant='contained'
            className={classes.btn}
          >
            <span id={num.id}>{num.value}</span>
          </Button>
        ))}
      </div>
    </Grow>
  );
};
