import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, makeStyles } from '@material-ui/core';
import { HTInput } from '../HT/HTInput';
import { useSession } from '../../hooks/session';
import { IEmp } from '../../models/emp';
import { useMany } from '../../hooks/api';
import { Store } from '../../hooks/store';

interface Props {}

const useStyles = makeStyles({
  btncontainer: {
    margin: '7% 10%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    margin: 'auto 4%',
  },
});

export const PwChange: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [currentpw, setCurrentpw] = useState<string>('');
  const [newpw, setNewpw] = useState<string>('');
  const [checkpw, setCheckpw] = useState<string>('');
  const [helperText, setHelperText] = useState<JSX.Element | string>('');
  const [newHelperText, setNewHelperText] = useState<JSX.Element | string>('');
  const [checkHelperText, setCheckHelperText] = useState<JSX.Element | string>(
    ''
  );
  const currentReducer = (currentNumpadState: boolean) => !currentNumpadState;
  const newReducer = (newNumpadState: boolean) => !newNumpadState;
  const checkReducer = (checkNumpadState: boolean) => !checkNumpadState;
  const [currentNumpadState, isSettingCurrentNumpad] = useReducer(
    currentReducer,
    false
  );
  const [newNumpadState, isSettingNewNumpad] = useReducer(newReducer, false);
  const [checkNumpadState, isSettingCheckNumpad] = useReducer(
    checkReducer,
    false
  );
  const { session } = useSession();

  const { data: loginuser, refetch: refetchLoginuser } = useMany<IEmp>('emp', {
    cmd: 'read',
    id: session.user.id,
  });
  const { data: userpw } = useMany<IEmp>('emp', {
    cmd: 'confirm-passwd',
    passwd: currentpw,
    id: loginuser?.id,
  });
  const validCheckingPw = (msg: JSX.Element | string) => {
    setNewpw('');
    setNewHelperText(msg);
  };
  const checkValidDone = (msg: JSX.Element | string) => {
    setNewHelperText(msg);
    isSettingNewNumpad();
    isSettingCheckNumpad();
  };

  const validCheckingPwcheck = (msg: JSX.Element | string) => {
    setCheckpw('');
    setCheckHelperText(msg);
  };

  const checkCorrectDone = (msg: JSX.Element | string) => {
    setCheckHelperText(msg);
    isSettingCheckNumpad();
  };

  const isValidPw = () => {
    let msg: JSX.Element | string;
    if (loginuser?.mobile.includes(newpw)) {
      msg = '휴대폰 번호와 일치하는 번호는 사용하지 말아주세요.';
      validCheckingPw(msg);
    } else if (
      newpw
        .split('')
        .filter(
          (p, i) =>
            (Number(p) - Number(newpw[i + 1]) === 1 &&
              Number(newpw[i + 1]) - Number(newpw[i + 2]) === 1) ||
            (Number(p) - Number(newpw[i + 1]) === -1 &&
              Number(newpw[i + 1]) - Number(newpw[i + 2]) === -1)
        ).length > 0
    ) {
      msg = '연속된 숫자가 3개이상인 번호는 사용하지 말아주세요.';
      validCheckingPw(msg);
    } else if (
      newpw
        .split('')
        .filter((a, b) => a === newpw[b + 1] && newpw[b + 1] === newpw[b + 2])
        .length > 0
    ) {
      msg = '같은 숫자를 연속으로 3개이상 사용하지 말아주세요.';
      validCheckingPw(msg);
    } else {
      msg = <strong style={{ color: '#3949AB' }}>확인되었습니다.</strong>;
      checkValidDone(msg);
    }
  };

  const isValidPwCheck = () => {
    let msg: JSX.Element | string;
    if (newpw === checkpw) {
      msg = <strong style={{ color: '#3949AB' }}>확인되었습니다.</strong>;
      checkCorrectDone(msg);
    } else {
      msg = '비밀번호와 일치하지 않습니다.';
      validCheckingPwcheck(msg);
    }
  };

  useEffect(() => {
    if (userpw) {
      setHelperText(<span style={{ color: '#1976D2' }}>일치합니다.</span>);
      isSettingCurrentNumpad();
      isSettingNewNumpad();
    } else setHelperText('현재 비밀번호와 일치한 비밀번호를 입력해주세요.');
  }, [userpw?.id && userpw.id === loginuser?.id]);

  useEffect(() => {
    if (newpw && newpw === currentpw) {
      setNewHelperText('현재 비밀번호와 같습니다.');
      setNewpw('');
    } else if (newpw) isValidPw();
  }, [newpw.length === 6]);

  useEffect(() => {
    if (checkpw) isValidPwCheck();
  }, [checkpw.length === 6]);

  return (
    <>
      <div>비번 변경</div>
      <HTInput
        from='pw'
        length={6}
        input={currentpw}
        setInput={setCurrentpw}
        title='현재 비밀번호'
        helperText={helperText}
        NumpadState={currentNumpadState}
        onChangeNumpad={isSettingCurrentNumpad}
      />
      <HTInput
        from='pw'
        length={6}
        input={newpw}
        setInput={setNewpw}
        title='새로운 비밀번호'
        helperText={newHelperText}
        NumpadState={newNumpadState}
        onChangeNumpad={isSettingNewNumpad}
      />
      <HTInput
        from='pw'
        length={6}
        input={checkpw}
        setInput={setCheckpw}
        title='비밀번호 확인'
        helperText={checkHelperText}
        NumpadState={checkNumpadState}
        onChangeNumpad={isSettingCheckNumpad}
      />
      <div className={classes.btncontainer}>
        <Button
          variant='contained'
          color='secondary'
          fullWidth
          onClick={() => window.history.back()}
        >
          취소
        </Button>
        <Divider className={classes.divider} />
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={async () => {
            console.log('currentpw, newpw>>>>>', currentpw, newpw);
            const res = await Store.post('emp', {
              cmd: 'update-passwd',
              passwd: newpw,
              id: loginuser?.id,
              oldpasswd: currentpw,
            });
            navigate('/myinfo2');
          }}
        >
          변경
        </Button>
      </div>
    </>
  );
};
