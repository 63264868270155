export interface ICheckList {
  id: number;
  content: string;
  checked: boolean;
  etc?: string;
}

export const CommonChecks = [
  {
    id: 1,
    content: '불 끄기/놓고 오는 물건은 없나요?',
    checked: false,
    etc: '공통',
  },
  { id: 2, content: '고객요청사항을 확인하셨나요?', checked: false },
  { id: 3, content: '에어컨(히터) 전원을 끄셨나요?', checked: false },
  { id: 4, content: '문, 창문, 블라인드를 닫으셨나요?', checked: false },
];

// export const Standards: IStandards[] = [
export const Standards = [
  {
    id: 11,
    content: '일반쓰레기/음식물/분리수거 처리(미확인 물품 사무실로)',
    checked: false,
    etc: '청소',
  },
  {
    id: 12,
    content: '설거지 거름망 음식물 확인, 싱크대 수전 광내기',
    checked: false,
  },
  {
    id: 13,
    content: '창틀, 거울 닦기',
    checked: false,
  },
  {
    id: 14,
    content: '화장실 휴지통, 배수구 머리카락 제거',
    checked: false,
  },
  {
    id: 15,
    content: '티슈 삼각 접기',
    checked: false,
  },
  {
    id: 16,
    content: '테이블, 선반 닦기',
    checked: true,
  },
  {
    id: 17,
    content: '카펫, 소파, 침대 아래 먼지제거',
    checked: true,
  },
  {
    id: 18,
    content: '바닥 쓸고 닦기, 머리카락 제거',
    checked: true,
  },
];

export const Toilets = [
  {
    id: 21,
    content: '변기 안쪽 물 나오는 곳, 비데노즐쪽 청소',
    checked: false,
    etc: '욕실',
  },
  {
    id: 22,
    content: '세면대, 세면대 위 선반 청소',
    checked: false,
  },
  {
    id: 23,
    content: '수전, 샤워기 마른 걸레로 광내기',
    checked: false,
  },
  {
    id: 24,
    content: '티슈 삼각 접기',
    checked: false,
  },
  {
    id: 25,
    content: '거울 닦기',
    checked: true,
  },
  {
    id: 26,
    content: '배수구 청소, 머리카락 제거',
    checked: true,
  },
  {
    id: 27,
    content: '화장실 휴지통 비우기',
    checked: true,
  },
  {
    id: 28,
    content: '샤워부스 물때 제거',
    checked: true,
  },
  {
    id: 29,
    content: '전체적인 물기 제거',
    checked: true,
  },
];

export const Beddings = [
  { id: 31, content: '회수 수량 확인', checked: true, etc: '베딩' },
  { id: 32, content: '오염, 주름이 없는지 확인', checked: true },
];

export const Towels = [
  { id: 41, content: '회수 수량 확인', checked: true, etc: '타월' },
  { id: 42, content: '오염, 흐트러짐이 없는지 확인', checked: true },
  { id: 43, content: '수거함 상태 확인', checked: true },
];

export const Absence = [];
export const Furniture = [];
export const SmartDoorLock = [];

export const Withdraw = [
  { id: 91, content: '사무실과 연락을 하셨나요?', checked: false },
  { id: 92, content: '고객님께 연락을 해보셨나요?', checked: false },
  { id: 93, content: '초인종을 눌러보셨나요?', checked: false },
  { id: 94, content: '20분 이상 대기하셨나요?', checked: false },
];
