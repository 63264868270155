import { DefaultEmp, IEmp } from './emp';

export interface IFolder {
  [index: string]: any;
  id?: number;
  createdate?: string;
  // workdate?: string;
  name: string;
  usetype: number;
  isdel: number;
  ftype: number;
  seq: number;
}

export interface IBoard {
  id: number;
  createdate?: string;
  workdate?: string;
  // folder: IFolder;
  folder: number;
  title: string;
  foldertag?: number;
  writer?: string;
  emp?: IEmp;
  writeip?: string;
  hit?: number;
  isdel?: boolean;
  isnoti?: boolean;
  contents?: string;
  didconfirm?: boolean;
}

export interface IBoardConfirm {
  id: number;
  createdate?: string;
  workdate?: string;
  board: number;
  emp: number;
}

export interface IFolderTag {
  id: number;
  createdate?: string;
  workdate?: string;
  folder: IFolder;
  tname: string;
  isdel?: boolean;
}

export const DefaultFolder: IFolder = {
  id: 0,
  name: '',
  usetype: 0,
  isdel: 0,
  ftype: 0,
  seq: 0,
};

export const DefaultBoard: IBoard = {
  id: 0,
  folder: 4,
  title: '',

  hit: 0,
  isdel: false,
  isnoti: false,
};

export const DefaultBoardConfirm: IBoardConfirm = {
  id: 0,
  board: 0,
  emp: 0,
};

export const DefaultFolderTag: IFolderTag = {
  id: 0,
  folder: DefaultFolder,
  tname: '',
  isdel: false,
};
export interface IFolderModel {
  folder: IFolder;
}

export interface IFolders {
  folder: IFolder[];
}
