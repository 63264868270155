import React from 'react';
import {
  IconButton,
  InputAdornment,
  createStyles,
  makeStyles,
  TextField,
} from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

const useStyles = makeStyles({
  container: {
    padding: '4% 10% 2% 10%',
    marginTop: '1.5em',
  },
  label: {
    fontSize: '15px',
    fontWeight: 600,
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  inputForm: {
    paddingLeft: '3%',
    paddingRight: '3%',
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '14px',
      color: '#3F51B5',
      textAlign: 'right',
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      height: '.9vh',
      margin: '.9%',
    },
  },
  icon: {
    marginRight: '.5em',
    height: '20px',
    weight: '20px',
  },
});
interface Props {
  nameRef: any;
  onChangeOpenHp: () => void;
}

export const SignupName: React.FC<Props> = ({ nameRef, onChangeOpenHp }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.label}>
        <PermIdentityIcon className={classes.icon} />
        이름
      </div>
      <TextField
        fullWidth
        id='name'
        placeholder='이름을 적어주세요'
        variant='outlined'
        autoFocus
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputRef: nameRef,
          endAdornment: (
            <InputAdornment position='end'>
              {/* <IconButton onClick={name !== '' ? onChangeOpenHp : f => f}> */}
              <IconButton onClick={onChangeOpenHp}>
                <ArrowForwardIosOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={classes.inputForm}
      />
    </div>
  );
};
