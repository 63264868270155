import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

interface Props {
  from?: string;
}

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  time: {
    opacity: 0.5,
    marginLeft: '1em',
  },
  title: {
    fontSize: '1.2em',
    display: 'flex',
    justifyConents: 'center',
    alignItems: 'center',
    margin: '.5em 0 ',
  },
});

const getSpeakers = () => [
  {
    id: 1,
    name: '사무실',
    avatar: '/static/img/avatars/logo.svg',
    time: '14:00',
    hasNew: true,
    contents: '마치시면 연락주세요.',
  },
  {
    id: 2,
    name: '드라이버',
    time: '11:00',
    hasNew: true,
    contents: '5분 내로 도착합니다.',
  },
  {
    id: 3,
    name: '베르디움 101동 205호',
    avatar: '/static/img/avatars/avatar-2.jpg',
    hasNew: false,
    time: '13:00',
    contents: '방문 전 초인종 눌러주세요.',
  },
  {
    id: 4,
    name: '베르디움프렌즈 101동 205호',
    avatar: '/static/img/avatars/avatar-2.jpg',
    hasNew: false,
    time: '10:00',
    contents: '방문 전 초인종 눌러주세요.',
  },
  {
    id: 5,
    name: '프렌즈 101동 205호',
    avatar: '/static/img/avatars/avatar-2.jpg',
    hasNew: true,
    time: '09:00',
    contents: '방문 전 초인종 눌러주세요.',
  },
];

export const ChatList: React.FC<Props> = ({ from }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [chatSpeakers] = useState(getSpeakers());

  const chatSpeakersSorting = chatSpeakers
    .sort((a, b) => (a.time < b.time ? 1 : -1))
    .sort((a, b) => Number(b.hasNew) - Number(a.hasNew));

  return (
    <>
      {from !== 'new' && (
        <div className={classes.title}>
          <IconButton onClick={() => window.history.back()}>
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <span>채팅</span>
        </div>
      )}
      {chatSpeakersSorting.map((speaker, idx) => (
        <List key={speaker.id} className={classes.root}>
          <Divider />
          <ListItem onClick={() => navigate('/chatroom')}>
            <ListItemIcon>
              {speaker.hasNew ? (
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  color='secondary'
                  badgeContent={1}
                >
                  <Avatar src={speaker.avatar} />
                </Badge>
              ) : (
                <Avatar src={speaker.avatar} />
              )}
            </ListItemIcon>
            <ListItemText primary={speaker.name} secondary={speaker.contents} />
            <span className={classes.time}>{speaker.time}</span>
          </ListItem>
        </List>
      ))}
      <Divider />
    </>
  );
};
