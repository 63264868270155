import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from '../HT/Modal';
import {
  Beddings,
  CommonChecks,
  ICheckList,
  Standards,
  Toilets,
  Towels,
  Absence,
  Furniture,
  SmartDoorLock,
  Withdraw,
} from './CkListContent';

interface ICheckProps {
  itemids?: string;
  title?: string;
  open: boolean;
  handleNext: () => void;
  handleClose: () => void;
  didcheck?: boolean;
}

const checkes = [
  CommonChecks,
  Standards,
  Toilets,
  Beddings,
  Towels,
  Absence,
  Furniture,
  SmartDoorLock,
  Standards,
  Withdraw, // 가사서비스 - 철수하기
];

const getChecks = (idsStr: string) =>
  idsStr === '9'
    ? checkes[Number(idsStr)]
    : idsStr
        .split(',')
        .sort((a, b) => Number(a) - Number(b))
        .reduce((c, q) => c.concat(checkes[Number(q)]), checkes[0]);

export default function CkList({
  itemids = '1',
  title = '체크리스트',
  handleNext,
  open,
  handleClose,
  didcheck = false,
}: ICheckProps) {
  // const { session } = useSession();
  const [contents, setContents] = useState<ICheckList[]>([]);

  const [warning, setWaring] = useState<boolean>(false);
  const isOpenWarning = () => setWaring(true);
  const isCloseWarning = () => setWaring(false);

  const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const newContents = [...contents];
    const idx: number = newContents.findIndex(
      (content) => evt.currentTarget.id === content.id.toString()
    );
    const newContent = newContents[idx];
    newContents[idx] = {
      ...newContent,
      checked: !newContents[idx].checked,
    };
    setContents(newContents);
  };

  const submit = () => {
    // if (session.user.id === 3 || contents.every((content) => content.checked)) {
    if (didcheck || contents.every((content) => content.checked)) {
      handleNext();
    } else {
      isOpenWarning();
    }
  };

  useEffect(() => {
    const cnts = getChecks(itemids);
    setContents(cnts);
  }, [itemids]);

  return (
    <>
      <Modal
        modalOpen={open}
        isCancel={handleClose}
        // isSubmit={handleNext}
        isSubmit={submit}
        title={`${title} ${didcheck ? '(완료)' : ''}`}
        msg={contents.map((content) => (
          <div key={content.id}>
            {content?.etc && (
              <h3 style={{ marginBottom: '.1rem' }}>{content.etc}</h3>
            )}
            <FormControlLabel
              key={`x${content?.id}`}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  checked={!!didcheck || content?.checked}
                  id={content?.id.toString()}
                  color='secondary'
                  onChange={onChange}
                  style={{ padding: '.1rem .5rem' }}
                />
              }
              label={content?.content}
            />
          </div>
        ))}
      />
      <Modal
        modalOpen={warning}
        isCancel={isCloseWarning}
        isCheck={isCloseWarning}
        type='alert'
        title='체크리스트'
        msg='체크리스트를 모두 확인해주세요.'
      />
    </>
  );
}
