import { Button } from '@material-ui/core';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSaver } from '../../hooks/api';
import { DefaultEmp, IEmp } from '../../models/emp';
import { HpInput } from '../HT/HpInput';
import { Modal } from '../HT/Modal';
import { SignupBelong } from './SignupBelong';
import { SignupFooter } from './SignupFooter';
import { SignupName } from './SignupName';
import { SignupPwInput } from './SignupPwInput';
import { SignupWeeks } from './SignupWeeks';
import { Store } from '../../hooks/store';
import { TAreaCode } from '../../models/etc';

interface Props {}

export interface IWorkday {
  id: number;
  name: string;
  checked: boolean;
}

const ErrorMessage = [
  '이름을 입력해주세요.',
  '휴대폰 번호를 입력해주세요.',
  '비밀번호를 입력해주세요.',
  '비밀번호가 일치하지 않습니다.',
  '소속 업체를 선택해주세요.',
  '근무 가능 요일을 선택해주세요.',
];

export const SignupForm: React.FC<Props> = () => {
  const navigate = useNavigate();
  const nameRef = useRef<any>(null);
  const belongRef = useRef<any>(null);
  // const branchRef = useRef<any>(null);
  const [firstHp, setFirstHp] = useState<TAreaCode>('010');
  const [hp, setHp] = useState<string>('');
  const [pw, setPw] = useState<string>('');
  const [pwCheck, setPwCheck] = useState('');
  const [HpNumpadState, setHpNumpadState] = useState<boolean>(false);
  const [PwNumpadState, setPwNumpadState] = useState<boolean>(false);
  const [PwCheckNumpadState, setPwCheckNumpadState] = useState<boolean>(false);
  const [openHp, setOpenHp] = useState<boolean>(true);
  const [openPw, setOpenPw] = useState<boolean>(true);
  const [openOthers, setOpenOthers] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackbar2, setOpenSnackbar2] = useState<boolean>(false);
  const [openJoinMsg, setOpenJoinMsg] = useState(false);

  const onChangeOpenHp = () => {
    setOpenHp(false);
    setHpNumpadState(true);
  };
  const onChangeOpenPw = () => {
    setOpenPw(false);
    setPwNumpadState(true);
  };

  const onChangeOpenOthers = () => setOpenOthers(true);

  const onChangeSnackbar = () => setOpenSnackbar(!openSnackbar);

  const onChangeOpenHpNum = () => {
    setHpNumpadState(!HpNumpadState);
  };
  const onChangeOpenPwNumpad = () => {
    setPwNumpadState(!PwNumpadState);
  };
  const onChangePwCheckNumpad = () => {
    setPwCheckNumpadState(!PwCheckNumpadState);
  };
  const signUpSaver = useSaver<IEmp>(
    'emp',
    (newEmp) => {
      console.log('success newEmp>>>>', newEmp);
      Store.setBaseURL(0);
      setOpenJoinMsg(true);
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
      setOpenSnackbar2(!openSnackbar2);
    }
  );

  const submit = () => {
    Store.setBaseURL(belongRef.current.value);

    const allHp = `${firstHp}${hp}`;
    const newEmp = {
      ...DefaultEmp,
      name: nameRef.current.value,
      mobile: allHp,
      passwd: pw,
      role: 3,
      cid: belongRef.current.value,
      weeks: '1,2,3,4,5,6',
    };
    signUpSaver.mutate(newEmp);
  };

  const validate = () => {
    console.log(
      nameRef.current?.value,
      hp.length === 8,
      pw,
      pwCheck === pw,
      belongRef.current?.value
    );

    const conditions = [
      nameRef.current?.value,
      hp.length === 8,
      pw,
      pwCheck === pw,
      Number(belongRef.current?.value) !== -1,
    ];

    const errorIndex = conditions.findIndex((t) => !t);
    console.log('errorIndex > ', errorIndex);

    if (errorIndex !== -1) {
      setSnackBarMessage(ErrorMessage[errorIndex]);
      setOpenSnackbar(true);
    } else {
      submit();
    }
  };

  // const all = () => {
  //   setOpenHp(false);
  //   setOpenPw(false);
  //   setOpenOthers(false);
  //   setHp('22223333');
  //   setPw('121212');
  // };

  return (
    <>
      <SignupName nameRef={nameRef} onChangeOpenHp={onChangeOpenHp} />
      <HpInput
        using='signup'
        firstHp={firstHp}
        setFirstHp={setFirstHp}
        hp={hp}
        setHp={setHp}
        NumpadState={HpNumpadState}
        onChangeNumpad={onChangeOpenHpNum}
        onChangePwNumpad={onChangeOpenPw}
        openHp={openHp}
      />
      <SignupPwInput
        hp={hp}
        pw={pw}
        pwCheck={pwCheck}
        setPwCheck={setPwCheck}
        setPw={setPw}
        NumpadState={PwNumpadState}
        PwCheckNumpadState={PwCheckNumpadState}
        onChangePwCheckNumpad={onChangePwCheckNumpad}
        onChangeNumpad={onChangeOpenPwNumpad}
        openPw={openPw}
        onChangeOpenNext={onChangeOpenOthers}
      />
      {openOthers && (
        <>
          <SignupBelong belongRef={belongRef} />

          <div
            className={clsx({
              open: openOthers,
              noneDisplay: !openOthers,
            })}
          >
            <Button
              onClick={validate}
              style={{
                padding: '0.5em',
                fontSize: '1.4em',
                borderRadius: '5em',
                display: 'block',
                margin: '4% auto',
                textAlign: 'center',
                width: '77%',
                backgroundColor: 'rgba(252, 105, 8, 0.8)',
              }}
              variant='contained'
            >
              가입 신청
            </Button>
          </div>
        </>
      )}

      <Modal
        modalOpen={openSnackbar}
        msg={snackBarMessage}
        isCancel={onChangeSnackbar}
        isCheck={onChangeSnackbar}
        type='alert'
      />
      <Modal
        modalOpen={openSnackbar2}
        msg='이미 가입된 번호입니다.'
        isCancel={() => setOpenSnackbar2(!openSnackbar2)}
        isCheck={() => setOpenSnackbar2(!openSnackbar2)}
        type='alert'
      />
      <Modal
        modalOpen={openJoinMsg}
        msg='가입되었습니다. 관리자 승인 후 로그인 가능합니다.'
        isCancel={() => {
          setOpenJoinMsg(false);
          navigate('/login');
        }}
        isCheck={() => {
          setOpenJoinMsg(false);
          navigate('/login');
        }}
        type='alert'
      />
      <SignupFooter />
      {/* <Button onClick={all}>.</Button> */}
    </>
  );
};
