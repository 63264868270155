import { IBillService } from '../models/bill';
import { twoLen } from './utils';

export interface ICalDate {
  id: string;
  holiday?: string | null | undefined;
  date: Date;
  billservices: IBillService[]; // 일별로 item별 하나씩 생성
  oldServices: IBillService[];
}
export const dayTostring = (newday: number) => {
  switch (newday) {
    case 1:
      return '월';
    case 2:
      return '화';
    case 3:
      return '수';
    case 4:
      return '목';
    case 5:
      return '금';
    case 6:
      return '토';
    default:
      return '일';
  }
};

const today = new Date();
const WeekNames = ['월', '화', '수', '목', '금'];

export const getWeekName = (idx: number) => WeekNames[idx];
export const getDailyWeeks = (weekCnt: number) =>
  Array.from({ length: 7 * weekCnt }, (_, i) => i * 5);

export const dateLocaleStr = (date: Date = new Date()) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}년 ${month}월 ${day}일 (${WeekNames[date.getDay() - 1]})`;
};

export const timeStr = (date: Date = new Date()) => {
  const d = date.toLocaleTimeString();
  return d.substring(0, d.length - 3);
};

export const isWorkingTime = (holiday?: string, date: Date = new Date()) =>
  (!holiday || holiday === '마감') &&
  date.getHours() >= 9 &&
  date.getHours() < 18;

export const dateFormat = (date: Date, mode: number = 0) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  switch (mode) {
    case 1: // for calendar days
      return `${month}월 ${day}일`;

    case 2: // for calendar title
      return `${year}년 ${month}월`;

    case 3: // for calendar month
      return `${year}-${twoLen(month)}`;

    default:
      return `${year}-${twoLen(month)}-${twoLen(day)}`;
  }
};

export const minToTime = (min: number) => {
  const h = Math.floor(min / 60);
  const m = min % 60;
  return `${h}:${twoLen(m)}`;
  // return `${twoLen(h)}:${twoLen(m)}`;
};

export const dateStr = (date: Date = new Date()) => dateFormat(date);

export const getBetweenDates = (startDate: Date, endDate: Date) => {
  let dates: Date[] = [];
  const theDate = new Date(startDate);
  // console.log('theDate>>', theDate);
  while (theDate < endDate) {
    // console.log(theDate, theDate.getDay());
    if (theDate.getDay() > 0 && theDate.getDay() < 6) {
      dates = [...dates, new Date(theDate)];
    }
    theDate.setDate(theDate.getDate() + 1);
  }
  dates = [...dates, endDate];
  return dates;
};

export const getDatesOnlyWeekday = (startDt?: string, endDt?: string) => {
  let start: Date;
  if (!startDt) {
    start = new Date();
    start.setDate(1);
  } else {
    start = new Date(startDt);
  }
  // console.log('000000000000000>.', start, start.getDay());
  if (start.getDay() % 6 === 0) start.setDate(start.getDate() + 2);
  if (start.getDay() !== 1)
    start.setDate(start.getDate() - (start.getDay() - 1));

  let end: Date;

  // start, end 둘 다 없다면 한 달 치!
  if (!startDt && !endDt) {
    end = new Date();
    end.setDate(31);

    // end만 없다면 8주 치! ===> 8주에서 6주로 변경(2021-10-27 by Jade)
  } else if (!endDt) {
    end = new Date(start);
    // end.setDate(end.getDate() + 7 * 7);
    end.setDate(end.getDate() + 7 * 5);
  } else {
    end = new Date(endDt);
  }
  // console.log('-------->>', end);

  // 시작을 월요일로 맞추기!
  const toMon = start.getDate() + -start.getDay() + 1;
  if (toMon !== 0) start.setDate(toMon);
  // console.log('ssss>>', start, toMon);

  // 끝을 금요일로 맞추기!
  const toFri = end.getDate() - (end.getDay() - 5);
  if (toFri !== 0) end.setDate(toFri);
  // console.log('+++++++++++++++>>', start, end);

  return getBetweenDates(start, end);
};

export const getWeeksByRule = (cntPerWeek: number, theDate: Date) => {
  const startWeek = theDate.getDay();
  if (cntPerWeek === 1) return [startWeek];

  switch (cntPerWeek) {
    case 2: // 월(1)-목(4), 화(2)-금(5), 수(3)-금(5), 목-월, 금-화
      if ([1, 4].includes(startWeek)) return [1, 4];
      if ([2, 5].includes(startWeek)) return [2, 5];
      return [3, 5];

    case 3:
      if ([1, 3, 5].includes(startWeek)) return [1, 3, 5];
      return [2, 4, 5];

    case 4:
      if (startWeek === 1) return [1, 2, 3, 5];
      if (startWeek === 2) return [2, 3, 4, 5];
      if (startWeek === 3) return [3, 4, 5, 1];
      return [1, 3, 4, 5];

    default:
      return [1, 2, 3, 4, 5];
  }
};

export const getNextDateStr = (dtStr: string, flag: number = 1) => {
  const d = new Date(dtStr);
  d.setDate(d.getDate() + flag);
  return dateFormat(d);
};

export const getNextMonthStr = (dtStr: string, flag: number = 1) => {
  const d = new Date(dtStr);
  d.setMonth(d.getMonth() + flag);
  return dateFormat(d);
};

export const getLastMonthStr = (dtStr: string, flag: number = 1) => {
  const d = new Date(dtStr);
  d.setMonth(d.getMonth() - flag);
  return dateFormat(d);
};

export const getFirstDateOfWeek = (dt: string | Date) => {
  const d = new Date(dt);
  d.setDate(d.getDate() - d.getDay());
  return d;
};

export const getFirstDateOfMonthStr = (
  dtStr: string | Date,
  startdt: number = 1
) => {
  const d = new Date(dtStr);
  if (startdt) d.setDate(startdt);
  return dateFormat(d);
};

type typeUnit =
  | 'years'
  | 'months'
  | 'weeks'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds';

export const dateDiff = (
  dt1: string | Date,
  dt2: string | Date,
  interval: typeUnit = 'days'
) => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  // for pure function
  const date1 = new Date(dt1);
  const date2 = new Date(dt2);
  const timediff = date2.getTime() - date1.getTime();

  if (Number.isNaN(timediff)) return 0;

  switch (interval) {
    case 'years':
      return date2.getFullYear() - date1.getFullYear();
    case 'months':
      return (
        date2.getFullYear() * 12 +
        date2.getMonth() -
        (date1.getFullYear() * 12 + date1.getMonth())
      );
    case 'weeks':
      return Math.floor(timediff / week);
    case 'days':
      return Math.floor(timediff / day);
    case 'hours':
      return Math.floor(timediff / hour);
    case 'minutes':
      return Math.floor(timediff / minute);
    case 'seconds':
      return Math.floor(timediff / second);
    default:
      return 0;
  }
};

export const weekDiff = (dt1: string | Date, dt2: string | Date) =>
  dateDiff(getFirstDateOfWeek(dt1), getFirstDateOfWeek(dt2), 'weeks');

export const getRealSpendTime = (spendtime: number = 0, withmgr: number = 0) =>
  withmgr ? Math.floor(spendtime / 2) : spendtime;
