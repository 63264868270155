import React, {
  ChangeEvent,
  FormEvent,
  FormEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { BrowserRouter, Navigate, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Store } from '../hooks/store';
import { useAll, useMany, useRemover, useSaver } from '../hooks/api';
import { DefaultFolder, IFolder, IFolders } from '../models/folder';
import { JadeCheck } from './JadeCheck';
import {
  useArray,
  useDebounce,
  useTimeout,
  useToggle,
  useUpdateEffect,
} from '../hooks/jhooks';

interface Props {
  id?: number;
}

export const Folder: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const { data, isSuccess } = useFolders<IFolders>();
  // const { data, isSuccess } = useAll<IFolders>('folder');
  const {
    data,
    isSuccess,
    refetch: refetchAllFolders,
  } = useAll<IFolder[]>('folder');

  const [folder, setFolder] = useState<IFolder>(DefaultFolder);
  // const [isIncludeDelete, setIncludeDelete] = useState<boolean>(false);
  const [isIncludeDelete, toggleIncludeDelete] = useToggle(false);

  const [count, setCount] = useState(10);
  const { clear, reset } = useTimeout(() => setCount(1), 1000);

  const [searchStr, setSearchStr] = useState('');
  const [searchResult, setSearchResult] = useState<[string]>(['']);
  // 검색어 모두 입력 후 1초 후에 실행!!
  useDebounce(
    () => {
      console.log('>>>>>>>>>>> this is real search!!!!!!!!!>>>>', searchStr);
      setSearchResult([searchStr]);
    },
    1000,
    [searchStr]
  );

  const {
    array,
    push,
    update,
    remove,
    filter,
    set,
    clear: clearArray,
  } = useArray<number>([1, 2, 3]);

  // searchResult가 변경됐을 때만 실행하라(useEffect를 쉽게 써보자!!)
  useUpdateEffect(() => {
    console.log('render Search Result!!!!!>>', searchStr);
  }, searchResult);

  const model = 'folder';
  const idcmd = id || 6;
  const queryKey = [model, idcmd];
  const nameRef = useRef<HTMLInputElement>(null);

  const onSaveSuccess = (datum: IFolder) => {
    console.log('onSuccess', datum);
    setFolder({ ...folder, id: datum.id });
    queryClient.invalidateQueries('folders');
  };

  const onSaveError = (err: AxiosError) => {
    console.log('!!onSaveError', err);
  };

  const saver = useSaver<IFolder>(model, onSaveSuccess, onSaveError);

  const remover = useRemover(
    'folder',
    () => {
      setFolder(DefaultFolder);
      queryClient.invalidateQueries('folders');
      queryClient.invalidateQueries('folders-find-by-usetype');
    },
    (err) => {
      console.log('Error on Remove!!', err);
    }
  );

  const {
    isError,
    error,
    isLoading,
    data: folderData,
  } = useQuery<IFolder, Error>(queryKey, Store.find<IFolder>(model, idcmd));
  // } = useQuery<IFolder, Error>(queryKey, Store.find<IFolder>(model, idcmd), {
  //   initialData: DefaultFolder, // 최초 등록으로 시작할 때!!
  // });

  // const mutation: UseMutationResult<IFolder, Error> = useMutation<
  //   IFolder,
  //   Error
  // >('createFolder', async (mdl: string, bdy: IFolder) =>
  //   Store.save<IFolder>(mdl, bdy)
  // );

  // const onSubmitCreate: FormEventHandler<HTMLFormElement> = (
  //   evt: React.SyntheticEvent
  // ) => {
  //   evt.preventDefault();
  //   const target = evt.target as typeof evt.target & {
  //     name: { value: string };
  //   };

  //   const name = target.name.value;
  //   mutation.mutate(model, folder);
  // };

  useEffect(() => {
    console.log('FolderData.useEffect======>>', folderData);
    setFolder(folderData || DefaultFolder);
  }, [folderData]);

  // query 방식 1: useQuery 사용
  // const {
  //   refetch: refetchFolders,
  //   data: folders,
  // }: UseQueryResult<IFolder[], Error> = useQuery(
  //   model,
  //   Store.finds<IFolder[]>(model, { cmd: 'finds-by-usetype', usetype: 0 })
  // );

  // query 방식 2: Store.useMany 사용
  const { refetch: refetchFolders, data: folders } = useMany<IFolder[]>(
    model,
    {
      cmd: 'finds-by-usetype',
      usetype: 0,
    },
    [DefaultFolder]
  );

  // const { data: folder2 } = useQuery(Store.find<IFolder>(model, 2));

  // const { refetch: refetchAllFolders, data: allFolders } = useQuery<
  const { data: allFolders } = useQuery<IFolder[], Error>(
    `all-${model}`,
    Store.findAll<IFolder[]>(model),
    {
      initialData: [],
    }
  );

  if (isLoading) {
    console.log('loading....................');
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error --- {error?.message}</div>;
  }

  const saveFolder = (evt: FormEvent) => {
    evt.preventDefault();
    // folder.name = nameRef.current?.value;
    // setFolder;
    setFolder({ ...folder, name: nameRef.current?.value || '' });

    // Store.save<IFolder>('folder', folder).then((res) => {
    //   console.log('save.res>>>', res);
    //   setFolder(res);

    //   // refetchFolders();  // 방식1: refetch 사용하기
    //   queryClient.invalidateQueries('folders'); // 방식 2: invalidate cache
    // });

    saver.mutate(folder);
  };

  const addFolder = () => {
    setFolder(DefaultFolder);
  };

  const deleteFolder = () => {
    // Store.delete('folder', folder.id).then(() => {
    //   setFolder(DefaultFolder);
    //   refetchFolders();
    // });

    remover.mutate(folder.id);
  };

  const onFolderChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setFolder({ ...folder, [evt.target.name]: evt.target.value });
  };

  const handleIsIncludeDelete = () => {
    console.log('isIncludeDelete>>', isIncludeDelete);
    // setIncludeDelete(!isIncludeDelete);
    toggleIncludeDelete();
    if (!isIncludeDelete) {
      refetchAllFolders();
    }
  };

  // -------------------------------------------------------------------------- use j-hook
  const searching = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchStr(evt.target.value);
  };

  return (
    <>
      <h5>
        게시판 :
        <small>
          {folder.id}-{folder.seq}
        </small>
        {folder.name} ({folder.workdate})
        <button onClick={() => setCount((c) => c + 1)}>{count}</button>
        <button onClick={reset}>Reset</button>
      </h5>
      <div>
        <div>
          Array: {array.join(', ')}
          <button onClick={() => navigate('/samples')}>Go to Samples</button>
          <button onClick={() => window.history.back()}>Go Back</button>
        </div>
        <button onClick={() => push(7)}>Add 7</button>
        <button onClick={() => update(1, 9)}>Change Second Element To 9</button>
        <button onClick={() => remove(1)}>Remove Second Element</button>
        <button onClick={() => filter((n: number) => n < 4)}>
          Keep Numbers Less Than 4
        </button>
        <button onClick={() => set([1, 2])}>Set To 1, 2</button>
        <button onClick={clearArray}>Clear</button>
      </div>
      <input
        type='text'
        value={searchStr}
        onChange={searching}
        placeholder='Search...'
      />
      <form onSubmit={saveFolder}>
        <input
          type='text'
          name='seq'
          value={folder.seq}
          placeholder='순서'
          onChange={onFolderChange}
        />
        <input
          type='text'
          ref={nameRef}
          value={folder.name}
          name='name'
          onChange={onFolderChange}
        />
        <button>Save Folder [ID: {folder.id}]</button>
        {saver.isError && <span>Error</span>}
        {saver.isLoading && <span>Loading</span>}
      </form>
      <button onClick={addFolder}>Add Folder</button>
      {folder.id ? <button onClick={deleteFolder}>Delete Folder</button> : ''}
      <hr />
      <div>
        {(isIncludeDelete ? data : folders)?.map((fld) => (
          <button
            onClick={() => {
              setFolder(fld);
            }}
            key={fld.id}
          >
            {fld.name}
          </button>
        ))}
        <JadeCheck
          label='모두보기'
          isChecked={isIncludeDelete}
          handleChange={handleIsIncludeDelete}
          elementId='chk-include-del'
        />
      </div>
      <hr />
      {/* {data?.folder.map((fld) => ( */}
      {data?.map((fld) => (
        <button
          onClick={() => {
            setFolder(fld);
          }}
          key={fld.id}
        >
          {fld.name}
        </button>
      ))}
      <hr />
      <pre>{JSON.stringify(folders, null, 2)}</pre>
    </>
  );
};
