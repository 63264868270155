import React, { ReactNode, useState } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const HTDarkStyle = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontSize: 14,
        },
        a: {
          color: '#BDBDBD',
          textDecoration: 'none',
        },
        input: {
          backgroundColor: '#455A64',
          color: '#fff',
        },
      },
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#407ad6',
      contrastText: '#FFF',
    },
    background: {
      default: '#1B2635',
      paper: '#233044',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans KR',
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontSize: '2rem',
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 780,
      lineHeight: 1.25,
    },
    h5: {
      fontSize: '1.0625rem',
      fontWeight: 780,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 780,
      lineHeight: 1.25,
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
    },
  },
});

interface Props {
  children: JSX.Element | ReactNode;
  // children: ReactNode;
}

export const HTTheme: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={HTDarkStyle}>{children}</ThemeProvider>
);
