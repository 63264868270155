import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StepConfirm } from '../My/StepConfirm';
import { HTHeader } from './HTHeader';
import { useSession } from '../../hooks/session';

interface Props {}

const useStyles = makeStyles({
  container: {
    padding: '10% 5% 0 5%',
  },
  cardRoot: {
    borderRadius: '20px',
    marginBottom: '2em',
    height: '15em',
    backgroundColor: 'rgb(38, 50, 56)',
  },
  header: {
    fontSize: '1.4em',
    textAlign: 'center',
    marginBottom: '1em',
  },
  contents: {
    lineHeight: '2em',
  },
  cardSecond: {
    borderRadius: '20px',
    marginBottom: '2em',
    height: '60%',
  },
  divider: {
    margin: '2em 1em 0em 1em',
  },
  btn: {
    float: 'right',
    marginRight: '1em',
  },
});

export const WaitingSignup: React.FC<Props> = () => {
  const classes = useStyles();
  const { logout } = useSession();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.cardRoot}>
          <CardContent>
            <div className={classes.header}>승인 대기 중</div>
            <div className={classes.contents}>
              승인 완료까지 영업일 기준 1~2일이 소요될 수 있습니다. 승인에
              문제가 발생 시 아래 홈텔리어 고객 센터로 연락 부탁드립니다.
            </div>
          </CardContent>
          <CardActions className={classes.btn}>
            <a href='tel: 02-6261-5577'>고객센터</a>
          </CardActions>
        </Card>
        <Card className={classes.cardSecond}>
          <CardContent>
            <StepConfirm
              steps={['가입 신청', '승인 대기중', '승인 완료']}
              step={1}
            />
            <Divider className={classes.divider} />
            <HTHeader from='waiting' />
          </CardContent>
        </Card>
        <Button
          onClick={() => {
            logout();
            // navigate('/login');
          }}
          className={classes.btn}
        >
          로그인 화면 돌아가기
        </Button>
      </div>
    </>
  );
};
