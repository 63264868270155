import React, { useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { useSession } from '../../hooks/session';

interface Props {}

const useStyles = makeStyles({
  container: {
    marginTop: '1em',
    padding: '3%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  btn: {
    fontSize: '14px',
    paddingBottom: 0,
  },
  about: {
    fontSize: '14px',
  },
  typo: {
    fontSize: '14px',
  },
  hometel: {
    display: 'flex',
    marginTop: '0.4em',
  },
  icon: {
    padding: '0',
  },
  detailhidden: {
    display: 'none',
    textAlign: 'right',
    fontSize: '12px',
    marginRight: '1.5em',
  },
  detail: {
    textAlign: 'right',
    fontSize: '12px',
    marginRight: '1.5em',
  },
});

export const Myfooter: React.FC<Props> = () => {
  const { session } = useSession();
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.about}>
          <div className={classes.hometel}>
            <Typography component='div' className={classes.typo}>
              {session.user.cname}
            </Typography>
          </div>
        </div>
      </div>
      {/* <div
        className={clsx(classes.detail, {
          [classes.detailhidden]: open === false,
        })}
      >
        대표이사: 허 혁<div>사업자등록번호: 704-88-02199</div>
        <div>통신판매업신고: 제 2021-서울용산-1519 호</div>
        서울 용산구 백범로99길 40 트리스퀘어 101동 205호
        <div>
          <a href='tel: 02-6261-5577'>Tel : 02 - 6261 - 5577</a>
        </div>
      </div> */}
    </div>
  );
};
