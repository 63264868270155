import {
  Avatar,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Store } from '../../hooks/store';
import { useSaver } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import {
  DefaultBillSvc,
  DefaultMvState,
  IBillSvc,
  IMvState,
  MVSTATE,
} from '../../models/bill';
import {
  alimMsg,
  alimMsgTitle,
  DefaultCustChat,
  ICustChat,
} from '../../models/emp';
import { getRealSpendTime, timeStr } from '../../utils/date-utils';
import { callNative } from '../../utils/native-connector';
import { once } from '../../utils/utils';
import { Modal } from '../HT/Modal';
import CkList from './CkList';

interface Props {
  billsvc?: IBillSvc;
  refetchBillSvc?: any;
  mvStates: IMvState[];
  // setMvStates: Dispatch<SetStateAction<IMvState[]>>;
  setMyMvState: (bsid: number | string, mv: string, state: number) => void;
}

interface Iprogress {
  step: number;
  title?: string;
  content?: any;
  time?: string;
}
const SERVER_PAGE = `${process.env.REACT_APP_SERVER_URL}/hometel/subscribe`;
const useStyles = makeStyles({
  btnMv: {
    marginLeft: '.5rem;',
    minWidth: '40px',
    fontWeight: 600,
  },
  divi: {
    margin: '.3em 1.5em',
  },
  avatar: {
    width: '18px',
    height: '18px',
    fontSize: '14px',
  },
  predo: {
    backgroundColor: '#424242',
  },
  done: {
    backgroundColor: '#D81B60',
  },
  doing: {
    backgroundColor: '#00C853',
  },
  container: {
    margin: '.5em 1em',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 .5em',
  },
  titledetail: {
    marginLeft: '2em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checklist: {
    margin: '.5em 1em',
    padding: '.5em',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '7px',
  },
  filming: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 .5em',
    margin: '0 1em .5em 6em',
  },
  time: {
    fontSize: '.7em',
    margin: '0em 0 0 2em',
  },
  pw: {
    fontSize: '1.2em',
  },
  donebtn: {
    backgroundColor: 'black',
  },
});

export const Todo: React.FC<Props> = ({
  billsvc,
  refetchBillSvc,
  mvStates,
  setMyMvState,
}) => {
  const classes = useStyles();
  const isWeb = !window || !window.Android;

  const { session, socket, myDrivers } = useSession();

  const [billservice, setBillservice] = useState<IBillSvc>(DefaultBillSvc);
  const [bv, setBv] = useState<boolean>(false); // 10분전 알림 alert 표시 여부
  const [pwCheck, setPwCheck] = useState<boolean>(false); // 비번 alert 표시 여부
  const [otpShow, setOtpShow] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [openChecklist, setOpenChecklist] = useState<boolean>(false); // 체크리스트 alert 표시 여부

  const [alertMsg, setAlertMsg] = useState<string>('');
  const [timeGuide, setTimeGuide] = useState<string>('');

  // const [time, setTime] = useState<number>(180); // 180초 타이머
  const [clickTime, setClickTime] = useState(Array(6));

  // 끝(문닫힘) 컨펌창 여부
  const [isOpenEnd, setOpenEnd] = useState<boolean>(false);

  const [mvState, setMvState] = useState<IMvState>(DefaultMvState);

  const billserviceId = billsvc?.bsids && billsvc?.bsids.split(',')[0];

  // console.log('=============>>', JSON.stringify(mvStates));

  window.onDoorLockOtp = (doorotp: string) => {
    window.log(`opt>>> ${doorotp}`);
    setOtp(doorotp);
  };

  // const DOMAIN = 'https://dev.mgr.hometelier.net';
  const DOMAIN =
    process.env.REACT_APP_LOCAL_URL || process.env.REACT_APP_SERVER_URL;

  const uploadMovie = (flag: string) => {
    const nowUploading = mvStates.find(
      (mvs) => mvs.smv === MVSTATE.UPLOADING || mvs.emv === MVSTATE.UPLOADING
    );
    console.log(
      ':::::>>',
      JSON.stringify(mvStates, null, '  '),
      mvState,
      nowUploading
    );
    if (
      nowUploading ||
      mvState.smv === MVSTATE.UPLOADING ||
      mvState.emv === MVSTATE.UPLOADING
    ) {
      setAlertMsg('하나씩 업로드하세요!');
      return;
    }

    setMvState({
      ...mvState,
      [flag === 'startmv' ? 'smv' : 'emv']: MVSTATE.UPLOADING,
    });

    // Home에서 사용 할 전체 Todo의 mvStates 세팅
    setMyMvState(billservice.id, flag, MVSTATE.UPLOADING);
    // const myMvState = mvStates.find((ms) => ms.id === Number(billservice.id));
    // if (myMvState) {
    //   myMvState[flag === 'startmv' ? 'smv' : 'emv'] = MVSTATE.UPLOADING;
    //   setMvStates([...mvStates]);
    // }

    callNative({
      cmd: 'upload',
      payload: {
        file: `${flag}_${billservice?.custid}_${billserviceId}.mp4`,
        url: `${DOMAIN}/api/hometel/htelweb/0/upfiles?x=${billservice?.custid}`,
      },
    });
  };

  // 종료 시간 구하기
  const getEndTime = () => {
    const d = new Date();
    // d.setMinutes(d.getMinutes() + (billservice?.spendtime || 0));
    d.setMinutes(
      d.getMinutes() +
        getRealSpendTime(billservice.spendtime, billservice.withmgr)
    );
    return d;
  };

  // takeMv (촬영하기)
  const recordingMv = (cmd: string) => {
    // console.log(
    //   'recording mv>>>>',
    //   mvState,
    //   getRealSpendTime(billservice.spendtime, billservice.withmgr)
    // );
    const isStartmv = cmd === 'startmv';
    // 시작촬영과 동시에 사운드 울릴 시간을 세팅한다!! (25분 미만의 소요시간은 알림 없음!!)
    if (
      isStartmv &&
      mvState.smv === MVSTATE.READY &&
      !billservice?.startmv &&
      getRealSpendTime(billservice.spendtime, billservice.withmgr) >= 25
      // billservice?.spendtime &&
      // billservice.spendtime >= 25
    ) {
      const payload = [];
      const d = getEndTime();
      // console.log('0>>>', d);
      payload.push({
        time: d.getTime(),
        file: 'htel_last.mp3',
      });

      d.setMinutes(d.getMinutes() - 5); // 5분 => 5분전 알림
      // console.log('1>>>', d.toLocaleString());

      let times = d.toTimeString().substr(0, 5);

      payload.push({
        time: d.getTime(),
        file: 'htel_last5min.mp3',
      });

      // if (billservice?.spendtime && billservice?.spendtime > 25) {
      if (getRealSpendTime(billservice.spendtime, billservice.withmgr) > 25) {
        d.setMinutes(d.getMinutes() - 5); // 추가 5분 => 10분전 알림
        // console.log('2>>>', d.toLocaleString());
        times += `->${d.toTimeString().substr(0, 5)}`;
        payload.push({
          time: d.getTime(),
          file: 'htel_last10min.mp3',
        });
        d.setMinutes(d.getMinutes() - 10); // 추가 10분 => 20분전 알림
        // console.log('3>>>', d.toLocaleString());
        times += `->${d.toTimeString().substr(0, 5)}`;
        payload.push({
          time: d.getTime(),
          file: 'htel_last20min.mp3',
        });
      }

      setTimeGuide(times);

      window.log(`setSound>> ${payload}`);
      callNative({
        cmd: 'setSound',
        payload,
      });
    }

    // 종료 촬영이면 사운드는 클리어하고, 기사에게 10분전(종료임박) 알림 발송
    if (!isStartmv) {
      socket?.emit('mgr-msg', {
        scope: 'mgr',
        model: 'billservice',
        msg: `[${billservice.empname}매니저] ${billservice.addr}`,
        fcmtoken: myDrivers?.map((md) => md.fcmtoken),
        drivers: myDrivers?.map((md) => md.id),
        // fcmtoken: [
        //   'c2gA4H-lkUI9hzo43WFxo7:APA91bFmFSqZYrFmoGZYxXQ9qt_8XgngouC8jVNfj0PYK2VD2ofva6I3DT3Lq99FLmoVcq8vevfDAlu3xRiPXRdoIEpFXgfFLW8PqKMT6_TE20p1RtbKI3UHH-GL-ysC8s8MlkpwIvPv',
        //   'e8ipPgt8TWuNSLCS_QwCdR:APA91bFpJipoOaOBQKHOkTwNRhS6Z9IR66r_9C-Sqcdo_Cf16kOAUxq7ah5Hdr8yo-dnls7CJVAUMkKKVhNHz_4gwCPTFF8ho2083MmsPRNoZJjWLAXkYMxEjzULRRggapByB6_YFU1D',
        // ],
      });

      callNative({ cmd: 'clearSound' });
    }

    callNative({
      cmd,
      payload: `${cmd}_${billservice?.custid}_${billserviceId}.mp4`,
    });

    // setMvStates([
    //   ...mvStates.filter((ms) => ms.id !== mvState.id),
    //   { ...mvState, [isStartmv ? 'smv' : 'emv']: MVSTATE.TAKING },
    // ]);
  };

  const changeTodo = useSaver<IBillSvc>(
    'billsvc',
    (updateTodo) => {
      setBillservice(updateTodo);
      console.log('updateTodo>>', updateTodo, mvState);
      refetchBillSvc();
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const timeSaver = useSaver<IBillSvc>(
    'billsvc',
    (bs) => {
      console.log('@@@timeSaver', billservice);
      refetchBillSvc();

      socket?.emit('sync', {
        ...bs,
        model: 'billservice',
      });
    },
    (err: AxiosError) => {
      console.log('Error on SaveTime>>>', err.response?.data);
    }
  );

  const custChatSaver = useSaver<ICustChat>(
    'custchat',
    (res: ICustChat) => {
      if (socket && socket.emit) socket.emit('cust-msg', res);
      else console.log('Not Valid Socket!!!!!!!!!', res);
    },
    (err: AxiosError) => {
      console.error('Error on SaveChat>>', err.response);
    }
  );

  const addChatMsg = (
    state: number,
    customer: number,
    message?: string,
    custaddr?: number
  ) => {
    const level = billsvc?.level;
    custChatSaver.mutate({
      ...DefaultCustChat,
      customer,
      empsaid: session.user.id,
      msgtitle: alimMsgTitle(state),
      msg: alimMsg(state, message, custaddr, level === 5 || level === 6),
      sysmsg: true,
    });
  };

  const updateTime = (bsids: string, mvstate: 'start' | 'end') => {
    console.log('@@@ updateTime>>>', billservice);
    if (billservice) {
      const bs = {
        ...billservice,
        cmd: `set-${mvstate}-time`,
        bsids: bsids,
      };
      timeSaver.mutate(bs);
    }
  };

  const onchangestate = (done: number) => {
    console.log('onchangestate>>', billservice, done);
    if (billservice) {
      const bs = {
        ...billservice,
        state: done,
      };
      // console.log('%%%%%%%>>', newtodo);
      changeTodo.mutate(bs);

      socket?.emit('sync', {
        ...bs,
        customer: bs.custid,
        scope: 'customer',
        model: bs.bsids ? 'billsvc' : 'billservice',
      });
      if (done >= 3 && done <= 5) {
        addChatMsg(done, bs.custid);
      }
    }
  };

  const onchangedidcheck = (didcheck: boolean) => {
    console.log('onchangedidcheck>>>>>', didcheck);
    if (billservice) {
      const bs = {
        ...billservice,
        didcheck,
      };
      changeTodo.mutate(bs);

      socket?.emit('sync', {
        ...bs,
        model: bs.bsids ? 'billsvc' : 'billservice',
      });
      // console.log('onchangedidcheck>>', newtodo);
      // refetchBillSvc();
    }
  };

  const DoneTime = (d: Date) => d.toLocaleTimeString();

  const handleClose = () => {
    setOpenChecklist(false);
  };

  // 10분 전 알림
  const sendingBeforeMsg = () => {
    console.log('sendingBeforeMsg!!');

    const newtime = [...clickTime];
    newtime[0] = DoneTime(new Date());
    setClickTime([...newtime]);
    onchangestate(3);
    setBv(false);

    // callNative({ cmd: 'giveMvs' }); // QQQ
  };

  // 암호 보기 끝
  const checkDonePw = () => {
    console.log('checkDonePw!!');
    const newtime = [...clickTime];
    newtime[1] = DoneTime(new Date());

    onchangestate(4);
    setPwCheck(false);
    setOtpShow(false);
  };

  // 정기구독 서비스 자동결제
  const completeService = async () => {
    const { bsids, visitdt, custaddr, custid } = billservice;
    const standingPayReqData = {
      billServiceIds: bsids?.split(',').map((id) => Number(id)) || [],
      visitdt,
      custaddr,
    };
    try {
      const result = await Store.sendPost(
        `${SERVER_PAGE}/standingpay`,
        standingPayReqData
      );

      if (result) {
        addChatMsg(6, custid);
      }
    } catch (error) {
      console.error(error);
      const { message } = error as AxiosError;
      const [code, msg] = message.split(':');
      // console.log(`ERROR >>>>>>>>>> [${code}]:${msg}`);

      addChatMsg(7, custid, `[${code}]:${msg}`, custaddr);
    }
    onchangestate(5);
  };

  // 서비스 끝 (문닫힘) + 정기구독 서비스 자동결제
  const checkDoneEnd = useCallback(
    once(() => {
      console.log('checkDoneEnd!!');
      // onchangestate(5);
      setOpenEnd(false);
      completeService();
    }),
    [onchangestate, setOpenEnd, completeService]
  );

  // 영상 여부 확인
  // useEffect(() => {
  //   if (billservice?.startmv) {
  //     setStartedMv(true);
  //     setUploadedStartmv(true);
  //   }
  //   if (billservice?.endmv) {
  //     setEndedMv(true);
  //     setUploadedEndmv(true);
  //   }

  //   callNative({ cmd: 'giveMvs' });
  // }, []);

  const progress: Iprogress[] = [
    {
      step: 1,
      title: '10분 전 안내',
      content: billservice && billservice.state < 5 && (
        // content: (
        <Button
          variant='contained'
          size='small'
          onClick={() => {
            setBv(true); // 모달창 열기
          }}
        >
          {billservice?.state <= 2 ? '알림 전송' : '알림 재전송'}
        </Button>
      ),
    },
    {
      step: 2,
      title: '비밀번호 확인',
      content: billservice?.state === 3 && (
        <Button
          variant='contained'
          size='small'
          onClick={() => {
            if (billservice && billservice?.state === 3) {
              setPwCheck(!pwCheck);
            }
          }}
        >
          보기
        </Button>
      ),
    },
    {
      step: 3,
      title: `시작 촬영`,
      content: billservice && billservice.state > 3 && (
        // content: (
        <div className='flex'>
          {/* {startedMv && !billservice?.startmv && !uploadedStartmv && ( */}
          {(mvState.smv === MVSTATE.TAKEN ||
            mvState.smv === MVSTATE.UPLOADING) && (
            <Button
              variant='contained'
              size='small'
              onClick={() => {
                // if (billservice && billservice?.state === 4) {

                if (billservice) {
                  const newtime = [...clickTime];
                  newtime[2] = DoneTime(new Date());
                  setClickTime([...newtime]);
                  // setUploadingStartmv(true);

                  uploadMovie('startmv');

                  if (isWeb)
                    setTimeout(() => {
                      console.log(
                        '************',
                        `startmv_${billservice?.custid}_${billserviceId}`
                      );
                      window.uploaded(
                        `startmv_${billservice?.custid}_${billserviceId}`
                      );
                    }, 2000);
                }
              }}
            >
              {mvState.smv === MVSTATE.UPLOADING ? (
                <>
                  Uploading...
                  <CircularProgress
                    style={{
                      width: '1.3em',
                      height: '1.3em',
                    }}
                    color='inherit'
                  />
                </>
              ) : (
                '업로드'
              )}
            </Button>
          )}

          {billservice.level === 5 ||
          mvState.smv === MVSTATE.UPLOADING ||
          billservice?.state === 5 ? (
            ''
          ) : (
            <Button
              className={classes.btnMv}
              variant='contained'
              size='small'
              onClick={() => {
                // console.log('recording startmv');
                recordingMv('startmv');

                if (billservice.bsids && !billservice.stm) {
                  updateTime(billservice.bsids, 'start');
                }

                if (isWeb) {
                  setTimeout(() => {
                    window.mvLocal([
                      `startmv_${billservice?.custid}_${billserviceId}.mp4`,
                    ]);
                  }, 1000);
                }
              }}
            >
              {billservice?.startmv || mvState.smv >= MVSTATE.TAKEN
                ? '재촬영'
                : `촬영`}
            </Button>
          )}
        </div>
      ),
    },
    {
      step: 4,
      title: `체크리스트`,
      content:
        billservice?.state > 3 && billservice.state < 5 ? (
          <Button
            variant='contained'
            size='small'
            onClick={() => setOpenChecklist(true)}
          >
            {billservice?.didcheck ? '보기(체크됨)' : '체크하기'}
          </Button>
        ) : (
          ''
        ),
    },
    {
      step: 5,
      title: `종료 촬영`,
      content: !!billservice?.didcheck && (
        // content: (
        <div className='flex'>
          {billservice.state > 3 &&
            (mvState.emv === MVSTATE.TAKEN ||
              mvState.emv === MVSTATE.UPLOADING) && (
              <Button
                variant='contained'
                size='small'
                onClick={() => {
                  // if (billservice && billservice?.state === 4) {
                  const newtime = [...clickTime];
                  newtime[2] = DoneTime(new Date());

                  setClickTime([...newtime]);
                  // setUploadingEndmv(true); // QQQ
                  uploadMovie('endmv');

                  if (isWeb)
                    setTimeout(() => {
                      window.uploaded(
                        `endmv_${billservice?.custid}_${billserviceId}`
                      );
                    }, 5000);
                }}
              >
                {mvState.emv === MVSTATE.UPLOADING ? (
                  <>
                    Uploading...
                    <CircularProgress
                      style={{
                        width: '1.3em',
                        height: '1.3em',
                      }}
                      color='inherit'
                    />
                  </>
                ) : (
                  '업로드'
                )}
              </Button>
            )}

          {mvState.emv === MVSTATE.UPLOADING ? (
            '업로드중...'
          ) : (
            <>
              {billservice.state === 4 && billservice.level !== 5 && (
                <Button
                  className={classes.btnMv}
                  variant='contained'
                  size='small'
                  onClick={() => {
                    recordingMv('endmv');
                    if (billservice.bsids && !billservice.etm) {
                      updateTime(billservice.bsids, 'end');
                    }
                    if (isWeb)
                      setTimeout(() => {
                        window.mvLocal([
                          `endmv_${billservice?.custid}_${billserviceId}`,
                        ]);
                      }, 1000);
                  }}
                >
                  {billservice?.endmv || mvState.emv >= MVSTATE.TAKEN
                    ? '재촬영'
                    : `촬영`}
                </Button>
              )}

              {billservice.state === 4 &&
              (billservice.level === 5 ||
                billservice?.endmv ||
                mvState.emv === MVSTATE.TAKEN) ? (
                <Button
                  className={classes.btnMv}
                  variant='contained'
                  color='secondary'
                  size='small'
                  onClick={() => {
                    setOpenEnd(true);
                  }}
                >
                  끝!
                </Button>
              ) : (
                ''
              )}

              {billservice.state === 5 ? (
                <Chip color='primary' label='완료!!' variant='outlined' />
              ) : (
                ''
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (billsvc && mvStates) {
      setBillservice(billsvc);

      // console.log('Todo.billsvc>>>>', billsvc);
      // console.log('Todo.mvStates>>>>', mvStates);
      if (billsvc.id) {
        setMvState(
          mvStates.find((ms) => ms.id === billsvc.id) || DefaultMvState
        );
      }
    }
  }, [billsvc, mvStates]);

  // Home > window.uploaded에서 영상파일 업로드 되었을 때 adm으로 sync보냄!!
  useEffect(() => {
    if (billsvc && (billsvc.startmv || billsvc.endmv)) {
      // console.log('--->> Todo.billsvc.startmv>>', billsvc.id, billsvc.startmv);

      socket?.emit('sync', {
        ...billsvc,
        customer: billsvc.custid,
        scope: 'customer',
        model: billsvc.bsids ? 'billsvc' : 'billservice',
      });
    }
  }, [billsvc?.startmv, billsvc?.endmv]);

  return (
    <>
      <Card variant='outlined'>
        <Divider className={classes.divi} />
        {progress.map((pg) => (
          <div key={pg.step}>
            <div
              className={clsx(classes.container, {
                p05: !clickTime[pg.step - 1],
              })}
            >
              <div className='flex'>
                <Avatar
                  className={clsx(
                    classes.avatar,
                    {
                      [classes.doing]:
                        billservice.state !== 5 &&
                        ((billservice?.state === 4 && pg.step >= 3) ||
                          billservice?.state === pg.step + 1),
                    },
                    {
                      [classes.done]:
                        billservice.state === 5 ||
                        billservice?.state < pg.step + 1,
                    },
                    {
                      [classes.predo]: billservice?.state > pg.step + 1,
                    }
                  )}
                >
                  {pg.step}
                </Avatar>
                <div className={classes.titledetail}>
                  <div>{pg.title}</div>
                  <div className={classes.time}>
                    {(pg.step === 3 &&
                      billservice.stm &&
                      timeStr(new Date(billservice.stm))) ||
                      (pg.step === 5 &&
                        billservice.etm &&
                        timeStr(new Date(billservice.etm))) ||
                      clickTime[pg.step - 1]}
                  </div>
                </div>
              </div>
              <div>{pg.content}</div>
            </div>
            <Divider className={classes.divi} />
          </div>
        ))}
      </Card>

      <Modal
        modalOpen={bv}
        title='10분 전 알림'
        msg='고객님께 10분 전 알림을 보내시겠습니까?'
        isCancel={() => setBv(!bv)}
        isSubmit={sendingBeforeMsg}
        labelOK='보낼게요!'
      />

      <Modal
        modalOpen={pwCheck}
        title='비밀번호'
        msg={
          <div>
            {otpShow ? (
              <div style={{ textAlign: 'center' }} className={classes.pw}>
                {billservice?.serialno ? (
                  <span className='ml1'>
                    {otp !== ''
                      ? `${otp}#`
                      : billservice?.serialno.startsWith('GTD')
                      ? '앱으로 보세요!'
                      : billservice?.serialno}
                  </span>
                ) : (
                  <span>사무실에 문의하세요</span>
                )}
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setOtp('');
                    if (billservice?.serialno?.match(/[a-zA-Z]/)) {
                      callNative({
                        cmd: 'showOTP',
                        payload: billservice?.doorid,
                      });
                    }
                    setOtpShow(!otpShow);
                  }}
                  className='ml2'
                  fullWidth
                >
                  비밀번호 확인하기
                </Button>
              </div>
            )}
          </div>
        }
        isCancel={() => {
          setPwCheck(!pwCheck);
          setOtpShow(false);
        }}
        isSubmit={otpShow ? checkDonePw : () => {}}
        type='confirm'
        labelOK='열렸어요!'
        labelCancel='안 열려요ㅠㅠ'
        hideButtons={!otpShow}
      />

      <CkList
        itemids={billservice?.itemids}
        didcheck={billservice?.didcheck}
        open={openChecklist}
        handleClose={handleClose}
        handleNext={() => {
          if (billservice && billservice?.state >= 3) {
            handleClose();
            const newtime = [...clickTime];
            newtime[3] = DoneTime(new Date());
            setClickTime([...newtime]);

            onchangedidcheck(true);
          }
        }}
      />

      {isOpenEnd && (
        <Modal
          modalOpen={isOpenEnd}
          title='서비스 종료'
          msg='고객님께 문닫힘 알림을 보내시겠습니까?'
          isCancel={() => setOpenEnd(!isOpenEnd)}
          isSubmit={checkDoneEnd}
          labelOK='보낼게요!'
        />
      )}

      <Modal
        modalOpen={!!alertMsg}
        title='도어락 등록'
        msg={alertMsg}
        isCancel={() => setAlertMsg('')}
        isCheck={() => setAlertMsg('')}
        type='alert'
      />
    </>
  );
};
