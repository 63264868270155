import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ClearIcon from '@material-ui/icons/Clear';

interface Content {
  label: string;
  imgPath: string;
}

interface Props {
  contents: Content[];
  id?: string;
}

const useStyles = makeStyles({
  img: {
    maxWidth: '100%',
  },
  content: {
    padding: 0,
    overflowY: 'hidden',
  },
  title: {
    padding: '4%',
  },
  dotChange: {
    color: 'white',
    display: 'flex',
  },
  dialogaction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & { children?: React.ReactElement<any, any> },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction='up' ref={ref} {...props} />;
// });

export const Photodialog: React.FC<Props> = ({ contents, id }) => {
  const classes = useStyles();
  const [opening, setopening] = useState(false);
  const [imga, setimga] = useState<FileList | null>(null);
  // const nowImageUrl = imga && URL.createObjectURL(imga[0]);

  const SlideSettings: Settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <button hidden onClick={() => setopening(!opening)} id={id}>
        .
      </button>
      <Dialog
        open={opening}
        fullScreen
        TransitionComponent={Slide}
        transitionDuration={500}
        className='p1'
      >
        <DialogTitle className={classes.title}>
          <IconButton onClick={() => setopening(false)}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={clsx(classes.content, { flex: contents.length === 1 })}
        >
          <Slider {...SlideSettings}>
            {contents.map((ts: Content) => (
              <div key={ts.imgPath} style={{ maxHeight: '60%' }}>
                <img src={ts.imgPath} alt={ts.label} className={classes.img} />
              </div>
            ))}
          </Slider>
        </DialogContent>
        <DialogActions className={classes.dialogaction} id='asda'>
          <IconButton size='small'>저장</IconButton>
          <IconButton size='small'>버튼</IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
