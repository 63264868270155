import {
  Card,
  CardContent,
  Chip,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMany } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import { IApproveEmp } from '../../models/approve';
import { IEmp, UserRole } from '../../models/emp';
import { HTAvatar } from '../HT/HTAvatar';
import { StepConfirm } from './StepConfirm';

interface Props {}

const useStyles = makeStyles({
  root: {
    '& .MuiCardContent-root': {
      paddingBottom: '1em',
    },
    padding: '1%',
    margin: '4% 4% 5% 4%',
  },
  container: {
    padding: '1%',
    margin: '2%',
  },
  content: {
    paddingTop: '2%',
  },
  flexdiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: '3.65em',
    height: '3.65em',
  },
  typo: {
    fontSize: '1.2em',
    display: 'flex',
    alignItems: 'center',
  },
  plus: {
    fontSize: '1em',
    paddingLeft: 0,
  },
  avatar2: {
    // borderRadius: '33%',
    backgroundColor: '#0D47A1',
  },
  chipbtn: {
    height: '2.1em',
    marginLeft: '.5em',
    backgroundColor: '#1565C0',
  },
});

export const Myheader: React.FC<Props> = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { session } = useSession();
  const { data: loginuser } = useMany<IEmp>('emp', {
    cmd: 'read',
    id: session.user.id,
  });

  const { data: approval } = useMany<IApproveEmp>('approveemp', {
    cmd: 'read',
    id: 3,
  });

  const rolesetting = (x: number = 0) => {
    const role = UserRole[x];
    return role?.name || '미정';
  };

  const restOffday = `잔여연차 ${loginuser?.offday}일`;

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={clsx(classes.content, classes.flexdiv)}>
          <div>
            <div
              className={classes.typo}
              aria-hidden='true'
              onClick={() => navigate('/myinfo')}
            >
              {loginuser?.name}
              <IconButton
                className={classes.plus}
                onClick={() => navigate('/myinfo')}
              >
                <ArrowForwardIosIcon className={classes.plus} />
              </IconButton>
            </div>
            {loginuser && <div>{rolesetting(loginuser.role)}</div>}
          </div>
          <HTAvatar from='my' />
        </CardContent>
      </Card>
      {session.user.cid === 0 && (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <div className={classes.flexdiv}>
              <div className={classes.typo}>전자결재</div>
              <div>
                <Chip
                  className={classes.chipbtn}
                  onClick={() => navigate('/approval')}
                  label={restOffday}
                />
                <Chip
                  className={classes.chipbtn}
                  onClick={() => navigate('/approval')}
                  label='휴가신청/내역'
                />
              </div>
            </div>
            <StepConfirm
              steps={['승인대기', '마스터', '지점장', '완료']}
              approval={approval}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};
