import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import {
  Button,
  Divider,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DefaultApproveEmp, IApproveEmp } from '../../models/approve';
import { useMany, useSaver } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import { IEmp } from '../../models/emp';

interface Props {}

const useStyles = makeStyles({
  root: {
    padding: '4%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2em',
  },
  headerchange: {
    fontSize: '1.2em',
    marginLeft: '1.2em',
    marginBottom: '.6em',
  },
  container: {
    padding: '3%',
    margin: '6% auto auto 5%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btn: {
    textAlign: 'right',
    margin: '10%',
    '& .MuiButton-root': {
      minWidth: '25vw',
    },
  },
  divi: {
    margin: '1em 1.5em 2em 1.5em',
  },
  contents: {
    margin: '2% 7%',
    display: 'flex',
    alignItems: 'center',
  },
  calendar: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
    },
    '& .MuiInputBase-input ': {
      textAlign: 'center',
    },
    margin: '1em auto',
  },
  typoheader: {
    marginRight: '1em',
  },
  reason: {
    margin: '5% 7%',
  },
  typo: {
    margin: '1em',
  },
  txtfield: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
    },
  },
  select: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      padding: '15.5px 14px',
    },
  },
});

export const Approval: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { session } = useSession();
  const [startDate, setStartDate] = useState<MaterialUiPickersDate>(new Date());
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>(new Date());
  const [value, setValue] = useState<string>('연차');
  const [valueTime, setValueTime] = useState<string>('');
  const contentsRef = useRef<any>(null);
  const startDateChange = (Date: MaterialUiPickersDate) => {
    setStartDate(Date);
  };
  const DateChange = (Date: MaterialUiPickersDate) => {
    setStartDate(Date);
    setEndDate(Date);
  };
  const endDateChange = (Date: MaterialUiPickersDate) => {
    setEndDate(Date);
  };

  const dateToisoString = (newDate: MaterialUiPickersDate) =>
    newDate?.toISOString().split('T')[0];

  const { data: loginuser, refetch: refetchLoginuser } = useMany<IEmp>('emp', {
    cmd: 'read',
    id: session.user.id,
  });

  const { data: approval } = useMany<IApproveEmp>('approveemp', {
    cmd: 'read',
    id: 3,
  });
  // console.log('approval>>>>>>>>>>>>', approval);
  const approvalSaver = useSaver<IApproveEmp>(
    'approveemp',
    (newap) => {
      console.log('newap>>', newap);
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  // 시작일, 종료일, 기간 설정
  const sd = dateToisoString(startDate);
  const ed = dateToisoString(endDate);
  const sM = startDate && startDate.getMonth();
  const sD = startDate && startDate.getDate();
  const eM = endDate && endDate.getMonth();
  const eD = endDate && endDate.getDate();
  const emd = endDate && new Date(endDate.getFullYear(), endDate.getMonth(), 1);
  const bM = emd && new Date(emd.setDate(emd.getDate() - 1));
  const count =
    Number(eM) - Number(sM) === 0
      ? Number(eD) - Number(sD) + 1
      : Number(eM) - Number(sM) === 1
      ? Number(eD) + (bM?.getDate() || 0) - Number(sD)
      : -1;
  // const cc = sM !== eM ? eD
  const kinds = valueTime === '오전' ? 1 : valueTime === '오후' ? 2 : 0;

  const datepicker = (
    label: string,
    val: MaterialUiPickersDate,
    onChange: (Date: MaterialUiPickersDate) => void
  ) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
      <DatePicker
        format='yyyy/MM/dd'
        value={val}
        onChange={onChange}
        className={classes.calendar}
        disablePast
        autoOk
        variant='inline'
        inputVariant='outlined'
        disableToolbar
        label={label}
      />
    </MuiPickersUtilsProvider>
  );

  useEffect(() => {
    if (value !== '반차') setValueTime('');
  }, [value]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIosIcon />
        </IconButton>
        휴가 신청 / 내역
      </div>
      <div className={classes.container}>
        <div>잔여연차 {loginuser?.offday}일</div>
        <Typography
          component='div'
          onClick={() => navigate('/approvalhistory')}
          className={classes.typoheader}
        >
          결재 내역
        </Typography>
      </div>
      <Divider className={classes.divi} />
      <div className={classes.contents}>
        <TextField
          select
          value={value}
          variant='outlined'
          fullWidth
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setValue(evt.target.value)}
          className={classes.select}
        >
          <MenuItem value='반차'>반차</MenuItem>
          <MenuItem value='연차'>연차</MenuItem>
          <MenuItem value='특별휴가'>특별휴가</MenuItem>
        </TextField>
      </div>
      {value !== '반차' ? (
        <div className={classes.contents}>
          {datepicker('시작일', startDate, startDateChange)}
          {datepicker('종료일', endDate, endDateChange)}
        </div>
      ) : (
        <div className={classes.contents}>
          {datepicker('신청일', startDate, DateChange)}
          <TextField
            select
            value={valueTime}
            variant='outlined'
            fullWidth
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValueTime(evt.target.value)}
            className={classes.select}
            label='오전 / 오후 선택해주세요.'
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value=''>---</MenuItem>
            <MenuItem value='오전'>오전[9시~13시]</MenuItem>
            <MenuItem value='오후'>오후[14시~18시]</MenuItem>
          </TextField>
        </div>
      )}
      <div className={classes.reason}>
        <Typography component='div' className={classes.typo}>
          휴가 목적을 작성해주세요.
        </Typography>
        <TextField
          minRows={5}
          variant='outlined'
          fullWidth
          multiline
          className={classes.txtfield}
          inputRef={contentsRef}
        />
      </div>
      <div className={classes.btn}>
        <Button
          onClick={() => {
            if (sd && ed && loginuser) {
              const newap = {
                ...DefaultApproveEmp,
                approve: 1,
                emp: loginuser.id,
                currseq: 0,
                title: value,
                sdt: sd,
                edt: ed,
                cnt: count,
                contents: contentsRef.current.value,
                state: 0,
                kind: kinds,
              };
              approvalSaver.mutate(newap);
            }
          }}
          variant='outlined'
        >
          완료
        </Button>
      </div>
    </div>
  );
};
