import axios, { AxiosError } from 'axios';
// import { useMutation, useQuery, UseMutationResult } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { iX } from '../utils/utils';
import { Store } from './store';

export function useAll<T extends iX>(model: string) {
  return useQuery<T>(`${model}s`, Store.findAll<T>(model));
}

export function useFind<T extends iX>(model: string, idcmd: number | string) {
  return useQuery<T>(`${model}s`, Store.find<T>(model, idcmd));
}

// const {data} = useFind('emp', 1);

// export function useMany<T>(model: string, params: {}, queryKey: string = '') {
//   // console.log('Store.useMany>>>', model, params);
//   return useQuery<T>(
//     queryKey || `${model}s/${new URLSearchParams(params)}`,
//     Store.finds<T>(model, params)
//   );
// }

export function useMany<T extends iX>(
  model: string,
  params: {},
  initialData?: T,
  enabled: boolean = true,
  queryKey: string = ''
) {
  // console.log('Store.useMany>>>', model, params);
  return useQuery<T>(
    queryKey || `${model}s/${new URLSearchParams(params)}`,
    Store.finds<T>(model, params),
    { initialData, enabled }
  );
}

export function useOne<T extends iX>(
  model: string,
  params: {},
  initialData?: T,
  enabled: boolean = true,
  queryKey: string = ''
) {
  // console.log('Store.useMany>>>', model, params);
  return useQuery<T>(
    queryKey || `${model}s/${new URLSearchParams(params)}`,
    Store.findOne<T>(model, params),
    { initialData, enabled }
  );
}

export function useSaver<T extends iX>(
  model: string,
  onSuccess: (datum: T) => void = () => {},
  onError: (err: AxiosError) => void = () => {}
) {
  return useMutation((bdy: T) => Store.save(model, bdy), {
    onSuccess,
    onError,
  });
}

export function useRemover(
  model: string,
  onSuccess: () => void = () => {},
  onError: (err: AxiosError) => void = () => {}
) {
  return useMutation((id: number = 0) => Store.delete(model, id), {
    onSuccess,
    onError,
  });
}

/*
const saverX = useMutation(
    (bdy: {}) => Store.save(model, bdy),
    // (mdl: string, bdy: {}) => Store.set(mdl, bdy),
    {
      // When mutate is called:
      onMutate: async (newFolder: IFolder) => {
        console.log('onMutate');
        // setFolder(DefaultFolder);
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries('folders');

        // Snapshot the previous value
        const previousTodos = queryClient.getQueryData<IFolder[]>('folders');

        // Optimistically update to the new value
        // if (previousTodos) {
        //   queryClient.setQueryData<IFolder[]>('folders', {
        //     ...previousTodos,
        //     newFolder,
        //   });
        // }

        return { previousTodos };
      },

      onSuccess: onSaveSuccess,

      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        console.log('onError', err);
        if (context?.previousTodos) {
          queryClient.setQueryData<IFolder[]>('folders', context.previousTodos);
        }
      },
      // Always refetch after error or success:
      // onSettled: () => {
      //   console.log('onSettled');
      //   queryClient.invalidateQueries('folders');
      // },
    }
  );
  */
