import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Collapse } from '@material-ui/core';
import { HTInput } from './HTInput';
import { TAreaCode } from '../../models/etc';

interface Props {
  using: string;
  firstHp: string;
  setFirstHp: Dispatch<SetStateAction<TAreaCode>>;
  hp: string;
  setHp: Dispatch<SetStateAction<string>>;
  NumpadState: boolean;
  onChangeNumpad: () => void;
  onChangePwNumpad: () => void;
  openHp?: boolean;
  // setPwHelperText?: Dispatch<SetStateAction<JSX.Element | string>>;
}

export const HpInput: React.FC<Props> = ({
  using,
  firstHp,
  setFirstHp,
  hp,
  setHp,
  NumpadState,
  onChangeNumpad,
  onChangePwNumpad,
  // setPwHelperText,
  openHp,
}) => {
  const [helperText, setHelperText] = useState<JSX.Element | string>('');

  const isSettingNumpad = () => {
    onChangeNumpad();
    setHelperText('');
  };

  useEffect(() => {
    //   let msg: JSX.Element | string;
    //   if (hp) {
    //     const inputHp = users.find((user: any) => user.hp === `010${hp}`);
    //     if (using === 'signup') {
    //       if (inputHp === undefined) {
    //         msg = (
    //           <strong style={{ color: '#1976D2' }}>
    //             가입 가능한 핸드폰번호입니다.
    //           </strong>
    //         );
    //         setHelperText(msg);
    //         clear();
    //       } else {
    //         msg = '이미 가입된 핸드폰입니다.';
    //         setHp('');
    //         setHelperText(msg);
    //       }
    //     } else if (inputHp === undefined) {
    //       msg = '가입되지 않은 번호입니다.';
    //       setHelperText(msg);
    //       setHp('');
    //     } else {
    //       msg = <strong style={{ color: '#1976D2' }}>확인되었습니다.</strong>;
    //       setHelperText(msg);
    //       clear();
    //     }
    //   }
    if (hp) {
      isSettingNumpad();
      onChangePwNumpad();
    }
  }, [hp.length === 8]);

  return (
    <Collapse in={!openHp} timeout={700}>
      <HTInput
        from='hp'
        length={8}
        input={hp}
        setInput={setHp}
        title='핸드폰 번호'
        helperText={helperText}
        NumpadState={NumpadState}
        onChangeNumpad={isSettingNumpad}
        firstHp={firstHp}
        setFirstHp={setFirstHp}
      />
    </Collapse>
  );
};
