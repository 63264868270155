import React from 'react';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { Divider, makeStyles } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import HistoryIcon from '@material-ui/icons/History';
import TodayIcon from '@material-ui/icons/Today';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import ImageField from './ImageField';
import { IBillSvc } from '../../models/bill';
import { useMany, useSaver } from '../../hooks/api';
import { ICustAddr } from '../../models/customer';
import { useSession } from '../../hooks/session';

const useStyles = makeStyles({
  item: {
    minHeight: '12vh',
    margin: '4%',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    // fontSize: '1.1em',
    marginBottom: '2%',
  },
  enterpw: {
    margin: '2% 4%',
    display: 'flex',
    alignItems: 'center',
    // fontSize: '1.1em',
  },
  content: {
    marginLeft: '.3em',
    marginRight: '.3em',
    backgroundColor: 'rgb(0, 137, 123)',
    borderRadius: '30px',
    padding: '.1em .4em',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '2%',
  },
  divi: {
    margin: '2%',
  },
  strong: {
    fontWeight: 700,
    color: 'lime',
  },
  pre: {
    whiteSpace: 'pre-wrap',
  },
});
interface Props {
  billservice: IBillSvc;
  updateBillservice: (
    type: 'midmv' | 'addrimg' | 'placeimg' | 'enterimg',
    id: number | undefined
  ) => void;
  refetchBillSvc: () => void;
}

interface IbaseOption {
  id: string;
  name: string;
  isOption: boolean;
  cntDigit: number;
}

export const Invoice: React.FC<Props> = ({
  billservice,
  updateBillservice,
  refetchBillSvc,
}) => {
  const {
    custcmt,
    haspet,
    petmemo,
    indicate,
    enterpw,
    enterinfo,
    waste,
    addrid,
  } = billservice;
  const classes = useStyles();
  const { socket } = useSession();
  const { data: custaddr, refetch: refetchCustAddr } = useMany<ICustAddr>(
    'custaddr',
    {
      cmd: 'read',
      id: addrid,
    }
  );

  const custaddrSaver = useSaver<ICustAddr>(
    'custaddr',
    (newca) => {
      console.log('newcustaddr >> ', newca);
      refetchCustAddr();
      refetchBillSvc();
      socket?.emit('sync', {
        ...newca,
        model: 'custaddr',
      });
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const updateCustAddrImg = (
    type: 'midmv' | 'addrimg' | 'placeimg' | 'enterimg',
    upfileId: number | undefined
  ) => {
    if (custaddr) {
      custaddrSaver.mutate({ ...custaddr, [type]: upfileId });
    }
  };

  const BASE_OPTION: IbaseOption[] = [
    {
      id: 'SS',
      name: '슈퍼싱글',
      isOption: false,
      cntDigit: 10,
    },
    {
      id: 'Q',
      name: '퀸',
      isOption: false,
      cntDigit: 1,
    },
    {
      id: 'Bedding',
      name: '이불 커버',
      isOption: true,
      cntDigit: 10,
    },
    {
      id: 'Pillow',
      name: '베개 커버',
      isOption: true,
      cntDigit: 1,
    },
  ];

  const getNumByDigit = (cnt: number, digit: number) =>
    Math.floor(cnt / digit) % 10;

  const getOptCntInfo = (optcnt: number) => {
    const options = BASE_OPTION.filter((bo) => bo.isOption);

    return options.map((bo) => {
      const optCnt = getNumByDigit(optcnt, bo.cntDigit);

      return (
        !!optCnt && (
          <span id={bo.id} className={classes.content}>
            {bo.name} 개수 : {optCnt} <br />
          </span>
        )
      );
    });
  };

  return (
    <>
      <div className={classes.enterpw}>
        <VpnKeyOutlinedIcon className={classes.icon} />
        현관비밀번호
        <span className={classes.content}>
          {enterpw === '_free'
            ? '자유출입'
            : enterpw === '_call'
            ? '경비호출'
            : enterpw}
        </span>
        {enterinfo}
      </div>
      <div className={classes.enterpw}>
        <DeleteOutlineIcon className={classes.icon} />
        분리수거
        <span className={classes.content}>{waste}</span>
      </div>
      <div className={classes.enterpw}>
        <DriveEtaIcon className={classes.icon} />
        {billservice.parking}
      </div>
      {billservice.itemids?.includes('3') && billservice.optcnts > 0 && (
        <div className={classes.enterpw}>
          <KingBedOutlinedIcon className={classes.icon} />
          베딩 추가 옵션
          <div className={classes.divi}>
            {getOptCntInfo(billservice.optcnts)}
          </div>
        </div>
      )}
      <Divider className={classes.divi} />
      <div className={classes.item}>
        <div className={classes.title}>
          <TodayIcon className={classes.icon} />
          고객 요청사항
        </div>
        <span className={clsx(classes.strong, classes.pre)}>
          {!!custcmt && (
            <>
              <div>{custcmt}</div>
              <br />
            </>
          )}
          {!!haspet && (
            <>
              <div>{petmemo}</div>
              <br />
            </>
          )}
          {!!billservice.remark && (
            <>
              <div>{billservice.remark}</div>
              <br />
            </>
          )}
          {!!billservice.addrcmt && <div>{billservice.addrcmt}</div>}
        </span>
      </div>

      <Divider className={classes.divi} />
      <div className={classes.item}>
        <div className={classes.title}>
          <HistoryIcon className={classes.icon} />
          지시사항
        </div>
        <span className={clsx(classes.strong, classes.pre)}>
          {!!indicate && (
            <>
              <div>{indicate}</div>
              <br />
            </>
          )}
          {!!billservice.etc && <div>{billservice.etc}</div>}
        </span>
      </div>
      <ImageField
        type='addrimg'
        bsId={billservice.id}
        imglink={billservice.addrlink}
        updater={updateCustAddrImg}
      >
        <HomeOutlinedIcon />
        <span>주소별 사진</span>
      </ImageField>
      <ImageField
        type='midmv'
        bsId={billservice.id}
        imglink={billservice.imglink}
        updater={updateBillservice}
      >
        <AssignmentTurnedInOutlinedIcon />
        <span>서비스 완료 사진</span>
      </ImageField>
    </>
  );
};
