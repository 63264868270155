import React, {
  useReducer,
  useEffect,
  MouseEvent,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import clsx from 'clsx';
import {
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import TabletAndroidIcon from '@material-ui/icons/TabletAndroid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { HTNumpad } from './HTNumpad';
import { AREA_CODES, TAreaCode } from '../../models/etc';

interface Props {
  from: string;
  length: number;
  input: string;
  setInput: Dispatch<SetStateAction<string>>;
  title: string;
  helperText?: JSX.Element | string;
  NumpadState: boolean;
  onChangeNumpad: () => void;
  firstHp?: string;
  setFirstHp?: Dispatch<SetStateAction<TAreaCode>>;
}

const useStyles = makeStyles({
  root: {
    padding: '4% 10% 0 10%',
  },
  title: {
    fontSize: '15px',
    fontWeight: 600,
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '.5em',
    height: '20px',
    weight: '20px',
  },
  pwShow: {
    padding: 0,
    marginLeft: '.5em',
  },
  container: {
    padding: '7px',
    margin: '0 3%',
    borderRadius: '100px',
    border: '.5px solid rgba(143, 143, 143, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerOpened: {
    padding: '4%',
    margin: 0,
    border: '1px solid #1E88E5',
  },
  dialing: {
    padding: '2% 1% 2% 2%',
    letterSpacing: '3px',
    fontSize: '14.5px',
  },
  divider: {
    fontWeight: 800,
    padding: '1%',
  },
  inputBasic: {
    borderBottom: '.1px solid #90CAF9',
    padding: '2%',
  },
  inputDone: {
    padding: '3% 2%',
  },
  helperText: {
    margin: '.5em 1em 0 0',
    display: 'flex',
    flexDirection: 'row-reverse',
    color: '#E91E63',
    fontWeight: 800,
  },
  resize: {
    fontSize: '15px',
    fontWeight: 700,
    letterSpacing: 1,
  },
});

export const HTInput: React.FC<Props> = ({
  from,
  length,
  input,
  setInput,
  title,
  helperText,
  NumpadState,
  onChangeNumpad,
  firstHp,
  setFirstHp,
}) => {
  const classes = useStyles();
  const reducer = (pwHidden: boolean) => !pwHidden;
  const [first, setFirst] = useState<'010' | '02'>('010');
  const [pwHidden, pwShowing] = useReducer(reducer, false);

  const inputLength = Array(length)
    .fill(0)
    .map((_, idx) => idx);

  const onAddNum = (evt: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (input.length < length) {
      setInput(input + evt.currentTarget.id);
    }
  };
  const onDeleteNum = () =>
    setInput(input.slice(0, input.length === 0 ? 0 : input.length - 1));
  const onClearNums = () => setInput('');

  useEffect(() => {
    window.scrollBy(0, 150);
  }, [NumpadState]);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {from === 'hp' ? (
          <TabletAndroidIcon className={classes.icon} />
        ) : (
          <LockOutlinedIcon className={classes.icon} />
        )}
        {title}
        {from === 'pw' && (
          <IconButton onClick={pwShowing} className={classes.pwShow}>
            {pwHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        )}
      </div>
      <Box
        onClick={onChangeNumpad}
        className={clsx(classes.container, {
          [classes.containerOpened]: NumpadState,
        })}
      >
        <strong className='hidden'>1</strong>
        {from === 'hp' && firstHp && setFirstHp && (
          // <>
          //   <span
          //     className={clsx(classes.dialing, {
          //       hidden: !input.length && !NumpadState,
          //     })}
          //   >
          //     <strong>010</strong>
          //   </span>
          //   <span
          //     className={clsx(classes.divider, {
          //       hidden: !input.length && !NumpadState,
          //     })}
          //   >
          //     .
          //   </span>
          // </>
          <TextField
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            onChange={(
              evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const a = evt.target.value as TAreaCode;
              setFirstHp(a);
            }}
            value={firstHp}
            variant='standard'
            select
          >
            {/* <MenuItem value='010'>010</MenuItem>
            <MenuItem value='02'>02</MenuItem>
            <MenuItem value='031'>031</MenuItem> */}
            {AREA_CODES.map((code) => (
              <MenuItem key={code} value={code}>
                {code}
              </MenuItem>
            ))}
          </TextField>
        )}
        {inputLength.slice(0, from === 'hp' ? 4 : length).map((i) => (
          <span
            key={i}
            className={clsx({
              [classes.inputBasic]: !input[i],
              [classes.inputDone]: input[i],
              hidden: !input.length && !NumpadState,
            })}
          >
            {!input[i] ? (
              <strong className='hidden'>.</strong>
            ) : from !== 'pw' || pwHidden || i === input.length - 1 ? (
              <strong>{input[i]}</strong>
            ) : (
              '*'
            )}
          </span>
        ))}
        {from === 'hp' && (
          <>
            <span
              className={clsx(classes.divider, {
                hidden: !input.length && !NumpadState,
              })}
            >
              .
            </span>
            {inputLength.slice(4, 8).map((i) => (
              <span
                key={i}
                className={clsx({
                  [classes.inputBasic]: !input[i],
                  [classes.inputDone]: input[i],
                  hidden: !input.length && !NumpadState,
                })}
              >
                {from === 'hp' && input[i] ? (
                  <strong>{input[i]}</strong>
                ) : (
                  <strong className='hidden'>.</strong>
                )}
              </span>
            ))}
          </>
        )}
        <strong className='hidden'>1</strong>
      </Box>
      <div className={classes.helperText}>{helperText}</div>
      <HTNumpad
        NumpadState={NumpadState}
        onAddNum={onAddNum}
        onDeleteNum={onDeleteNum}
        onClearNums={onClearNums}
      />
    </div>
  );
};
