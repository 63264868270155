import React, { useRef } from 'react';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DomainOutlinedIcon from '@material-ui/icons/DomainOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../hooks/session';
import { HTAvatar } from '../HT/HTAvatar';

interface Props {}

const useStyles = makeStyles({
  root: {
    padding: '4%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.1em',
  },
  avatorTop: {
    display: 'flex',
    justifyContent: 'center',
  },
  edit: {
    margin: '-1.5em 0 0 3em',
    color: '#E53935',
  },
  container: {
    padding: '0 9%',
  },
  title: {
    marginBottom: '1.5em',
    fontSize: '1.15em',
  },
  content: {
    display: 'flex',
    marginBottom: '1em',
    alignItems: 'center',
  },
  txt: {
    textAlign: 'center',
  },
  divid: {
    margin: '2.2em 1.5em',
  },
  pw: {
    marginTop: '.1em',
  },
  value: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  comps: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
});

export const MyInfo: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { session, login } = useSession();
  const imgRef = useRef<any>(null);

  const userRole = () => {
    const x = session.user.role;
    switch (x) {
      case 1:
        return '직원';
      case 2:
        return '지점장';
      case 3:
        return '매니저';
      case 4:
        return '드라이버';
      default:
        return '미정';
    }
  };

  // const userZone = () => {
  //   const x = session.user.zone;
  //   switch (x) {
  //     case 1:
  //       return '홈텔리어 용산마포지점';
  //     case 2:
  //       return '홈텔리어 강서지점';
  //     default:
  //       return '미정';
  //   }
  // };

  const info = [
    {
      icon: <PhoneAndroidOutlinedIcon />,
      id: '핸드폰번호',
      value: session.user.mobile,
      key: 1,
    },
    {
      icon: <LockOutlinedIcon />,
      id: '비밀번호',
      value: '******',
      key: 1,
    },
    {
      icon: <AccountBoxOutlinedIcon />,
      id: '직급',
      value: userRole(),
      key: 2,
    },
    {
      icon: <DomainOutlinedIcon />,
      id: '소속',
      value: session.user.cname,
      key: 2,
    },
  ];

  const ci = document.getElementById('changeImg');

  const infoSpread = (x: number) =>
    info.map(
      (content) =>
        content.key === x && (
          <Grid container key={content.id} className={classes.content}>
            {/* <Grid item xs={1} /> */}
            <Grid item xs={1}>
              {content.icon}
            </Grid>
            <Grid item xs={4} key={content.id}>
              {content.id}
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={6}
              key={content.value}
              className={clsx(classes.value, {
                [classes.pw]: content.id === '비밀번호',
              })}
            >
              {content.value}
              {content.id === '비밀번호' && (
                <Button size='small' onClick={() => navigate('/pwchange')}>
                  변경
                </Button>
              )}
            </Grid>
          </Grid>
        )
    );

  return (
    <div className={classes.root}>
      <div
        className={classes.header}
        aria-hidden='true'
        onClick={() => window.history.back()}
      >
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIosIcon aria-hidden='true' />
        </IconButton>
        내 정보
      </div>
      <div className={classes.avatorTop}>
        <HTAvatar from='myinfo' />
      </div>
      <div className={classes.txt}>
        <IconButton onClick={() => ci?.click()} className={classes.edit}>
          <EditOutlinedIcon />
          <input
            type='file'
            accept='image/*'
            ref={imgRef}
            id='changeImg'
            hidden
          />
        </IconButton>
      </div>
      <div className={classes.txt}>{session.user.name}</div>
      <Divider className={classes.divid} />
      <div className={classes.container}>
        <div className={classes.title}>기본 정보</div>
        {infoSpread(1)}
        <Divider className={classes.divid} />
        <div className={classes.title}>소속 정보</div>
        {infoSpread(2)}
      </div>
    </div>
  );
};
