import { useNavigate } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
import {
  Avatar,
  Button,
  Card,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { DriveEtaRounded } from '@material-ui/icons';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import koLocale from 'date-fns/locale/ko';
import React, { useEffect, useState } from 'react';
import { useMany, useOne, useSaver } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import {
  BillStates,
  IBillSvc,
  IGasaService,
  IMvState,
  MVSTATE,
} from '../../models/bill';
import { DefaultEmpCommute, IEmpCommute } from '../../models/emp';
import { IHoliday } from '../../models/etc';
import { ItemInfo } from '../../models/item';
import {
  dateFormat,
  minToTime,
  dayTostring,
  getRealSpendTime,
  getLastMonthStr,
  getNextMonthStr,
} from '../../utils/date-utils';
import { callNative } from '../../utils/native-connector';
import withAuthentication from '../../_withAuthentication';
import { Modal } from '../HT/Modal';
import { HomeTab } from './HomeTab';
import { GasaService } from './GasaService';
import { ICareCust } from '../../models/customer';
import { CareService } from './CareService';

interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      width: '2em',
      height: '2em',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: '.7em',
  },
  attend: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '.9em',
    fontSize: '1.1em',
    height: '3vh',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    padding: '0.2% 0 2% 0',
    backgroundColor: '#37474F',
    overflow: 'scroll',
  },
  right: {
    alignSelf: 'flex-end',
  },
  mgrname: {
    textAlign: 'center',
    margin: '.3rem',
    color: '#bad02f',
  },
  paper: {
    marginBottom: '5%',
  },
  badge: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    textAlign: 'center',
    backgroundColor: 'white',
    fontSize: '8px',
    color: 'black',
    margin: '2px',
  },
  small: {
    // width: theme.spacing(3.4),
    // height: theme.spacing(3.4),
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    color: '#fff',
    fontSize: '12px',
    // fontSize: '.7rem',
    backgroundColor: '#233044',
    marginRight: '.2em',
    fontWeight: 600,
  },
  small2: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '12px',
    color: '#fff',
  },
  addr: {
    backgroundColor: '#37474F',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '15px',
    padding: '0 .5rem',
    fontWeight: 700,
    marginTop: '-.3rem',
  },
  etc: {
    backgroundColor: '#37474F',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 2% 1% 0',
    marginTop: '-2%',
    fontSize: '.9em',
    color: '#CFD8DC',
  },
  etcs: {
    backgroundColor: '#37474F',
    fontSize: '.9em',
    paddingLeft: '2%',
    color: '#CFD8DC',
  },
  driver: {
    backgroundColor: '#37474F',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '2%',
    color: '#CFD8DC',
  },
  driverIcon: {
    fontSize: '1.6em',
    padding: '0 1% 0 0',
    color: '#D13438',
  },
  withname: {
    float: 'right',
  },
  calendar: {
    display: 'none',
  },
  icon: {
    fontSize: '.51em',
    margin: '2% 1% 1% 1%',
  },
  doing: {
    margin: '6% 0 0 19%',
  },
  collect: {
    fontSize: '.6em',
    fontWeight: 900,
    position: 'relative',
    right: '2.8em',
    // top: '2.0em',
    top: '1.8em',
    color: '#bad02f',
  },
  collect2: {
    fontSize: '.6em',
    fontWeight: 900,
    textAlign: 'center',
    position: 'relative',
    top: '-.3em',
    right: '.1em',
    color: '#bad02f',
  },
  lunch: {
    fontSize: '.9em',
    color: '#CFD8DC',
  },
  walk: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: '#42A5F5',
    marginLeft: '.2em',
    // backgroundColor: '#1A237E',
  },
  withmgr: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: '#bad02f',
    marginLeft: '0em',
    marginRight: '-.5em',
  },
  collected: {
    border: '1px solid #D81B60',
    borderRadius: '50px',
  },
  title: {
    fontSize: '.56em',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #37474F',
  },
  fright: {
    float: 'right',
  },
  textCenter: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
}));

const Home: React.FC<Props> = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { session, socket, setFcmtoken, myDrivers } = useSession();

  // 0:ready/uploaded, 1: taking(takeMv), 2: taken(mvLocal), 3: uploading
  const [mvStates, setMvStates] = useState<IMvState[]>([]);

  const [isShowId, setShowId] = useState(false);
  const [isShowLongAddr, setShowLongAddr] = useState(false);

  const [isOpenLeave, setOpenLeave] = useState(false); // 퇴근하기 모달
  const [hasToUpload, setToUpload] = useState(false); // 미 업로드 존재 유무

  const [date, setDate] = useState(dateFormat(new Date()));
  const [day, setDay] = useState(dayTostring(new Date().getDay()));
  const dateView = date.split('-');
  const dateShowing = `${dateView[0]}년 ${dateView[1]}월 ${dateView[2]}일 (${day})`;

  const [driverAlim, setDriverAlim] = useState('');

  const { data: holiday } = useMany<IHoliday[]>(
    'holiday',
    {
      cmd: session.user.cid === 0 ? 'all' : 'gets-gasa-holidays',
      sdt: getLastMonthStr(date),
      edt: getNextMonthStr(date),
    },
    [],
    !!session.user.id
  );

  const { data: commute, refetch: refetchCommute } = useOne<IEmpCommute>(
    'empcommute',
    {
      cmd: 'get-by-mgr',
      ymd: date,
      emp: session.user.id,
    },
    session.user?.id ? undefined : DefaultEmpCommute,
    !!session.user.id && session.user.cid === 0 && session.user.zone !== 5
  );

  const saveCommute = useSaver<IEmpCommute>(
    'empcommute',
    (comm) => {
      console.log('afterSaveCommute>>>', comm);
      refetchCommute();
      setOpenLeave(false);
      socket?.emit('sync', {
        ...comm,
        model: 'empcommute',
      });
    },
    (err: AxiosError) => {
      console.error('Error on saveCommute>>>', err.response?.data);
    }
  );

  const { data: billsvc, refetch: refetchBillSvc } = useMany<IBillSvc[]>(
    'billsvc',
    {
      cmd: session.user.role === 3 ? 'finds-by-mgr' : 'gets-by-visitdts',
      dt: date,
      emp: session.user.id,
      sdt: date,
      edt: date,
    },
    session.user?.id ? undefined : [],
    !!session.user.id && session.user.cid === 0 && session.user.zone !== 5
  );

  const { data: gasaservices, refetch: refetchGasaservices } = useMany<
    IGasaService[]
  >(
    'gasaservice',
    {
      cmd: session.user.role < 3 ? 'gets-by-visitdts-cid' : 'finds-by-mgr-cid',
      emp: session.user.id,
      cid: session.user.cid,
      visitdt: date,
    },
    session.user?.id ? undefined : [],
    !!session.user.id && session.user.cid !== 0 && session.user.zone !== 5
  );

  const { data: carecusts, refetch: refetchCareservices } = useMany<
    ICareCust[]
  >(
    'carecust',
    {
      cmd: session.user.role >= 3 ? 'gets-by-visitdt-mgr' : 'gets-by-visitdt',
      emp: session.user.id,
      visitdt: date,
    },
    session.user?.id ? undefined : [],
    !!session.user.id && session.user.zone === 5
  );

  const onChangeDateBefore = () => {
    const newDate = new Date(date);
    const beforeDate = new Date(newDate.setDate(newDate.getDate() - 1));
    /* 2024-02-15 - 참사랑: 모든 일정 다 볼 수 있도록 요청 */
    // do {
    //   beforeDate = new Date(newDate.setDate(newDate.getDate() - 1));
    //   newDate = beforeDate;
    // } while (
    //   session.user.zone !== 5 &&
    //   holiday
    //     ?.filter((w) => w.hname !== '마감')
    //     .map((q) => q.ymd)
    //     .includes(dateFormat(beforeDate))
    // );
    setDay(dayTostring(beforeDate.getDay()));
    setDate(dateFormat(beforeDate));
  };

  /* 2024-02-15 - 참사랑: 모든 일정 다 볼 수 있도록 요청 */
  // const notAllowDateAfter = () => {
  //   const now = new Date();
  //   const isToday = date === dateFormat(now) && now.getHours() < 17;
  //   const isTomorrow = date > dateFormat(now);
  //   const isMgrOrDriver = session.user.role === 3 || session.user.role === 4;

  //   return session.user.zone !== 5 && isMgrOrDriver && (isToday || isTomorrow);
  // };

  const onChangeDateAfter = () => {
    // if (notAllowDateAfter()) return;

    const newDate = new Date(date);
    const afterDate = new Date(newDate.setDate(newDate.getDate() + 1));
    /* 2024-02-15 - 참사랑: 모든 일정 다 볼 수 있도록 요청 */
    // do {
    //   afterDate = new Date(newDate.setDate(newDate.getDate() + 1));
    //   newDate = afterDate;
    // } while (
    //   session.user.zone !== 5 &&
    //   holiday
    //     ?.filter((w) => w.hname !== '마감')
    //     .map((q) => q.ymd)
    //     .includes(dateFormat(afterDate))
    // );
    setDay(dayTostring(afterDate.getDay()));
    setDate(dateFormat(afterDate));
  };

  const calendarDateChange = (D: MaterialUiPickersDate) => {
    if (!D) return;
    const now = new Date();
    // if (D.getDate() > now.getDate() && notAllowDateAfter()) return;

    setDate(dateFormat(D));
    setDay(dayTostring(D.getDay()));
  };

  const setItem = (item: string, collect?: boolean, bicnts?: string) => {
    const info = ItemInfo[`${item}:${collect ? 1 : 0}`];
    // console.log('iiiiiiiiiii>>>', item, bicnts, collect);
    // console.log('info>>>', info, item);
    const cnts = bicnts?.split(',');
    let cnt = 1;
    if (cnts && cnts.length) {
      const ccc = cnts.find((c) => c.split(':')[0] === item);
      cnt = Number(ccc?.split(':')[1]);
    }
    const realCnt =
      info.name === '베' ? Math.floor(cnt / 10) + (cnt % 10) : cnt;

    const marginTop = collect ? '.1rem' : '0rem';

    return (
      <div key={Math.random()}>
        <Avatar
          className={clsx(classes.small2, { [classes.collected]: collect })}
          style={{ backgroundColor: info?.color, marginTop }}
        >
          {info?.name}
          {realCnt > 1 ? realCnt : ''}
        </Avatar>
        {collect && <div className={classes.collect2}>회수</div>}
      </div>
    );
  };

  const billitems = (bs: IBillSvc) => {
    const colls: { [k: string]: boolean } =
      bs.colls?.split(',').reduce((s, c) => {
        const [id, isColl] = c.split(':');
        return { ...s, [id]: isColl === '1' };
      }, {}) || {};
    // console.log('bs>>>>>>', bs, colls);
    return (
      billsvc &&
      bs.itemids
        ?.split(',')
        .sort((a, b) => Number(a) - Number(b))
        .map((item) => setItem(item, colls[item], bs.bicnts))
    );
  };

  const setProgress = (progress?: number) => {
    const bstate = BillStates[progress || 0];
    if (bstate)
      return <FiberManualRecordIcon style={{ color: bstate.color }} />;
    return <ErrorOutlineIcon />;
  };

  const refetchBillServices = () => {
    refetchBillSvc();

    setTimeout(() => {
      if (window.Android) callNative({ cmd: 'giveMvs' });
      // window.mvLocal(['endmv_21_400.mp4', 'startmv_188_54.mp4']);
    }, 1000);
  };

  const updateGasaService = () => refetchGasaservices();

  const updateCareService = () => refetchCareservices();

  const setMyMvState = (bsid: number | string, mv: string, state: number) => {
    const mvState = mvStates.find((ms) => ms.id === Number(bsid));
    console.log('-----------current>>>', mvState, mvStates);

    if (!mvState) return;

    const field = mv === 'startmv' || mv === 'smv' ? 'smv' : 'emv';

    mvState[field] = state;
    setMvStates([...mvStates]); // 일괄 반영
  };

  // -------------------------------------------------------------- with native
  const mvFromNative = (q: string, state: number) => {
    console.log('mvFromNative>>>>', q);
    console.log('billservices & mvStates>>>', billsvc, mvStates);

    const [mv, custid, bsid] = q.replace('.mp4', '').split('_');

    console.log('qq>>>', mv, custid, bsid);

    setMyMvState(bsid, mv, state);

    // const isStartmv = mv === 'startmv';
    // const mvState = mvStates.find((ms) => ms.id === Number(bsid));
    // // console.log('current>>>', mvState);
    // if (!mvState) return;
    // mvState[isStartmv ? 'smv' : 'emv'] = state;
    // setMvStates([...mvStates]); // 일괄 반영

    if (billsvc?.length && state === MVSTATE.UPLOADED) {
      const newer = { ...billsvc.find((bs) => Number(bsid) === bs.id) };
      console.log('nnnnnnnnnnnnnn>>', newer);
    }
  };

  window.uploaded = (q: string) => {
    console.log('-------uploaded>>>', q);
    refetchBillServices();

    mvFromNative(q, MVSTATE.UPLOADED);
  };

  window.mvLocal = (qs) => {
    window.log(`mvLocal>>>>${qs}`);
    console.log('mvLocal>>>>', qs, mvStates);
    qs.map((q) => mvFromNative(q, MVSTATE.TAKEN));
  };

  window.setFCMToken = (token) => {
    console.log('session>>', session, token);
    window.log(`${session.didLogin}> ${token}`);

    setFcmtoken(token);
  };

  // -------------------------------------------------------- native

  const leaveOffice = () => {
    console.log(
      'leaveOffice>>',
      mvStates,
      date,
      new Date().toLocaleTimeString().substr(0, 5)
    );
    if (hasToUpload) {
      setOpenLeave(false);
    } else {
      saveCommute.mutate({
        ...commute,
        id: commute && commute.id ? commute.id : 0,
        ymd: date,
        emp: commute && commute.emp ? commute.emp : session.user.id,
        stm: commute?.stm || '09:00',
        etm: new Date().toTimeString().substr(0, 5),
      });
    }
  };

  useEffect(() => {
    if (session.user.auth === 9) {
      navigate('/waiting');
    }
  }, [session.user.auth]);

  useEffect(() => {
    // console.log('hasToUpload>>', mvStates);
    const notYetUploads = mvStates?.find(
      (ms) => ms.smv === MVSTATE.TAKEN || ms.emv === MVSTATE.TAKEN
    );

    setToUpload(!!notYetUploads);
  }, [mvStates]);

  useEffect(() => {
    if (billsvc?.length) {
      setMvStates([...billsvc?.map((bs) => ({ id: bs.id, smv: 0, emv: 0 }))]);
      callNative({ cmd: 'giveMvs' });
    }
  }, [billsvc]);

  useEffect(() => {
    if (socket) {
      socket.on('sync', (data: any) => {
        // console.log('Home.onSync>>>>>', data, billsvc);
        if (
          'cid' in data &&
          Number(data.cid) === session.user.cid &&
          (data.idmdl === 'bill' ||
            data.model === 'billservice' ||
            data.model === 'billsvc')
        ) {
          console.log(
            'bs.sync.data.model>>>>',
            data.model,
            session.user.id,
            Number(data.mgremp) === session.user.id
          );
          // if (Number(data.mgremp) === session.user.id) { // 직원용 모두보기 적용!
          refetchBillServices();
          // }
        } else if (data.model === 'empcommute') {
          refetchCommute();
        }
      });

      socket.on('mgr-msg', (data: any) => {
        // console.log('Home.onMgrMsg>>>>>', data);
        if (
          'cid' in data &&
          Number(data.cid) === session.user.cid &&
          data?.drivers &&
          data.drivers.includes(session.user.id)
        ) {
          setDriverAlim(data.msg);
        }
      });

      setShowId(!!process.env.REACT_APP_SERVER_URL?.includes('local'));
    }
  }, [socket]);

  return (
    <div className='p10'>
      <h2 className={classes.root}>
        <KeyboardArrowLeftRoundedIcon onClick={onChangeDateBefore} />
        <EventIcon
          className={classes.icon}
          onClick={() => {
            const dateChange = document.getElementById('dateChange');
            dateChange?.click();
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
          <DatePicker
            format='yyyy년MM월dd일'
            value={date}
            onChange={calendarDateChange}
            className={classes.calendar}
            autoOk
            disableToolbar
            id='dateChange'
          />
        </MuiPickersUtilsProvider>
        <small>{dateShowing}</small>
        <KeyboardArrowRightRoundedIcon onClick={onChangeDateAfter} />
      </h2>
      {session.user.cid === 0 && session.user.zone !== 5 && (
        <Card onClick={() => setShowId(!isShowId)} className={classes.attend}>
          <span>
            출근시간: {commute && commute.stm ? commute.stm : '09:00'}
          </span>
          <span>{commute && commute.etm ? commute.etm : '-'} :퇴근시간</span>
        </Card>
      )}
      {billsvc &&
        billsvc
          ?.filter(
            (bs) =>
              session.user.role <= 3 || // 매니저는 gets-by-mgr 커맨드로 가지고 오므로 어차피 본인 스케줄만 확인 가능함.
              !bs.driver ||
              session.user.id === bs.driver
          )
          .filter((bs) => {
            if (bs.mgremp === 3) {
              return bs.zone === session.user.zone;
            }
            return bs.empzone === session.user.zone;
          })
          .map((bs, idx) => {
            const driver = myDrivers?.find((d) => d.id === bs.driver);

            return (
              <Paper
                key={bs.id}
                className={classes.paper}
                variant='outlined'
                id={idx.toString()}
              >
                <Grid container>
                  <Grid item xs={1} className={classes.center}>
                    <span className={classes.doing}>
                      {setProgress(bs.state)}
                    </span>
                    {isShowId && (
                      <small>
                        <small>{bs.id}</small>
                      </small>
                    )}
                  </Grid>
                  <Grid item xs={4} className={classes.center}>
                    <strong className='mr02'>{bs.visittm}</strong>
                    {/* <small>({bs.spendtime}분)</small> */}
                    {/* <small>({minToTime(bs.spendtime ?? 0)})</small> */}
                    <small>
                      ({minToTime(getRealSpendTime(bs.spendtime, bs.withmgr))})
                    </small>
                  </Grid>
                  <Grid item xs={5} className={classes.center}>
                    <div className='flex'>{billitems(bs)}</div>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    className={classes.center}
                    justifyContent='flex-end'
                  >
                    {session.user.role !== 3 ? (
                      <a href={`tel: ${bs.emptel}`}>
                        <Avatar className={classes.small}>
                          {/* {bs.empname?.substring(bs.empname?.length - 2)} */}
                          {bs.empname?.slice(0, 3)}
                        </Avatar>
                      </a>
                    ) : (
                      session.user.id === bs.withmgr && (
                        <a href={`tel: ${bs.emptel}`}>
                          <Avatar className={classes.small}>
                            {/* {bs.empname?.substring(bs.empname?.length - 2)} */}
                            {bs.empname?.slice(0, 3)}
                          </Avatar>
                        </a>
                      )
                    )}
                  </Grid>
                </Grid>
                <div className={classes.addr}>
                  <div
                    onClick={() => setShowLongAddr(!isShowLongAddr)}
                    aria-hidden='true'
                  >
                    {isShowLongAddr ? bs.longaddr : bs.addr}
                  </div>
                  <div className='flex'>
                    {!!bs.afterlunch && (
                      <span className={classes.lunch}>점심 후</span>
                    )}
                    {!!bs.iswalk && (
                      <DirectionsWalkIcon className={classes.walk} />
                    )}
                    {bs.withmgr && (
                      <EmojiPeopleIcon className={classes.withmgr} />
                    )}
                  </div>
                </div>
                {driver && (
                  <div>
                    <a
                      className={classes.driver}
                      href={`tel: ${driver.mobile}`}
                    >
                      <DriveEtaRounded className={classes.driverIcon} />
                      {driver.name} 님{' '}
                    </a>
                  </div>
                )}
                {!!bs.etc && (
                  <div className={classes.etcs}>
                    {bs.etc}
                    {!!bs.withname &&
                      (session.user.role !== 3 ||
                        bs.mgremp === session.user.id) && (
                        <span className={classes.withname}>{bs.withname}</span>
                      )}
                  </div>
                )}

                {!!bs.withmgr && session.user.role === 3 && (
                  <div className={classes.mgrname}>
                    <strong>
                      {session.user.id === bs.mgremp ? bs.withname : bs.empname}
                    </strong>
                    님과 함께 진행합니다!
                  </div>
                )}

                {session.user.role !== 3 || session.user.id === bs.mgremp ? (
                  <HomeTab
                    billservice={bs}
                    refetchBillSvc={refetchBillServices}
                    mvStates={mvStates}
                    setMyMvState={setMyMvState}
                  />
                ) : (
                  ''
                )}
              </Paper>
            );
          })}
      {gasaservices &&
        gasaservices.map((service) => (
          <div key={service.id}>
            <GasaService
              service={service}
              refetchGasaservice={updateGasaService}
            />
          </div>
        ))}
      {carecusts &&
        carecusts.map((service) => (
          <div key={service.id}>
            <CareService
              service={service}
              refetchCareservice={updateCareService}
            />
          </div>
        ))}

      {session.user.cid === 0 && session.user.zone !== 5 && (
        <div className={classes.textCenter}>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<ExitToAppIcon />}
            onClick={() => setOpenLeave(true)}
          >
            {commute?.etm ? `다시 퇴근하기(${commute?.etm})` : '퇴근하기'}
          </Button>
        </div>
      )}

      <hr />

      <div>
        {window.Android ? (
          <>
            <button
              onClick={() => callNative({ cmd: 'restartApp', payload: true })}
            >
              재시작
            </button>
          </>
        ) : (
          <a href='mgrapp.apk?ver=0.2.6'>Download APK 0.2.6</a>
        )}
        <span
          aria-hidden='true'
          onClick={() => callNative({ cmd: 'restartApp', payload: false })}
          className={classes.fright}
        >
          Version 1.0.6
        </span>
      </div>

      <Modal
        modalOpen={isOpenLeave}
        title='퇴근하기'
        type={hasToUpload ? 'alert' : ''}
        msg={
          hasToUpload
            ? '아직 업로드하지 않은 영상이 있습니다!'
            : '퇴근하시겠어요?'
        }
        isCancel={() => setOpenLeave(!isOpenLeave)}
        isSubmit={leaveOffice}
        labelOK={hasToUpload ? '확인' : '퇴근할게요'}
      />

      <Modal
        modalOpen={!!driverAlim}
        title='종료 10분 전'
        type='alert'
        msg={driverAlim}
        isSubmit={() => setDriverAlim('')}
      />
    </div>
  );
};

export default withAuthentication(Home);
// export default Home;
