import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline } from '@material-ui/core';
import './index.css';
import App from './App';
import { HTTheme } from './HTTheme';
import { SessionProvider } from './hooks/session';

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number(process.env.REACT_APP_QUERY_STALE_TIME),
      retryDelay: 30000,
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <>
    <HTTheme>
      <CssBaseline />
      <Router>
        <QueryClientProvider client={reactQueryClient}>
          <SessionProvider>
            <App />
          </SessionProvider>
        </QueryClientProvider>
      </Router>
    </HTTheme>
  </>,
  document.getElementById('root')
);
