// import parse from 'html-react-parser';

import { AxiosError } from 'axios';

export interface iX {
  [index: string]: any;
}

// export const safeString = (str: string) => parse(str);
// export const toHtml = (str: string) =>
//   parse((str || '').replaceAll('\n', '<br>'));

// ***
export const parseError = (error: unknown) => {
  const { response } = error as unknown as AxiosError;
  const { code, message } = response?.data.errors;
  return `${code}: ${message}`;
};

export const fullLink = (relPath?: string) =>
  `${process.env.NEXT_PUBLIC_REAL_SERVER}${relPath}`;

export const uuid = () => new Date().getTime();

// export const uuid = () => {
//   let dt = new Date().getTime();

//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
//     const r = (dt + Math.random() * 16) % 16 | 0;
//     dt = Math.floor(dt / 16);
//     return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
//   });
// };

export const toComma = (n?: number) =>
  n?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const fixOrNot = (n: number, fixCount: number) =>
  fixCount ? n.toFixed(fixCount) : Math.ceil(n);

export function getSum<T extends iX>(
  arr: T[],
  field: string,
  rateField?: string
) {
  return arr.reduce(
    (a: number, b: T) => a + b[field] * (rateField ? b[rateField] || 1 : 1),
    0
  );
  // return arr.reduce((a: number, b: T) => a + b[field], 0);
}

export const toPercent = (total: number, value: number, fixCount: number = 0) =>
  fixOrNot((value / total) * 100, fixCount);

export const toDcPercent = (
  total: number,
  value: number,
  fixCount: number = 0
) => fixOrNot(((total - value) / total) * 100, fixCount);
// ) => fixOrNot(((total - value) / total) * 100, fixCount);

export const MobileRegexp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
export const isValidMobile = (mobile: string) => MobileRegexp.test(mobile);

export const EmailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
export const isValidEmail = (email: string) => EmailRegexp.test(email);

// export const checkValid = (type: 'email' | 'mobile', value: string) => {
export const checkValid = (type: string, value: string) => {
  switch (type) {
    case 'mobile':
      return isValidMobile(value);

    case 'email':
      return isValidEmail(value);

    default:
      return !!value;
  }
};

export const rmPrefix = (str: string) => str.replace(/[^|S]cir_/g, ' ');

export const mobileFormat = (mobile: string) => {
  const mbl = mobile.replace(/[^0-9]/g, '');
  let tmp = '';
  if (mbl.length < 7) {
    tmp += mbl.substr(0, 3);
    tmp += '-';
    tmp += mbl.substr(3);
  } else if (mbl.length < 11) {
    tmp += mbl.substr(0, 3);
    tmp += '-';
    tmp += mbl.substr(3, 3);
    tmp += '-';
    tmp += mbl.substr(6);
  } else {
    tmp += mbl.substr(0, 3);
    tmp += '-';
    tmp += mbl.substr(3, 4);
    tmp += '-';
    tmp += mbl.substr(7);
  }

  return tmp;
};

export const twoLen = (s: number | string) => {
  if (typeof s === 'string' && s.length === 1) return `0${s}`;
  if (typeof s === 'number' && s < 10) return `0${s}`;
  return s;
};

export const isEmpty = <T>(x: string | T[] | number): boolean => {
  if (typeof x === 'string') return !x || x === 'null';
  if (typeof x === 'object') return !x || !x.length;
  return !x;
};

export const mapBy = <T extends iX>(arr: T[], attr: string) => [
  ...arr.map((a) => a[attr]),
];

export const once = (fn: Function) => {
  let isDone = false;

  return (...args: any) => {
    if (!isDone) {
      isDone = true;
      fn(...args);
    }
  };
};

export const getTelWithoutHyphen = (tel: string) => tel.replace(/[^0-9]/g, '');

export const getTelWithHyphen = (tel: string) =>
  getTelWithoutHyphen(tel).replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
