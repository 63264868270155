import React, { useState } from 'react';
import { HTHeader } from '../HT/HTHeader';
import { LoginFooter } from './LoginFooter';
import { LoginForm } from './LoginForm';
import { IEmpWithComp } from '../../models/emp';

interface Props {}

export const Login: React.FC<Props> = () => {
  const [empComps, setEmpComps] = useState<IEmpWithComp[] | []>([]);
  return (
    <>
      <HTHeader from='login' />
      <LoginForm empComps={empComps} setEmpComps={setEmpComps} />
      {!empComps.length && <LoginFooter />}
    </>
  );
};
