import {
  DefaultCustAddr,
  DefaultCustomer,
  ICustAddr,
  ICustomer,
} from './customer';
import { IEmp } from './emp';
import { DefaultItem, IItem } from './item';

export interface IComp {
  id: number;
  createdate?: string;
  workdate?: string;
  name: string;
  kind?: number;
  tel?: string;
  email?: string;
  sigungu?: string;
}

export const DefaultComp: IComp = {
  id: 0,
  name: '',
  kind: 3,
  sigungu: '',
};

export interface IBill {
  id: number;
  createdate?: string;
  customer: ICustomer;
  state?: number;
  standing?: boolean;
}

export interface IBillService {
  id: number;
  createdate?: string;
  workdate?: string;
  billitem: IBillItem;
  visitdt: string;
  visittm?: string;
  amt?: number;
  state?: number;
  mgremp?: IEmp;
  withmgr?: IEmp;
  price?: number;
  dcprice?: number;
  startmv?: number;
  midmv?: number;
  endmv?: number;
  custscore?: number;
  custcmt?: string;
  addr?: string;
  longaddr?: string;
  haspet?: boolean;
  petmemo?: string;
  item?: number;
  customer?: number;
  afterlunch?: boolean;
  iscollect?: boolean;
  iswalk?: boolean;
  etc?: string;
  attendtm?: string;
  indicate?: string;
  didcheck?: boolean;
  addrcmt?: string;
  stm?: string;
  etm?: string;
  optcnt: number;
  driver?: number;
  drivername?: string;
  drivertel?: string;
}

export interface IBillItem {
  id: number;
  createdate?: string;
  workdate?: string;
  bill: IBill;
  item: IItem;
  reqtm: string;
  state?: number;
  baseopt?: string;
  cleaner?: boolean;
  remark?: string;
  price?: number;
  dcprice?: number;
  cnt?: number;
  amt?: number;
  custaddr: ICustAddr;
}

export interface IBillSvc {
  id: number;
  createdate?: string;
  workdate?: string;
  addr: string;
  longaddr?: string;
  afterlunch?: boolean;
  amt: number;
  attendtm?: string;
  baseopt?: string;
  bids?: string;
  bsids?: string;
  custaddr: number;
  custcmt?: string;
  custid: number;
  level: number;
  custscore?: number;
  dcprice?: number;
  startmv?: number;
  midmv?: number;
  imglink?: string;
  endmv?: number;
  enterpw?: string;
  etc?: string;
  indicate?: string;
  iswalk?: boolean;
  itemids?: string;
  mgremp: number;
  withmgr?: number;
  name: string;
  parking?: string;
  price: number;
  remark?: string;
  serialno?: string;
  doorid?: string;
  state: number;
  visitdt?: string;
  visittm?: string;
  waste?: string;
  haspet?: boolean;
  petmemo?: string;
  billitem: number;
  didcheck?: boolean;
  enterinfo?: string;
  placeid?: number;
  cnt: number;
  colls?: string;
  iscollect?: boolean;
  spendtime?: number;
  addrcmt?: string;
  empname?: string;
  emptel?: string;
  empzone?: number;
  zone?: number;
  withname?: string;
  bicnts?: string;
  buttonController?: number;
  placelink?: string;
  enterlink?: string;
  addrid?: number;
  addrlink?: string;
  stm?: string;
  etm?: string;
  optcnts: number;
  driver?: number;
}

export interface IGasaService {
  id: number;
  createdate?: string;
  workdate?: string;
  gasacust: number;
  visitdt: string;
  visittm: string;
  state: number;
  remark?: string;
  emp?: number;
  cid: number;
  empname: string;
  custname: string;
  mobile: string;
  addr: string;
  petmemo?: string;
  custremark?: string;
  placesize?: string;
  type: 'gasa';
  etm?: string;
}

export const DefaultGasaService: IGasaService = {
  id: 0,
  gasacust: 0,
  visitdt: '',
  visittm: '',
  state: 0,
  cid: 0,
  empname: '',
  custname: '',
  mobile: '',
  addr: '',
  type: 'gasa',
};

export const DefaultBill: IBill = {
  id: 0,
  customer: DefaultCustomer,
  state: 0,
  standing: false,
};

export const DefaultBillItem: IBillItem = {
  id: 0,
  bill: DefaultBill,
  item: DefaultItem,
  reqtm: '',
  state: 0,
  cleaner: false,
  amt: 0,
  custaddr: DefaultCustAddr,
};

export const DefaultBillSvc: IBillSvc = {
  id: 0,
  billitem: 0,
  addr: '',
  amt: 0,
  mgremp: 0,
  name: '',
  custaddr: 0,
  custid: 0,
  price: 0,
  state: 0,
  buttonController: 0,
  cnt: 1,
  optcnts: 0,
  level: 9,
};

export const BillStates = [
  { text: '예약', color: 'blue' },
  { text: '결제완료', color: 'red' },
  { text: '스케줄됨', color: 'rgb(253, 216, 53)' },
  { text: '10분전', color: 'rgb(76, 175, 80)' },
  { text: '서비스중', color: 'rgb(63, 81, 181)' },
  { text: '서비스완료', color: 'rgb(97, 97, 97)' },
];

export interface IMvState {
  id: number;
  smv: number;
  emv: number;
}

// 0:ready/uploaded, 1: taking(takeMv), 2: taken(mvLocal), 3: uploading
export enum MVSTATE {
  READY = 0,
  // TAKING = 1,
  TAKEN = 2,
  UPLOADING = 3,
  UPLOADED = 4,
}

export const DefaultMvState = { id: 0, smv: 0, emv: 0 };
