import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Collapse } from '@material-ui/core';
import { HTInput } from '../HT/HTInput';

interface Props {
  hp: string;
  pw: string;
  pwCheck: string;
  setPwCheck: Dispatch<SetStateAction<string>>;
  setPw: Dispatch<SetStateAction<string>>;
  NumpadState: boolean;
  PwCheckNumpadState: boolean;
  onChangeNumpad: () => void;
  onChangePwCheckNumpad: () => void;
  openPw: boolean;
  onChangeOpenNext: () => void;
}

export const SignupPwInput: React.FC<Props> = ({
  hp,
  pw,
  pwCheck,
  setPwCheck,
  setPw,
  NumpadState,
  PwCheckNumpadState,
  onChangeNumpad,
  onChangePwCheckNumpad,
  openPw,
  onChangeOpenNext,
}) => {
  const [helperText, setHelperText] = useState<JSX.Element | string>('');
  const [CheckHelperText, setCheckHelperText] = useState<JSX.Element | string>(
    ''
  );

  const validCheckingPw = (msg: JSX.Element | string) => {
    setPw('');
    setHelperText(msg);
  };
  const validCheckingPwcheck = (msg: JSX.Element | string) => {
    setPwCheck('');
    setCheckHelperText(msg);
  };
  const checkValidDone = (msg: JSX.Element | string) => {
    setHelperText(msg);
    onChangePwCheckNumpad();
    onChangeNumpad();
  };
  const checkCorrectDone = (msg: JSX.Element | string) => {
    setCheckHelperText(msg);
    onChangePwCheckNumpad();
    onChangeOpenNext();
  };

  const isValidPw = () => {
    let msg: JSX.Element | string;
    if (hp.includes(pw)) {
      msg = '휴대폰 번호와 일치하는 번호는 사용하지 말아주세요.';
      validCheckingPw(msg);
    } else if (
      pw
        .split('')
        .filter(
          (p, i) =>
            (Number(p) - Number(pw[i + 1]) === 1 &&
              Number(pw[i + 1]) - Number(pw[i + 2]) === 1) ||
            (Number(p) - Number(pw[i + 1]) === -1 &&
              Number(pw[i + 1]) - Number(pw[i + 2]) === -1)
        ).length > 0
    ) {
      msg = '연속된 숫자가 3개이상인 번호는 사용하지 말아주세요.';
      validCheckingPw(msg);
    } else if (
      pw.split('').filter((a, b) => a === pw[b + 1] && pw[b + 1] === pw[b + 2])
        .length > 0
    ) {
      msg = '같은 숫자를 연속으로 3개이상 사용하지 말아주세요.';
      validCheckingPw(msg);
    } else {
      msg = <strong style={{ color: '#3949AB' }}>확인되었습니다.</strong>;
      checkValidDone(msg);
    }
  };

  const isValidPwCheck = () => {
    let msg: JSX.Element | string;
    if (pw === pwCheck) {
      msg = <strong style={{ color: '#3949AB' }}>확인되었습니다.</strong>;
      checkCorrectDone(msg);
    } else {
      msg = '비밀번호와 일치하지 않습니다.';
      validCheckingPwcheck(msg);
    }
  };

  useEffect(() => {
    if (pw) {
      isValidPw();
    }
    console.log(hp);
  }, [pw.length === 6]);

  useEffect(() => {
    if (pwCheck) {
      isValidPwCheck();
      console.log(hp);
    }
  }, [pwCheck.length === 6]);

  return (
    <Collapse in={!openPw} timeout={700}>
      <HTInput
        from='pw'
        length={6}
        input={pw}
        setInput={setPw}
        title='비밀번호'
        helperText={helperText}
        NumpadState={NumpadState}
        onChangeNumpad={onChangeNumpad}
      />
      <HTInput
        from='pw'
        length={6}
        input={pwCheck}
        setInput={setPwCheck}
        title='비밀번호 확인'
        helperText={CheckHelperText}
        NumpadState={PwCheckNumpadState}
        onChangeNumpad={onChangePwCheckNumpad}
      />
    </Collapse>
  );
};
