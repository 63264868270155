import React, {
  ChangeEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Button,
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tab,
  Collapse,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { IBoard, IFolder, IFolderTag } from '../../models/folder';
import { useMany } from '../../hooks/api';

interface Props {
  title: string;
  folder: IFolder;
}

const useStyles = makeStyles({
  root: {
    padding: '2% 4% 1% 4%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.4em',
  },
  icon: {
    color: '#EF5350',
    fontSize: '2em',
    margin: '-.5em 0 0 -.5em',
  },
  icon2: {
    color: '#EF5350',
  },
  detail: {
    backgroundColor: 'rgb(69, 90, 100)',
    padding: '16px',
  },
  tabs: {
    margin: '0 0 4% 0',
  },
  contents: {
    margin: '1% 0',
  },
  btn: {
    padding: '1.4em',
    justifyContent: 'space-between',
  },
  name: {
    marginLeft: '0.5em',
    fontSize: '14px',
  },
  badge: {
    marginRight: '1em',
  },
});

export const Aco: React.FC<Props> = ({ title, folder }) => {
  const divRef = useRef<any>();
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: boards } = useMany<IBoard[]>('board', {
    cmd: 'finds-by-folder',
    folder: folder.id,
  });
  // console.log('boards>>>>>>>>>>>>', folder.id, boards);
  const fts = folder.foldertags;
  // console.log('foldertag>>>>>>>>>>>>', fts);

  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [value, setValue] = useState(0);
  const notiReducer = (notiState: boolean) => !notiState;
  const manualReducer = (manualState: boolean) => !manualState;
  const [notiState, onChangeNoti] = useReducer(notiReducer, false);
  const [manualState, onChangeManual] = useReducer(manualReducer, false);

  const onChange =
    (panel: string) => (evt: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onChangeTab = (evt: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Button
          variant='outlined'
          fullWidth
          className={classes.btn}
          onClick={title === '업무매뉴얼' ? onChangeManual : onChangeNoti}
          size='small'
        >
          <div className={classes.name}>{title}</div>
          <div className={classes.badge}>
            {/* <Badge
            // badgeContent={
            //   btn.title === '공지사항'
            //     ? noti.filter((a) => a.check === 1).length
            //     : manual.filter((a) => a.check === 1).length
            // }
            // color={btn.title === '공지사항' ? 'primary' : 'secondary'}
            >
              {title === '업무매뉴얼' ? (
                <NotificationsActiveOutlinedIcon />
              ) : (
                <CheckCircleOutlinedIcon />
              )}
            </Badge> */}
          </div>
        </Button>
        <Collapse
          in={title === '업무매뉴얼' ? manualState : notiState}
          timeout={800}
        >
          <div ref={divRef}>
            {folder.id === 5 && (
              <Tabs
                value={value}
                onChange={onChangeTab}
                indicatorColor='secondary'
                variant='scrollable'
                scrollButtons='auto'
                className={classes.tabs}
              >
                <Tab label='모두' />
                {fts.map((ft: IFolderTag) => (
                  <Tab label={ft.tname} key={ft.id} />
                ))}
              </Tabs>
            )}
            {boards?.map((content) => (
              <div
                key={content.id}
                className={classes.contents}
                hidden={
                  folder.id === 5 &&
                  value + 3 !== content.foldertag &&
                  value !== 0
                }
              >
                <Accordion
                  expanded={expanded === content.title}
                  onChange={onChange(content.title)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {value === 0 && content.foldertag}
                    {content.title}
                  </AccordionSummary>
                  <AccordionDetails className={classes.detail}>
                    {content.contents}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    </>
  );
};
