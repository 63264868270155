import React, { useRef } from 'react';
import {
  Button,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import TabletAndroidIcon from '@material-ui/icons/TabletAndroid';
import ClearIcon from '@material-ui/icons/Clear';
import { HTHeader } from '../HT/HTHeader';

interface Props {}

const useStyles = makeStyles({
  root: {
    padding: '4% 5% 4% 5%',
    // display: 'flex',
    // flexDirection: 'column',
  },
  txtfield: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
    },
    '& .MuiFormHelperText-root': {
      // color: '#78909C',
      fontSize: '1.2em',
    },
  },
  btn: {
    marginTop: '1.5em',
    borderRadius: '50px',
  },
  back: {
    float: 'right',
    marginRight: '1.5em',
  },
});

export const HpCheckForPwChange: React.FC<Props> = () => {
  const classes = useStyles();
  const hpRef = useRef<any>(null);
  const isCheckingHp = () => {
    // console.log(hpRef.current?.value);
  };
  return (
    <>
      <HTHeader from='pwchange' />
      <div className={classes.root}>
        <TextField
          className={classes.txtfield}
          label='핸드폰 번호'
          fullWidth
          variant='outlined'
          autoFocus
          inputRef={hpRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <TabletAndroidIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <ClearIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          onClick={isCheckingHp}
          variant='contained'
          color='secondary'
          fullWidth
          className={classes.btn}
        >
          본인 확인
        </Button>
      </div>
      <Button onClick={() => window.history.back()} className={classes.back}>
        뒤로가기
      </Button>
    </>
  );
};
