import { useEffect, useState } from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import EventIcon from '@material-ui/icons/Event';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import clsx from 'clsx';
import withAuthentication from '../../_withAuthentication';
import { dateFormat, dayTostring } from '../../utils/date-utils';
import { useMany } from '../../hooks/api';
import { IGasaService } from '../../models/bill';
import { useSession } from '../../hooks/session';

const useStyles = makeStyles({
  root: {
    '& .MuiSvgIcon-root': {
      width: '2em',
      height: '2em',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: '.7em',
  },
  icon: {
    fontSize: '.51em',
    margin: '2% 1% 1% 1%',
  },
  calendar: {
    display: 'none',
  },
  container: {
    position: 'relative',
    marginBottom: 20,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(52, 65, 72)',
  },
  emp: {
    width: 42,
    height: 42,
    borderRadius: 21,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(26, 34, 50)',
    '& > p': {
      fontSize: '14px',
    },
  },
  paddingHorizontal: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  paddingVertical: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  status_box: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  status: {
    display: 'inline-block',
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  bg_yellow: {
    backgroundColor: '#f59427',
  },
  bg_green: {
    backgroundColor: 'rgb(133, 192, 37)',
  },
  date: {
    fontSize: '20px',
    fontWeight: 700,
  },
  gap: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
  },
  empty: {
    textAlign: 'center',
  },
  box: {
    position: 'relative',
  },
  blur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(80, 80, 80, 0.4)',
  },
  blur_text: {
    color: 'rgba(150, 150, 150, 0.9)',
  },
});

const TODAY = dateFormat(new Date());

interface Props {}
const MonthSchedule: React.FC<Props> = () => {
  const classes = useStyles();
  const { session } = useSession();
  const [date, setDate] = useState(dateFormat(new Date()));
  const [hasScheduleDates, setHasScheduleDates] = useState<string[]>([]);
  const dateView = date.split('-');
  const dateShowing = `${dateView[0]}년 ${dateView[1]}월`;

  const { data: gasaservices } = useMany<IGasaService[]>('gasaservice', {
    cmd: session.user.role < 3 ? 'gets-by-month-cid' : 'gets-by-month-mgr',
    cid: session.user.cid,
    emp: session.user.id,
    sdt: dateFormat(new Date(date), 3),
  });

  const onChangeMonthBefore = () => {
    const newDate = new Date(date);
    const beforeMonth = new Date(newDate.setMonth(newDate.getMonth() - 1));

    setDate(dateFormat(beforeMonth));
  };

  const onChangeMonthAfter = () => {
    const newDate = new Date(date);
    const afterMonth = new Date(newDate.setMonth(newDate.getMonth() + 1));

    setDate(dateFormat(afterMonth));
  };
  const calendarMonthChange = (D: MaterialUiPickersDate) => {
    if (!D) return;

    setDate(dateFormat(D));
  };

  useEffect(() => {
    const visitdts = gasaservices?.map(({ visitdt }) => visitdt);
    setHasScheduleDates(
      visitdts
        ?.filter(
          (visitdt, idx) => visitdts.findIndex((dt) => dt === visitdt) === idx
        )
        .sort() || []
    );
  }, [gasaservices]);

  return (
    <div className='p10'>
      <h2 className={classes.root}>
        <KeyboardArrowLeftRoundedIcon onClick={onChangeMonthBefore} />
        <EventIcon
          className={classes.icon}
          onClick={() => {
            const dateChange = document.getElementById('dateChange');
            dateChange?.click();
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
          <DatePicker
            format='yyyy년MM월'
            value={date}
            views={['month']}
            onChange={calendarMonthChange}
            className={classes.calendar}
            autoOk
            disableToolbar
            id='dateChange'
          />
        </MuiPickersUtilsProvider>
        <small>{dateShowing}</small>
        <KeyboardArrowRightRoundedIcon onClick={onChangeMonthAfter} />
      </h2>
      <div className={classes.gap}>
        {hasScheduleDates.map((dt) => {
          const isPast = dt < TODAY;
          return (
            <div key={dt} className={classes.box}>
              <p
                className={clsx(classes.date, {
                  [classes.blur_text]: isPast,
                })}
              >
                {`${dt} (${dayTostring(new Date(dt).getDay())})`}
              </p>
              {gasaservices
                ?.filter(({ visitdt }) => visitdt === dt)
                .map(({ id, visittm, empname, addr, state }) => (
                  <Paper
                    key={id}
                    className={classes.container}
                    variant='outlined'
                  >
                    <div
                      className={clsx(
                        classes.info,
                        classes.paddingHorizontal,
                        classes.paddingVertical
                      )}
                    >
                      <div className={classes.status_box}>
                        <span
                          className={clsx(
                            classes.status,
                            state >= 5 && state !== 6
                              ? classes.bg_green
                              : classes.bg_yellow
                          )}
                        />
                        <small>{visittm}</small>
                      </div>
                      <div className={classes.emp}>
                        <p>{empname.slice(0, 3)}</p>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.paddingHorizontal,
                        classes.paddingVertical
                      )}
                    >
                      {addr}
                    </div>
                    <div className={clsx({ [classes.blur]: isPast })} />
                  </Paper>
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default withAuthentication(MonthSchedule);
