import React from 'react';
import { HTHeader } from '../HT/HTHeader';
import { SignupForm } from './SignupForm';

interface Props {}

export const Signup: React.FC<Props> = () => (
  <>
    <HTHeader from='signup' />
    <SignupForm />
  </>
);
