import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  makeStyles,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { useMany } from '../../hooks/api';
import { IApproveEmp } from '../../models/approve';

const useStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: '1em',
    alignItems: 'center',
  },
  active: {
    color: '#7E57C2',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'black',
  },
});

interface activeChecking {
  active: number;
  completed: number;
}

function HTStepIcon({ active, completed }: activeChecking) {
  const classes = useStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

interface Props {
  steps: string[];
  approval?: IApproveEmp;
  step?: number;
}

const useStyles = makeStyles({
  container: {
    paddingBottom: 0,
  },
  btn: {
    float: 'right',
    margin: '-1em -3em 0 0',
  },
  sub: {
    padding: 0,
    marginTop: '0.5em',
  },
  subcomplete: {
    padding: 0,
    marginTop: '0.5em',
    display: 'none',
  },
  typo: {
    fontSize: '12px',
    margin: '1em 0',
  },
  stplabel: {
    marginTop: '-.9em',
    fontSize: '.8em',
    fontWeight: 700,
  },
});

export const StepConfirm: React.FC<Props> = ({ steps, approval, step = 0 }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(step);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div
      className={clsx(classes.container, {
        [classes.subcomplete]: activeStep === 4,
      })}
    >
      {approval && (
        <div className={classes.typo}>
          {approval?.title}({approval?.sdt} ~ {approval?.edt})
        </div>
      )}
      <Stepper activeStep={activeStep} alternativeLabel className={classes.sub}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={HTStepIcon}>
              <div className={classes.stplabel}>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Button color='primary' onClick={handleNext} className={classes.btn}>
        -
      </Button>
    </div>
  );
};
