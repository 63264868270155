import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

interface Props {
  from: string;
}

const useStyles = makeStyles({
  container: {
    backgroundColor: '#233044',
    padding: '4%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5%',
  },
  fromLogin: {
    marginBottom: '10%',
  },
  login: { maxWidth: '85%', height: 'auto', margin: '12vh 0 3vh 0' },
  NotLogin: { maxWidth: '85%', height: 'auto', margin: '3vh 0 3vh 0' },
});

export const HTHeader: React.FC<Props> = ({ from }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.container, {
        [classes.fromLogin]: from === 'login',
      })}
    >
      <img
        src='/static/img/mgr_white_v4.svg'
        alt='홈텔리어 매니저'
        className={clsx({
          [classes.login]: from === 'login',
          [classes.NotLogin]: from !== 'login',
        })}
      />
    </div>
  );
};
