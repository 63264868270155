import { Box, Button, Divider, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../hooks/session';
import { callNative } from '../../utils/native-connector';
import { HTAvatar } from '../HT/HTAvatar';
import { DropDown } from './DropDown';

interface Props {}

const useStyles = makeStyles(() => ({
  item: {
    width: '28px',
    height: '28px',
    fontSize: '.673em',
  },
  divi: {
    margin: '2% 5%',
  },
}));

const XBox: React.FC<Props> = ({ children }) => <Box>{children}</Box>;

export const UserDropDown: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const { session, logout } = useSession();
  const { session, logout } = useSession();
  const [isOpen, setOpen] = useState<HTMLElement | null>(null);

  const goingMy = () => {
    navigate('/htmy');
    setOpen(null);
  };

  const userLogout = () => {
    logout();
    // console.log('1111111111111111');
    navigate('/login');
    setOpen(null);
  };

  const getoff = () => {
    callNative({ cmd: 'flushAll' });
    userLogout();
  };

  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setOpen(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(':::>>', event);
    setOpen(null);
  };

  const open = Boolean(isOpen);

  const isHometel = session.user.id === 3;

  // const { data: loginUser, refetch: refetchLoginUser } = useMany<IEmp>('emp', {
  //   cmd: 'read',
  //   id: session.user.id,
  // });
  // // console.log('login확인>>>>>>>', loginUser);

  // const { data: userImg, refetch: refetchUserImg } = useMany<IUpfile>(
  //   'upfile',
  //   {
  //     cmd: 'read',
  //     id: loginUser?.profileimg,
  //   }
  // );

  return (
    <DropDown
      labelIcon={<HTAvatar from='navibar' />}
      contents={
        <Box p={0.5} display='flex' flexDirection='column'>
          <Button onClick={goingMy}>내 정보</Button>
          <Divider className={classes.divi} />
          <Button onClick={userLogout}>로그아웃</Button>
          {isHometel && <Divider className={classes.divi} />}
          {isHometel && <Button onClick={getoff}>ClearAll</Button>}
          {isHometel && (
            <Button onClick={() => callNative({ cmd: 'clearSound' })}>
              ClearSound
            </Button>
          )}
        </Box>
      }
      isOpen={isOpen}
      open={open}
      handleChange={handleChange}
      handleClose={handleClose}
    />
  );
};
