import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import { useSession } from '../../hooks/session';
import { HpInput } from '../HT/HpInput';
import { Modal } from '../HT/Modal';
import { PwInput } from './PwInput';
import { IEmpWithComp } from '../../models/emp';
import { TAreaCode } from '../../models/etc';

interface Props {
  empComps: IEmpWithComp[] | [];
  setEmpComps: Dispatch<SetStateAction<IEmpWithComp[] | []>>;
}

const useStyles = makeStyles({
  text: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: 2,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    // gridTemplateColumns: '1fr 1fr',
    gap: 20,
    margin: 10,
  },
  button: {
    height: 45,
    fontSize: 16,
    letterSpacing: 2,
    fontWeight: 'bold',
    backgroundColor: 'rgb(47, 62, 84)',
    color: '#fff',
    border: '1px solid rgb(190,190,190)',
  },
});

// const testLoginUser = { loginid: '01088351112', passwd: '123456' }; // donghyuck
const testLoginUser = { loginid: '01011112222', passwd: '123456' }; // hometel
const testLoginDriver = { loginid: '01050667366', passwd: '123456' }; // driver

export const LoginForm: React.FC<Props> = ({ empComps, setEmpComps }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { session, login, getAccounts } = useSession();
  const reducer = (openError: boolean) => !openError;
  const hpReducer = (HpNumpadState: boolean) => !HpNumpadState;
  const pwReducer = (PwNumpadState: boolean) => !PwNumpadState;
  const [firstHp, setFirstHp] = useState<TAreaCode>('010');
  const [hp, setHp] = useState<string>('');
  const [pw, setPw] = useState<string>('');
  const [HpNumpadState, onChangeHpNumpad] = useReducer(hpReducer, false);
  const [PwNumpadState, onChangePwNumpad] = useReducer(pwReducer, false);
  const [openError, isOpenError] = useReducer(reducer, false);

  // ------------------------------------------------------------ local
  const isDev = process.env.REACT_APP_SERVER_URL?.includes('local');
  // console.log('DriverHome.isDev>>>', isDev);
  const [first, setFirst] = useState(0);
  const [second, setSecond] = useState(0);
  const multiSet = () => {
    setFirst((f) => f + 1);
    setSecond((s) => s + 1);
    setTimeout(() => {
      setFirst((f) => f + 1);
      setSecond((s) => s + 1);
    }, 10);
  };

  const [count, setCount] = useState<number>(0);
  const cntRef = React.useRef<HTMLSpanElement>(null);
  const plusCount2 = () => {
    const gt = new Date().getTime() % 1000;
    console.log('111>>', gt, count);
    // setCount((c) => {
    //   const d = c + 1;
    //   console.log(
    //     'current count is',
    //     new Date().getTime() % 1000,
    //     count,
    //     c,
    //     d,
    //     cntRef.current?.innerText
    //   );

    //   return d;
    // });
    flushSync(() => setCount((c) => c + 1));
    console.log('222>>', new Date().getTime() % 1000, count);
  };

  const plusCount = () => {
    if (!cntRef.current) return;
    const prev = Number(cntRef.current.innerText);
    const intl = setInterval(() => {
      const curr = Number(cntRef.current?.innerText);
      console.log(
        'rrr>>',
        new Date().getTime() % 1000,
        curr,
        document.getElementById('cnt')?.innerText
      );
      if (curr > prev) clearInterval(intl);
    }, 1);

    setTimeout(() => plusCount2(), 10);
  };

  // ------------------------------------------------------------ local

  const submit = (cid: number, cname: string) => {
    login(
      { loginid: `${firstHp}${hp}`, passwd: `${pw}`, cid, cname },
      (err) => {
        if (err.response?.status === 404) {
          isOpenError();
        }
      }
    );
  };

  const submitAccount = () => {
    getAccounts({ loginid: `${firstHp}${hp}`, passwd: `${pw}` }).then(
      (empcomps) => {
        if (empcomps.length === 0) {
          isOpenError();
        } else if (empcomps.length === 1) {
          submit(empcomps[0].cid, empcomps[0].cname);
        }
        setEmpComps(empcomps);
      }
    );
  };

  const submitTester = (loginid = `11112222`, passwd = '121212') => {
    // console.log('🚀  loginid:', loginid, passwd);
    // login({ loginid, passwd }, (err) => {
    //   if (err.response?.status === 404) {
    //     isOpenError();
    //   }
    // });
    setHp(loginid);
    setPw(passwd);
    submitAccount();
  };

  useEffect(() => {
    // if (session.user.auth === 9) navigate('/waiting');
    // else if (session.didLogin) navigate('/home');
    if (session.didLogin) navigate('/home');
  }, [session.didLogin]);

  const Ttt = ({ f }: { f: number }) => <span>{f}</span>;

  return (
    <>
      {isDev && (
        <>
          <button onClick={multiSet}>
            {first}MultiSet{second}
            <Ttt f={first} />
          </button>
          <button onClick={plusCount}>Count</button>
          <span ref={cntRef} id='cnt'>
            {count}
          </span>
          <button onClick={() => submitTester()}>HTEL</button>
          <button onClick={() => submitTester('11110000')}>직원</button>
          <button onClick={() => submitTester('22223333')}>Driver</button>
          <button onClick={() => submitTester('88916693')}>분점</button>
          <button
            onClick={() => {
              setHp('55554444');
              setPw('12121');
              onChangeHpNumpad();
            }}
          >
            시연용
          </button>
        </>
      )}
      {!empComps.length && (
        <>
          <HpInput
            using='login'
            firstHp={firstHp}
            setFirstHp={setFirstHp}
            hp={hp}
            setHp={setHp}
            NumpadState={HpNumpadState}
            onChangeNumpad={onChangeHpNumpad}
            onChangePwNumpad={onChangePwNumpad}
          />
          <PwInput
            hp={hp}
            pw={pw}
            setPw={setPw}
            NumpadState={PwNumpadState}
            onChangeNumpad={onChangePwNumpad}
            submit={submitAccount}
          />
          <Modal
            modalOpen={openError}
            title='ddd'
            msg='잘못 입력하였습니다. 다시 로그인해주세요.'
            isCancel={isOpenError}
            isCheck={isOpenError}
            type='alert'
          />
        </>
      )}
      {empComps.length > 1 && (
        <div>
          <p className={classes.text}>소속을 선택해주세요.</p>
          <div className={classes.buttons}>
            {empComps.map((empcomp) => (
              <Button
                key={empcomp.cid}
                className={classes.button}
                variant='contained'
                size='large'
                onClick={() => {
                  console.log('click account ! ', empcomp.cid, empcomp.cname);
                  submit(empcomp.cid, empcomp.cname);
                }}
              >
                {empcomp.cname}
              </Button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
