import React, { useRef, useState } from 'react';
import { Button, Divider, makeStyles } from '@material-ui/core';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Store } from '../../hooks/store';
import { upfileLink } from '../../models/etc';
import { Modal } from '../HT/Modal';

const useStyles = makeStyles({
  divi: {
    margin: '2%',
  },
  buildingImg: {
    padding: '0 1.2rem',
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    '& .title span': {
      marginLeft: '0.2rem',
    },
  },
  imglink: {
    marginTop: '.5rem',
    maxWidth: '100%',
  },
  imgbtns: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
});

interface Props {
  type: 'midmv' | 'addrimg' | 'placeimg' | 'enterimg';
  bsId: number;
  imglink: string | undefined;
  updater: (
    type: 'midmv' | 'addrimg' | 'placeimg' | 'enterimg',
    id: number | undefined
  ) => void;
  children: React.ReactNode;
}

const ImageField: React.FC<Props> = ({
  type,
  bsId,
  imglink = '',
  updater,
  children,
}) => {
  const classes = useStyles();
  const [isOpenDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);
  const imgRef = useRef<HTMLInputElement>(null);
  const upfileRef = useRef<number>(0);

  const takePhoto = (capture: string) => {
    const img = document.getElementById(`${type}-${bsId}`);
    if (img) {
      if (capture === 'capture') img.setAttribute('capture', capture);
      else img.removeAttribute('capture');
      img?.click();
    }
  };

  const uploadImage = () => {
    if (!imgRef.current || !imgRef.current.files?.length) return;

    const file = imgRef.current.files[0];
    console.log('file >> ', file);

    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (upfileRef.current) Store.delete('upfile', upfileRef.current);

    Store.sendPost('upfiles?x=1', formData, config).then((res) => {
      console.log('res >> ', res, upfileLink(res.upfile));
      if (imgRef.current?.value) imgRef.current.value = '';
      upfileRef.current = res.upfile.id;
      updater(type, res.upfile.id);
    });
  };

  const deleteImage = () => {
    if (upfileRef.current) {
      Store.delete('upfile', upfileRef.current);
      upfileRef.current = 0;
    }
    setOpenDeleteConfirm(false);
    updater(type, undefined);
  };

  return (
    <div>
      <Divider className={classes.divi} />

      <div className={classes.buildingImg}>
        <div className='title'>{children}</div>

        <div>
          <input
            type='file'
            accept='image/*'
            multiple={false}
            ref={imgRef}
            id={`${type}-${bsId}`}
            hidden
            onChange={() => uploadImage()}
          />
        </div>

        {imglink && (
          <img src={imglink} alt='사진' className={classes.imglink} />
        )}

        <div className={classes.imgbtns}>
          <Button onClick={() => takePhoto('capture')} variant='outlined'>
            <CameraEnhanceIcon /> 촬영
          </Button>
          <Button onClick={() => takePhoto('file')} variant='outlined'>
            <AttachmentIcon /> 파일
          </Button>

          {imglink && (
            <Button
              onClick={() => setOpenDeleteConfirm(true)}
              variant='outlined'
              color='secondary'
            >
              <DeleteForeverIcon />
            </Button>
          )}
        </div>
      </div>

      <Modal
        modalOpen={isOpenDeleteConfirm}
        title='사진 삭제'
        msg='사진을 삭제하시겠습니까?'
        isCancel={() => setOpenDeleteConfirm(!isOpenDeleteConfirm)}
        isSubmit={deleteImage}
        labelOK='삭제!'
      />
    </div>
  );
};
export default ImageField;
