import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { ReactComponent as Logo } from './logo.svg';

const useStyles = makeStyles({
  container: {
    '& .MuiDialog-container': {
      backgroundColor: 'rgba(69, 90, 100, 0.4)',
    },
    '& .MuiDialog-paper': {
      border: '1px solid #7578d3',
    },
  },
  title: {
    padding: '4%',
  },
  topcontent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 .5em ',
  },
  titleContent: {
    width: '10%',
    backgroundColor: 'white',
    borderRadius: '50%',
    marginLeft: '4%',
    marginRight: '3%',
  },
  msgContent: {
    margin: '0 5%',
    padding: 0,
  },
  actionStyle: {
    margin: '4%',
  },
  logo: {
    width: '24px',
    height: '27px',
  },
});

interface Props {
  modalOpen?: boolean;
  title?: string;
  msg?: string | JSX.Element | JSX.Element[];
  type?: string;
  clickMsg?: string;
  isCheck?: () => void;
  isCancel?: () => void;
  isSubmit?: () => void;
  labelOK?: string;
  labelCancel?: string;
  hideButtons?: boolean;
}

export const Modal: React.FC<Props> = ({
  modalOpen,
  title,
  msg,
  type,
  clickMsg,
  isCheck,
  isCancel,
  isSubmit,
  labelOK = '확인',
  labelCancel = '취소',
  hideButtons = false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const isOpen = () => setOpen(true);

  return (
    <>
      {modalOpen === undefined && (
        <Button onClick={isOpen} variant='outlined'>
          {clickMsg}
        </Button>
      )}
      <Dialog
        // open={open}
        open={modalOpen !== undefined ? modalOpen : open}
        fullWidth
        className={classes.container}
        // TransitionComponent={Slide}
        TransitionComponent={Grow}
        // TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <DialogTitle className={classes.title}>
          <div className={classes.topcontent}>
            <div className='flex'>
              {/* <img
                src='/static/img/hometelier_logo.svg'
                alt='htlogo'
                className={classes.titleContent}
              /> */}
              <Logo className={classes.logo} />
              <div className='ml05'>{title}</div>
            </div>
            <IconButton onClick={modalOpen !== undefined ? isCancel : onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.msgContent}>{msg}</DialogContent>
        {hideButtons ? (
          <span>&nbsp;</span>
        ) : (
          <DialogActions className={classes.actionStyle}>
            {type === 'alert' ? (
              <Button
                onClick={isCheck || isSubmit}
                color='primary'
                variant='contained'
              >
                {labelOK}
              </Button>
            ) : (
              <>
                <Button
                  onClick={modalOpen !== undefined ? isCancel : onClose}
                  variant='contained'
                  fullWidth
                >
                  {labelCancel}
                </Button>
                <Button
                  onClick={isSubmit}
                  color='primary'
                  variant='contained'
                  fullWidth
                >
                  {labelOK}
                </Button>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
