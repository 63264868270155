import { IUpfile } from './etc';

export interface IEmp {
  id: number;
  name: string;
  mobile: string;
  cid: number;
  email?: string;
  paswd?: string;
  auth: number;
  role: number;
  profileimg?: string;
  zone: number;
  remark?: string;
  rankcode: number;
  offday?: number;
  fcmtoken?: string;
  weeks: string;
  cmd?: string;
}
export interface IEmpWithComp extends IEmp {
  cname: string;
}

export interface IEmpModel {
  emp: IEmp;
}

export interface IEmps {
  emp: IEmp[];
}

export interface IEmpCommute {
  id: number;
  emp: number;
  ymd: string;
  stm: string;
  etm?: string;
  memo?: string;
}

export interface IComp {
  id: number;
  name: string;
  kind: number;
  tel?: string;
  email?: string;
  sigungu: string;
}

export interface IZone {
  id: number;
  name: string;
  ison?: boolean;
  region: number;
  chief: number;
  isheadoffice?: boolean;
  mobile?: string;
  isdel?: boolean;
  remark?: string;
}

export const DefaultZone: IZone = {
  id: 0,
  name: '',
  ison: false,
  region: 0,
  chief: 0,
  isheadoffice: false,
  isdel: false,
};

export interface ILoginUser {
  user: {
    id: number;
    name: string;
    auth: number;
    ts: number;
    role: number;
    mobile: string;
    cid: number;
    cname: string;
    zone: number;
  };
  didLogin?: boolean;
  attemptedPage?: string;
}

export const DefaultEmp: IEmp = {
  id: 0,
  name: '',
  mobile: '',
  cid: 0,
  auth: 9,
  role: 9,
  zone: 1,
  rankcode: 1,
  offday: 1,
  weeks: '',
};

export const DefaultComp: IComp = {
  id: 0,
  name: '',
  kind: 0,
  tel: '',
  email: '',
  sigungu: '',
};

export const DefaultEmpCommute: IEmpCommute = {
  id: 0,
  emp: 0,
  ymd: '',
  stm: '',
};

export const DefaultLoginUser = {
  user: {
    id: 0,
    name: '',
    auth: 9,
    ts: 0,
    role: 0,
    mobile: '',
    cid: 0,
    cname: '',
    zone: 1,
  },
  didLogin: false,
  attemptedPage: '',
};

export interface ICustChat {
  id: number;
  createdate: string;
  workdate: string;
  customer: number;
  empsaid: number | null;
  sysmsg: boolean;
  upfile?: IUpfile;
  msg: string;
  isdel: boolean;
  mtime: string;
  mdate: string;
  cread?: number;
  eread?: number;
  isShowHT?: boolean;
  isShowDate?: boolean;
  isShowTime?: boolean;
  msgtitle?: string;
}

export const DefaultCustChat: ICustChat = {
  id: 0,
  createdate: '',
  workdate: '',
  customer: 0,
  empsaid: null,
  sysmsg: false,
  msg: '',
  isdel: false,
  mtime: '',
  mdate: '',
};

export interface ICustChatRead {
  id: number;
  name: string;
  maxid: number;
  cread: number;
  eread: number;
  lastmsg: string;
}

export const DefaultCustChatRead: ICustChatRead = {
  id: 0,
  name: '',
  maxid: 0,
  cread: 0,
  eread: 0,
  lastmsg: '',
};

interface IUserRole {
  [q: number]: { name: string };
}

export const UserRole: IUserRole = {
  1: { name: '직원' },
  2: { name: '지점장' },
  3: { name: '매니저' },
  4: { name: '드라이버' },
};

export const alimMsgTitle = (state: number) => {
  switch (state) {
    case 3:
      return '[HOMETELIER] 서비스 10분 전 알림';

    case 4:
      return '[HOMETELIER] 서비스 시작 알림';

    case 5:
      return '[HOMETELIER] 서비스 완료 알림';

    case 6:
      return '[HOMETELIER] 정기구독 결제 알림';

    case 7:
      return '[HOMETELIER] 정기구독 결제 실패 알림';

    default:
      return '';
  }
};

export const alimMsg = (
  state: number,
  message?: string,
  custaddr?: number,
  isGasa = false
) => {
  switch (state) {
    case 3:
      return `안녕하세요 고객님.

고객님의 집을 호텔처럼 관리해 드리는 서비스매니저입니다.
 
약 10분 뒤 서비스 진행을 위해 방문 드릴 예정입니다.

※ 서비스 진행 시 참고해야 할 사항이 있다면 채팅으로 말씀해주세요.`;

    case 4:
      return `서비스가 시작되었습니다.

고객님의 소중한 공간을 호텔처럼 관리해 드리기 위해 최선을 다하고 있습니다.`;

    case 5:
      return `서비스가 완료되었습니다.

※ 서비스 중 불편사항이 있으셨다면 채팅으로 문의 부탁드립니다.
${
  !isGasa
    ? `
    
※ 출입 영상은 업무종료(6시) 이후 이용내역에서 확인 가능합니다.
  `
    : ''
}`;

    case 6:
      return `다음 정기구독 결제가 완료되었습니다.
      
※ 일정 확인 및 변경은 이용내역에서 가능합니다.

※ 정기구독 취소는 결제내역 또는 정기구독 페이지에서 가능합니다.`;

    case 7:
      return `아래 사유로 다음 정기구독 결제에 실패하였습니다.
  
[${message}]

※ 정기구독 서비스를 계속 이용하시려면 정기구독 페이지에서 재결제를 진행해 주세요.
          
※ 정기구독 취소는 결제내역 또는 정기구독 페이지에서 가능합니다.

※ 기타 문의사항은 고객센터(App 채팅)을 이용해주세요.${custaddr}`;

    default:
      return '';
  }
};
