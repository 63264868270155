import { FC } from 'react';
import { AxiosError } from 'axios';
import { ICareCust } from '../../models/customer';
import { useSession } from '../../hooks/session';
import { useSaver } from '../../hooks/api';
import { ServiceCard } from './ServiceCard';

interface ICareServiceProps {
  service: ICareCust;
  refetchCareservice: () => void;
}

export const CareService: FC<ICareServiceProps> = ({
  service,
  refetchCareservice,
}) => {
  const {
    visittm,
    name: custname,
    empname,
    remark,
    mobile,
    addr,
    id: customer,
    state,
  } = service;
  const { socket, session } = useSession();

  const statusSaver = useSaver<ICareCust>(
    'carecust',
    (newCareCust) => {
      refetchCareservice();

      socket?.emit('sync', {
        ...newCareCust,
        customer,
        scope: 'customer',
        model: 'carecust',
      });
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const changeState = (newState: number) => {
    statusSaver.mutate({ ...service, state: newState });
  };

  return (
    <>
      <ServiceCard
        visittm={visittm || ''}
        empname={empname || ''}
        custname={custname}
        placesizeOrRemark={remark || ''}
        mobile={mobile}
        addr={addr}
        state={state}
        type='care'
        expandable={false}
        changeState={changeState}
      />
    </>
  );
};
