import { Collapse, makeStyles } from '@material-ui/core/';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
// import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import NearMeIcon from '@material-ui/icons/NearMe';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSaver } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import { IBillSvc, IMvState, MVSTATE } from '../../models/bill';
import { serviceState } from '../../models/item';
import { dateFormat } from '../../utils/date-utils';
import { ChatRoom } from '../Chat/ChatRoom';
import { BuildingSetting } from './BuildingSetting';
import { Invoice } from './Invoice';
import { Todo } from './Todo';

interface Props {
  billservice: IBillSvc;
  refetchBillSvc: () => void;
  mvStates: IMvState[];
  // setMvStates: Dispatch<SetStateAction<IMvState[]>>;
  setMyMvState: (bsid: number | string, mv: string, state: number) => void;
}
interface hTab {
  id: number;
  component?: JSX.Element | string;
  title: JSX.Element;
  open?: boolean;
  handle?: () => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #37474F',
  },
  chosen: {
    // color: '#3F51B5',
    color: '#c0b8f2',
  },
  title: {
    fontSize: '.56em',
    fontWeight: 700,
  },
  btn: {
    // paddingBottom: '7%',
    padding: '2% 4% 1.5% 4%',
    textAlign: 'center',
  },
  map: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '.56em',
    color: '#fff',
    fontWeight: 700,
    gap: 5,
  },
});

export const HomeTab: React.FC<Props> = ({
  billservice,
  refetchBillSvc,
  mvStates,
  setMyMvState,
}) => {
  const classes = useStyles();
  const { session, socket } = useSession();
  // const [billsvc, setBillsvc] = useState<IBillSvc>(DefaultBillSvc);
  const [comment, setComment] = useState(false);
  const [aboutBuilding, setAboutBuilding] = useState(false);
  const [miniChat, setMiniChat] = useState(false);
  const [workStep, setWorkStep] = useState(false);
  const [hasTaken, setHasTaken] = useState(false);

  const updateBillSvc = useSaver<IBillSvc>(
    'billsvc',
    (newBillSvc) => {
      // setBillsvc(newBillSvc);
      refetchBillSvc();

      socket?.emit('sync', {
        ...newBillSvc,
        customer: newBillSvc.custid,
        scope: 'customer',
        model: 'billsvc',
      });
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const updateBillservice = (
    type: 'midmv' | 'addrimg' | 'placeimg' | 'enterimg',
    upfileId: number | undefined
  ) => {
    updateBillSvc.mutate({ ...billservice, [type]: upfileId });
  };

  const isOpenComment = () => {
    setComment(!comment);
    setAboutBuilding(false);
    setMiniChat(false);
    setWorkStep(false);
  };

  const isOpenAboutBuilding = () => {
    setAboutBuilding(!aboutBuilding);
    setComment(false);
    setMiniChat(false);
    setWorkStep(false);
  };

  const isOpenMiniChat = () => {
    setMiniChat(!miniChat);
    setComment(false);
    setAboutBuilding(false);
    setWorkStep(false);
  };

  const isOpenWorkStep = () => {
    if (
      billservice.visitdt === dateFormat(new Date()) ||
      (session.user.role !== 3 && session.user.role !== 4)
    ) {
      setWorkStep(!workStep);
      setComment(false);
      setAboutBuilding(false);
      setMiniChat(false);
    }
  };

  useEffect(() => {
    // if (billservice) {
    //   setBillsvc(billservice);
    // }

    const myState = mvStates?.find((ms) => ms.id === billservice.id);
    if (myState)
      setHasTaken(
        myState.smv === MVSTATE.TAKEN || myState.emv === MVSTATE.TAKEN
      );
  }, [mvStates]);
  // }, [billservice, mvStates]);

  const tabs: hTab[] = [
    {
      id: 1,
      component: (
        <Invoice
          billservice={billservice}
          updateBillservice={updateBillservice}
          refetchBillSvc={refetchBillSvc}
        />
      ),
      title: (
        <div>
          <AssignmentOutlinedIcon />
          <div className={classes.title}>요청사항</div>
        </div>
      ),
      open: comment,
      handle: isOpenComment,
    },
    {
      id: 2,
      component: (
        <Todo
          billsvc={billservice}
          refetchBillSvc={refetchBillSvc}
          mvStates={mvStates}
          setMyMvState={setMyMvState}
        />
      ),
      title: (
        <div>
          {hasTaken ? (
            <SubscriptionsIcon color='secondary' />
          ) : (
            <PlayCircleFilledWhiteOutlinedIcon
              color={
                dateFormat(new Date()) === billservice.visitdt ||
                (session.user.role !== 3 && session.user.role !== 4)
                  ? 'primary'
                  : 'disabled'
              }
            />
          )}
          {/* <div className={classes.title}>서비스({billservice?.state})</div> */}
          <div className={classes.title}>
            {serviceState(billservice?.state)?.text}
          </div>
        </div>
      ),
      open: workStep,
      handle: isOpenWorkStep,
    },
    // {
    //   id: 3,
    //   component: <ChatRoom from='home' />,
    //   title: (
    //     <div>
    //       <ChatBubbleOutlineOutlinedIcon />
    //       <div className={classes.title}>미니채팅</div>
    //     </div>
    //   ),
    //   open: miniChat,
    //   handle: isOpenMiniChat,
    // },
    {
      id: 3,
      component: (
        <BuildingSetting
          billservice={billservice}
          refetchBillSvc={refetchBillSvc}
        />
      ),
      title: (
        <div>
          <ApartmentOutlinedIcon />
          <div className={classes.title}>빌딩정보</div>
        </div>
      ),
      open: aboutBuilding,
      handle: isOpenAboutBuilding,
    },
    {
      id: 4,
      component: <></>,
      title: (
        <a
          className={classes.map}
          href={`https://map.kakao.com/link/search/${billservice.longaddr}`}
        >
          <NearMeIcon />
          길찾기
        </a>
      ),
    },
  ];

  return (
    <>
      <div className={classes.container}>
        {tabs.map((tab) => (
          <div
            aria-hidden='true'
            onClick={tab.handle}
            className={clsx(classes.btn, { [classes.chosen]: tab.open })}
            key={tab.id}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {tabs.map(
        (tab) =>
          tab.open && (
            <div key={tab.id}>
              <Collapse in={tab.open}>
                <div>{tab.component}</div>
              </Collapse>
            </div>
          )
      )}
    </>
  );
};
