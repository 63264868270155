import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, makeStyles, MenuItem, TextField } from '@material-ui/core';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';

import { IComp } from '../../models/emp';
import { useMany } from '../../hooks/api';

interface coms {
  com: string;
  br: string;
}

interface Props {
  belongRef: any;
  // branchRef: any;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '4% 10% 2% 10%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 3%',
    alignItems: 'flex-start',
  },
  label: {
    fontSize: '15px',
    fontWeight: 600,
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '.5em',
    height: '20px',
    weight: '20px',
  },
  selectBelong: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      padding: '15.5px 14px',
    },
  },
}));

export const SignupBelong: React.FC<Props> = ({
  // openOthers,
  belongRef,
  // branchRef,
}) => {
  const classes = useStyles();

  const { data: comps } = useMany<IComp[]>('comp', {
    cmd: 'gets-public',
  });

  const [belong, setBelong] = useState('-1');
  // const [hbranch, setHbranch] = useState('');

  return (
    <Collapse in timeout={700}>
      <div className={classes.root}>
        <div className={classes.label}>
          <BusinessOutlinedIcon className={classes.icon} />
          소속 업체
        </div>
        <div className={classes.container}>
          <TextField
            className={classes.selectBelong}
            fullWidth
            variant='outlined'
            select
            value={belong}
            onChange={(
              evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setBelong(evt.target.value)}
            InputProps={{
              inputRef: belongRef,
            }}
          >
            <MenuItem value={-1}>-- 소속 업체를 선택해주세요. --</MenuItem>
            {comps &&
              comps.map((comp) => (
                <MenuItem key={comp.id} value={comp.id}>
                  {comp.name}
                </MenuItem>
              ))}
          </TextField>
          {/* {belong !== '' && (
            <TextField
              className={classes.selectBelong}
              variant='outlined'
              fullWidth
              select
              // value={belongs.br}
              value={hbranch}
              onChange={(
                evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setHbranch(evt.target.value)}
              InputProps={{
                inputRef: branchRef,
              }}
            >
              <MenuItem value=''>----</MenuItem>
              {htbranch
                .filter((branch) => branch.category === belong)
                .map((branch) => (
                  <MenuItem key={branch.value} value={branch.value}>
                    {branch.value}
                  </MenuItem>
                ))}
            </TextField>
          )} */}
        </div>
      </div>
    </Collapse>
  );
};
