import React from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: '1% 7% 0 7%',
      marginTop: '5%',
    },
  })
);

interface Props {}

export const LoginFooter: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div>
        <Button onClick={() => navigate('/findingpw')}>PW 찾기</Button>
      </div>
      <div>
        <Button onClick={() => navigate('/signup')}>회원가입</Button>
      </div>
    </div>
  );
};
