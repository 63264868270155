import { FC } from 'react';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { makeStyles, Divider } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import HistoryIcon from '@material-ui/icons/History';
import PetsIcon from '@material-ui/icons/Pets';
import { IGasaService } from '../../models/bill';
import { useSaver } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import { ServiceCard } from './ServiceCard';
import { timeStr } from '../../utils/date-utils';

const useStyles = makeStyles({
  mt10: {
    marginTop: 10,
  },
  invoice: {
    width: '100%',
  },
  info: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
    marginBottom: 10,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  remark: {
    letterSpacing: 1.2,
    fontSize: 14,
    lineHeight: 1.5,
  },
  icon: {
    width: '18px',
    height: '18px',
    marginRight: '2%',
  },
  strong: {
    fontWeight: 700,
    color: 'lime',
    whiteSpace: 'pre-wrap',
  },
});

interface IGasaServiceProps {
  service: IGasaService;
  refetchGasaservice: () => void;
}

export const GasaService: FC<IGasaServiceProps> = ({
  service,
  refetchGasaservice,
}) => {
  const {
    visittm,
    empname,
    custname,
    placesize,
    mobile,
    addr,
    gasacust,
    state,
    remark,
    custremark,
    petmemo,
    etm,
  } = service;
  const classes = useStyles();
  const { socket } = useSession();

  const statusSaver = useSaver<IGasaService>(
    'gasaservice',
    (newGasaService) => {
      refetchGasaservice();

      socket?.emit('sync', {
        ...newGasaService,
        customer: gasacust,
        scope: 'customer',
        model: 'gasaservice',
      });
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const changeState = (newState: number) => {
    statusSaver.mutate({
      ...service,
      state: newState,
    });
  };

  return (
    <>
      <ServiceCard
        visittm={visittm}
        empname={empname}
        custname={custname}
        placesizeOrRemark={placesize || ''}
        mobile={mobile}
        addr={addr}
        state={state}
        type='gasa'
        expandable
        changeState={changeState}
        etm={etm}
      >
        <div className={classes.invoice}>
          <div className={clsx(classes.info, classes.mt10)}>
            <div className={classes.title}>
              <PetsIcon className={classes.icon} />
              반려 동물
            </div>
            <div className={classes.remark}>
              {!!petmemo && <div className={classes.strong}>{petmemo}</div>}
            </div>
          </div>
          <Divider />
          <div className={clsx(classes.info, classes.mt10)}>
            <div className={classes.title}>
              <TodayIcon className={classes.icon} />
              고객 정보
            </div>
            <div className={classes.remark}>
              {!!custremark && (
                <div className={classes.strong}>{custremark}</div>
              )}
            </div>
          </div>
          <Divider />
          <div className={clsx(classes.info, classes.mt10)}>
            <div className={classes.title}>
              <HistoryIcon className={classes.icon} />
              오늘의 지시사항
            </div>
            <div className={classes.remark}>
              {!!remark && <div className={classes.strong}>{remark}</div>}
            </div>
          </div>
        </div>
      </ServiceCard>
    </>
  );
};
