import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { iX } from '../utils/utils';

const xapi = axios.create({
  baseURL: `/api/hometel/htelmgr/0/`,
  withCredentials: true,
});

interface idX {
  [index: string]: any;
  id?: number;
}

interface ER extends Error {}

export const Store = {
  setBaseURL(cid: number) {
    xapi.defaults.baseURL = `/api/hometel/htelmgr/${cid}`;
  },

  findAll<T extends iX>(model: string) {
    // console.log('******* findAll!!!', model);
    return () => this.fetchAll<T>(model);
  },

  async fetchAll<T extends iX>(model: string) {
    // console.log('fetchAll>>>', model);
    const res = await this.get<T>(`${model}s`);
    return res.data?.[model];
  },

  find<T extends iX>(model: string, idcmd: number | string) {
    // console.log('******* find!!!', model, idcmd);
    return () => this.fetch<T>(model, idcmd);
  },

  async fetch<T extends iX>(model: string, idcmd: number | string) {
    // console.log('fffffffff>>>', model);
    const res = await this.get<T>(`${model}s/${idcmd}`);
    return res.data?.[model];
  },

  findOne<T extends iX>(model: string, params: {}) {
    // console.log('******* find!!!', model, idcmd);
    return () => this.fetches<T>(model, params);
  },

  finds<T extends iX>(model: string, params: {}) {
    return () => this.fetches<T>(model, params);
  },

  async fetches<T extends iX>(model: string, params: {}) {
    // console.log('ffffffsss>>>', model, params);
    const res = await this.get<T>(`${model}s?${new URLSearchParams(params)}`);
    return res.data?.[model];
  },

  save<T extends iX>(model: string, data: T) {
    try {
      return this.set<T>(model, data);
    } catch (Err) {
      console.log('Error on Store.save>>', Err);
      throw Err;
    }
  },

  async set<T extends idX>(model: string, data: T) {
    // const res = await api.put(`${model}/${data.id}`, { [model]: data });

    try {
      const res = await (data.id ? xapi.put : xapi.post)(
        `${model}/${data.id}${data.cmd ? `?cmd=${data.cmd}` : ''}`,
        {
          [model]: data,
        }
      );
      return res.data?.[model];
    } catch (Err) {
      console.log('Error on Store.save>>', Err);
      throw Err;
    }
  },

  async delete(model: string, id: number = 0) {
    console.log('store.delete!!', model, id);
    try {
      const { data } = await xapi.delete(`${model}/${id}`);
      return data;
    } catch (Err) {
      console.log('Error on Store.save>>', Err);
      throw Err;
    }
  },

  get<T>(uri: string) {
    return xapi.get<T>(uri);
  },

  async sendGet(model: string, params?: string) {
    console.log('store.sendGet!!', model, params);
    try {
      const uri = `${model}s/${params}`;
      const res = await this.get(uri);
      return res.data;
    } catch (Err) {
      console.log('Error on Store.sendGet>>', Err);
      throw Err;
    }
  },

  async sendPost(uri: string, body?: {}, config?: AxiosRequestConfig) {
    try {
      const res = await xapi.post(uri, body, config);
      return res.data;
    } catch (Err) {
      const err = Err as unknown as AxiosError;
      const { response } = err;
      console.log('Error on Store.post>>', response);
      if (response?.data) {
        const { code, message } = response.data.errors;
        throw new Error(`${code}: ${message}`);
      } else {
        throw Err;
      }
    }
  },

  async post<T extends iX>(model: string, data: T) {
    try {
      const uri = data.id ? `${model}s/${data.id}` : `${model}s/`;
      const res = await xapi.post(uri, {
        [model]: data,
      });
      return res.data;
    } catch (Err) {
      console.log('Error on Store.post>>', Err);
      throw Err;
    }
  },
};
