import React, { ChangeEvent } from 'react';

interface Props {
  label: string;
  isChecked: boolean;
  handleChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  elementId: string;
}

export const JadeCheck: React.FC<Props> = ({
  label = '',
  isChecked = false,
  handleChange = () => {},
  elementId = String(new Date().getTime()),
}) => (
  <>
    <input
      type='checkbox'
      id={elementId}
      checked={isChecked}
      onChange={handleChange}
    />
    <label htmlFor={elementId}>{label}</label>
  </>
);
