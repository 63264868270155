import axios, { AxiosError } from 'axios';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import io, { Socket } from 'socket.io-client';
import {
  DefaultEmp,
  DefaultLoginUser,
  IEmp,
  IEmpWithComp,
  ILoginUser,
} from '../models/emp';
import { DefaultItem, IItem } from '../models/item';
import { isEmpty } from '../utils/utils';
import { useAll, useMany } from './api';
import { Store } from './store';
import { callNative } from '../utils/native-connector';

type Action =
  | { type: 'login'; payload: { loginid: string; passwd: string } }
  | {
      type: 'signup';
      payload: { name: string; mobile: string; passwd: string; zone: number };
    }
  | { type: 'logout' }
  | { type: 'setUser'; payload: ILoginUser };

interface LoginProps {
  loginid: string;
  passwd: string;
  cid?: number;
  cname?: string;
  fcmtoken?: string;
}

const xapi = axios.create({
  baseURL: `/auth/hometel/htelmgr/0/`,
  withCredentials: true,
});

const initialState = DefaultLoginUser;

const reducer = (session: ILoginUser, action: Action) => {
  switch (action.type) {
    case 'login':
      return session;
    case 'signup':
      return session;
    case 'logout':
      return initialState;
    case 'setUser':
      return { ...action.payload, didLogin: !!action.payload?.user?.name };
    default:
      return session;
  }
};

interface ContextProps {
  session: ILoginUser;
  signAction: Dispatch<Action>;
  getAccounts: (params: LoginProps) => Promise<IEmpWithComp[] | []>;
  login: (params: LoginProps, errfn?: (err: AxiosError) => void) => void;
  logout: () => void;
  getItem: (id: number) => IItem;
  socket?: typeof Socket;

  fcmtoken: string;
  setFcmtoken: Dispatch<SetStateAction<string>>;

  myDrivers: IEmp[] | undefined;
}

const SessionContext = createContext<ContextProps>({
  session: initialState,
  signAction: () => {},
  getAccounts: () => Promise.resolve([]),
  login: () => {},
  logout: () => {},
  getItem: () => DefaultItem,
  fcmtoken: '',
  setFcmtoken: () => {},
  myDrivers: [],
});

const LOCAL_STORAGE_KEY = 'comp';

function SessionProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const [session, signAction] = useReducer(reducer, initialState);
  const [fcmtoken, setFcmtoken] = useState<string>('');

  const [items, setItems] = useState<IItem[]>([]);

  const [socket, setSocket] = useState<typeof Socket>();

  const { data: itemData } = useAll<IItem[]>('item');

  const { data: myDrivers, refetch: refetchDrivers } = useMany<IEmp[]>(
    'emp',
    {
      cmd: 'gets-my-drivers',
      zone: session.user.zone,
    },
    session.user?.id ? undefined : [],
    !!session.user?.id
  );

  const usableLocalStorage =
    typeof window !== 'undefined' && window.localStorage;

  const saveCid = (cid: number, cname: string) => {
    if (usableLocalStorage) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ cid, cname })
      );
    }
  };

  const getCid = () => {
    const comp =
      usableLocalStorage && window.localStorage.getItem(LOCAL_STORAGE_KEY);
    return comp ? JSON.parse(comp) : { cid: 0 };
  };

  const removeCid = () => {
    if (usableLocalStorage) {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  };

  const closeSocket = () => {
    console.log('close socket...', socket);
    if (socket) {
      socket.close();
    }
  };

  const initializeSocket = useCallback((myinfo: ILoginUser) => {
    console.log('initializeSocket>>>>>', myinfo);

    if (socket) {
      console.log('Already Exists socket.......>>', socket.connected);
      if (!socket.connected) socket.connect();
      return;
    }

    const socketUrl = `${process.env.REACT_APP_SERVER_URL}?prj=hometel&cid=${myinfo.user.cid}&adm=1`;
    console.log('session.socketUrl>>>>>', socketUrl);
    const sock = io(socketUrl, {
      transports: ['websocket'],
    });
    sock.on('connect', () => {
      console.log('session.Connect....', socketUrl, sock.id, new Date());
    });

    sock.on('message', (data: any) => {
      if (
        typeof data === 'object' &&
        'cid' in data &&
        Number(data.cid) === session.user.cid
      ) {
        console.log('socket.message>>', data);
        const { cmd } = data;
        if (cmd === 'refresh-mgr' || cmd === 'refresh-all') {
          callNative({ cmd: 'restartApp', payload: true });
        }
      }
    });

    sock.on('disconnect', (reason: string) => {
      console.log('session.DisConnect....', reason, new Date());
    });

    setSocket(sock);
  }, []);

  useEffect(() => {
    xapi.get<ILoginUser>('emp/myinfo').then((res) => {
      // console.log('myinfo.res>>>', res.data);
      if (res.data?.user && res.data?.user.auth !== 9) {
        const comp = getCid();
        const user = {
          ...res.data,
          user: {
            ...res.data.user,
            ...(comp || {}),
          },
        };
        console.log('myinfo.user > ', user);
        Store.setBaseURL(comp.cid);
        xapi.defaults.baseURL = `/auth/hometel/htelmgr/${comp.cid || 0}/`;
        signAction({ type: 'setUser', payload: user || initialState });
        initializeSocket(user);
      }
    });

    return closeSocket;
  }, [initializeSocket]);

  useEffect(() => {
    if (itemData) setItems(itemData);
  }, [itemData]);

  useEffect(() => {
    // console.log('fdfdfdff--------->>', session.didLogin, fcmtoken);
    if (
      typeof window !== 'undefined' &&
      window &&
      window.log &&
      typeof window.log === 'function'
    )
      window.log(`${session.user.id}-${session.didLogin}:${fcmtoken}`);

    if (session.didLogin) {
      if (fcmtoken)
        Store.set<IEmp>('emp', {
          ...DefaultEmp,
          ...session.user,
          fcmtoken,
          cmd: 'update-fcmtoken',
        });

      refetchDrivers();
    }
  }, [session.didLogin, fcmtoken]);

  const login = (params: LoginProps, errfn?: (err: AxiosError) => void) => {
    // console.log('login params > ', params);
    xapi.defaults.baseURL = `/auth/hometel/htelmgr/${params.cid || 0}/`;
    xapi
      .post<ILoginUser>('emp/login', {
        ...params,
        fcmtoken: isEmpty(fcmtoken) ? null : fcmtoken,
      })
      .then((res) => {
        // console.log('login.res>>>', res, params.cid, params.cname);

        Store.setBaseURL(params.cid || 0);
        const { user } = res.data;
        if (user && typeof params.cid === 'number' && params.cname) {
          if (user.auth !== 9) {
            const payload = {
              ...res.data,
              user: {
                ...res.data.user,
                cid: params.cid,
                cname: params.cname,
              },
            };
            initializeSocket(payload);
            saveCid(params.cid, params.cname);
            signAction({
              type: 'setUser',
              payload: payload || initialState,
            });
            const { attemptedPage } = session;
            console.log('🚀  attemptedPage:', attemptedPage);
            if (attemptedPage && attemptedPage !== '/login') {
              session.attemptedPage = '';
              navigate(attemptedPage);
            } else navigate('/home');
          } else {
            navigate('/waiting');
          }
        } else {
          setTimeout(() => navigate('/home'), 500);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error on Login:', error.response);
        if (errfn) errfn(error);
      });
  };

  const getAccounts = async (params: LoginProps) => {
    try {
      const empcomps = await Store.fetches<IEmpWithComp[]>('emp', {
        cmd: 'gets-all-account',
        ...params,
      });

      return empcomps;
    } catch (error) {
      console.error('Error on session.getAccounts > ', error);
      throw error;
    }
  };

  const logout = () => {
    xapi.get<ILoginUser>('emp/logout').then((res) => {
      Store.setBaseURL(0);
      console.log('loginout.res>>>', res);
      removeCid();
      signAction({ type: 'setUser', payload: initialState });
      navigate('/login');
    });
  };

  const getItem = (id: number) =>
    items.find((it) => it.id === id) || DefaultItem;

  return (
    <SessionContext.Provider
      value={{
        session,
        signAction,
        getAccounts,
        login,
        logout,
        // xapi,
        getItem,
        socket,
        fcmtoken,
        setFcmtoken,
        myDrivers,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

const useSession = () => useContext(SessionContext);

export { useSession, SessionProvider, xapi };
