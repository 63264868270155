import { PropsWithChildren, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Paper, Button, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import PersonIcon from '@material-ui/icons/Person';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import { getTelWithHyphen, getTelWithoutHyphen } from '../../utils/utils';
import { Modal } from '../HT/Modal';
import CkList from './CkList';
import { timeStr } from '../../utils/date-utils';

const useStyles = makeStyles({
  container: {
    marginBottom: 20,
  },
  summary: {
    padding: 0,
  },
  summaryBox: {
    width: '100%',
  },
  top: {
    backgroundColor: 'rgb(52, 65, 72)',
    padding: 5,
  },
  fr_gap10: {
    display: 'flex',
    gap: 10,
  },
  jc_space_between: {
    justifyContent: 'space-between',
  },
  status_visittm: {
    display: 'flex',
  },
  status: {
    display: 'inline-block',
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#fff',
  },
  customerInfo: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 5,
  },
  customer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  placesize: {
    flex: 1,
    justifyContent: 'center',
  },
  name: {
    flex: 1,
  },
  phone: {
    flex: 2,
    justifyContent: 'end',
  },
  col_white: {
    color: '#fff',
  },
  emp: {
    width: 42,
    height: 42,
    borderRadius: 21,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(26, 34, 50)',
    '& > p': {
      fontSize: '14px',
    },
  },
  addr: {
    padding: 5,
  },
  buttons: {
    display: 'flex',
    padding: 5,
  },
  fs_15: {
    fontSize: 15,
  },
  ls: {
    letterSpacing: 1.1,
  },
  icon: {
    width: '18px',
    height: '18px',
    marginRight: '2%',
  },
  bg_yellow: {
    backgroundColor: '#f59427',
  },
  bg_green: {
    backgroundColor: 'rgb(133, 192, 37)',
  },
});

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(190, 190, 190, 0.5)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: '0 0',
    '&$expanded': {
      margin: '0 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

interface IServiceProps {
  visittm: string;
  empname: string;
  custname: string;
  placesizeOrRemark: string;
  mobile: string;
  addr: string;
  state: number;
  type: 'gasa' | 'care';
  expandable: boolean;
  changeState: (state: number) => void;
  etm?: string;
}

export const ServiceCard = ({
  visittm,
  empname,
  custname,
  placesizeOrRemark,
  mobile,
  addr,
  state,
  type,
  expandable,
  changeState,
  etm,
  children,
}: PropsWithChildren<IServiceProps>) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(expandable && state === 0);
  const [confirmDone, setConfirmDone] = useState(false);
  const [openCheckList, setOpenCheckList] = useState(false);

  const close = () => {
    setExpanded(false);
    setConfirmDone(false);
    setOpenCheckList(false);
  };

  return (
    <>
      <Paper className={classes.container} variant='outlined'>
        <Accordion
          square
          expanded={expanded}
          onChange={() => setExpanded((prev) => !prev)}
        >
          <AccordionSummary className={classes.summary}>
            <div className={classes.summaryBox}>
              <div className={classes.top}>
                <div
                  className={clsx(classes.fr_gap10, classes.jc_space_between)}
                >
                  <div className={classes.fr_gap10}>
                    <span
                      className={clsx(
                        classes.status,
                        state >= 5 && state !== 6
                          ? classes.bg_green
                          : classes.bg_yellow
                      )}
                    />
                    <span>{visittm}</span>
                  </div>
                  <div className={classes.emp}>
                    <p>{empname.slice(0, 3)}</p>
                  </div>
                </div>
                <div
                  className={clsx(
                    classes.customerInfo,
                    classes.jc_space_between
                  )}
                >
                  <div className={clsx(classes.customer, classes.name)}>
                    <PersonIcon className={classes.icon} />
                    <span>{custname}</span>
                  </div>
                  {placesizeOrRemark && (
                    <div className={clsx(classes.customer, classes.placesize)}>
                      {type === 'gasa' ? (
                        <HomeIcon className={classes.icon} />
                      ) : (
                        <HotelIcon className={classes.icon} />
                      )}

                      <span>{placesizeOrRemark}</span>
                    </div>
                  )}
                  <div className={clsx(classes.customer, classes.phone)}>
                    <PhoneAndroidIcon className={classes.icon} />
                    <a
                      className={classes.col_white}
                      href={`tel: ${getTelWithoutHyphen(mobile)}`}
                      onClick={(evt) => {
                        evt.stopPropagation();
                      }}
                    >
                      {getTelWithHyphen(mobile)}
                    </a>
                  </div>
                </div>
              </div>

              <Divider />
              <div className={clsx(classes.addr, classes.fs_15, classes.ls)}>
                {addr}
              </div>
              <div className={clsx(classes.buttons, classes.jc_space_between)}>
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <a
                    className={classes.col_white}
                    href={`https://map.kakao.com/link/search/${addr}`}
                  >
                    길찾기
                  </a>
                </Button>
                <div className={classes.fr_gap10}>
                  {!etm ? (
                    <>
                      <Button
                        disabled={!!state}
                        size='small'
                        variant='contained'
                        color='secondary'
                        onClick={(evt) => {
                          evt.stopPropagation();
                          setOpenCheckList(true);
                        }}
                      >
                        철수(노쇼)
                      </Button>

                      <Button
                        disabled={!!state}
                        size='small'
                        variant='contained'
                        color='primary'
                        onClick={(evt) => {
                          evt.stopPropagation();
                          setConfirmDone(true);
                        }}
                      >
                        완료
                      </Button>
                    </>
                  ) : (
                    <Button
                      // disabled={!!state}
                      size='small'
                      variant='text'
                      color={state === 5 ? 'primary' : 'secondary'}
                    >
                      {`${state === 5 ? '완료' : '노쇼'} | ${timeStr(
                        new Date(etm)
                      )}`}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </AccordionSummary>
          {children && <AccordionDetails>{children}</AccordionDetails>}
        </Accordion>
        <Modal
          modalOpen={confirmDone}
          title='서비스 완료'
          msg='서비스를 완료하시겠습니까?'
          labelOK='완료'
          labelCancel='취소'
          isCancel={() => setConfirmDone(false)}
          isSubmit={() => {
            changeState(5);
            close();
          }}
        />
      </Paper>
      <CkList
        title='철수하기'
        itemids='9'
        open={openCheckList}
        handleNext={() => {
          changeState(6);
          close();
        }}
        handleClose={() => setOpenCheckList(false)}
      />
    </>
  );
};
