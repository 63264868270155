import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Login } from './components/Login/Index';
import { useSession } from './hooks/session';

/*
 * login 안되어 있으면 login 페이지로 이동!
 * @usage: export default withAuthentication(nextPage);
 */

// const withAuthentication = ({ children }: { children: ReactNode }) => {
const withAuthentication = (WrappedPage: FC) => {
  const RequiresAuthentication = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { session } = useSession();

    useEffect(() => {
      // console.log(
      //   '*******withAuthentication>>',
      //   session.didLogin,
      //   session.isLoading,
      //   router
      // );
      if (!session.didLogin) {
        session.attemptedPage = location.pathname;
        navigate('/login');
      }
    }, [session.didLogin]);

    if (!session.didLogin) return <Login />;

    return <WrappedPage />;
  };

  return RequiresAuthentication;
};

export default withAuthentication;
