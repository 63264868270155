// type nativeFn = (payload: {}) => void;
type boolFn = (param: boolean) => void;
type strFn = (param: string) => void;
type str2Fn = (param1: string, param2: string) => void;
type str3Fn = (param1: string, param2: string, param3: string) => void;
type str4Fn = (
  param1: string,
  param2: string,
  param3: string,
  param4: string
) => void;
type aryFn = (param: string[]) => void;

// type RouterParams = { router: string; params: string };

declare global {
  interface Window {
    AndroidTest: {
      ttt: strFn;
    };
    Android: {
      getDoorLockUuid: str3Fn; // id/pw/sno로 도어락의 uuid 구하기
      getDoorLockOtp: strFn;
      registerDoorLockUser: str4Fn;
      registerDoorLock: str3Fn;
      takeMv: strFn; // 시작 촬영 (with 알람 정책)
      closingRecord: strFn; // 종료 촬영
      takePhoto: strFn; // 사진 촬영 호출
      giveMvs: () => void; // 앱 종료 후 정보 다시 호출
      flushAll: () => void; // 앱 종료 후 정보 다시 호출
      clearSound: () => void; // 사운드 알림 초기화
      upload: str2Fn; // 영상 업로드 호출
      playSound: strFn;
      restartApp: boolFn; // 앱 재시작 (true: 캐시 삭제)
    };
    fromNative: strFn | null; // 사진 촬영 콜백, 푸시 클릭 시 네이티브에서 호출 당함!
    mvLocal: aryFn;
    mvlocal: aryFn;
    uploaded: strFn;
    onDoorLockOtp: strFn;
    onRegisterDoorLockUser: str2Fn;
    onRegisterDoorLock: str2Fn;
    onDoorLockUuid: strFn;
    log: strFn;
    setFCMToken: strFn;
  }
}

interface Props {
  cmd: string;
  payload?: any;
}

export function callNative({ cmd, payload = {} }: Props) {
  console.log('callNative>>', cmd, payload, JSON.stringify(payload));

  // const jPayload = JSON.stringify({ cmd, payload });
  const jPayload =
    typeof payload === 'object' ? JSON.stringify(payload) : payload;
  console.log('🚀 ~ jPayload', jPayload);
  window.log(`cmd>>>>>>${cmd}:${new Date()}`);

  if (cmd !== 'ttt' && !window.Android) return;

  switch (cmd) {
    case 'ttt':
      window.AndroidTest.ttt(jPayload);
      break;

    case 'showOTP':
      // window.Android.showOTP(jPayload);

      // window.Android.getDoorLockOtp('02d9e1b5-7359-498c-be17-ced2e4cb8e90');
      // window.Android.getDoorLockOtp('ce3b2211-0345-49ec-ba2c-3305a355b643');
      // window.Android.getDoorLockOtp('ed4f06c0-97b0-4097-9c4d-ab0278f5ff3e');

      // min_o0od@naver.com
      // window.Android.getDoorLockOtp('8aa72a9d-da91-4ad2-8eda-1df771d00402');

      // Piljung8@gmail.com
      // window.Android.getDoorLockOtp('01a311bf-f5d0-4427-939f-924709fa539b');

      window.Android.getDoorLockOtp(jPayload);

      // window.Android.registerDoorLockUser(
      //   'indiflex1@gmail.com',
      //   'hawk7788',
      //   '01088916693',
      //   '홈텔리어'
      // );

      // window.Android.registerDoorLock(
      //   '5864a638-fe79-4c4c-9f68-9cd4e4f6df34',
      //   'GTDLKW80008CC8F40F326B05581M4L',
      //   '테스트_doorlock'
      // );

      // window.Android.getDoorLockUuid(
      //   'qwepoir12345@gmail.com',
      //   'asdasd123',
      //   'GTDLKW80008CC8F40F326B05581M4L'
      // );

      // window.Android.getDoorLockUuid(
      //   'indiflex1@gmail.com',
      //   'hawk7878',
      //   'GTDLKW80008CC8F40F31E10555C06C'
      // );

      // window.Android.getDoorLockUuid(
      //   'cornmilk@naver.com',
      //   'Netgear1',
      //   'GTDLKW80008CC8F40ECC2F0578GSG2'
      // );

      break;

    case 'getDoorLockUuid':
      window.Android.getDoorLockUuid(
        payload.email,
        payload.kwtoken,
        payload.serialno
      );
      break;

    case 'registerDoorLockUser':
      window.Android.registerDoorLockUser(
        payload.email,
        payload.passwd,
        payload.mobile,
        payload.username
      );
      break;

    case 'registerDoorLock':
      window.Android.registerDoorLock(
        payload.userUuid,
        payload.serialno,
        payload.doorname
      );
      break;

    case 'startmv':
    case 'endmv':
      window.Android.takeMv(jPayload);
      break;

    case 'photo':
      window.Android.takePhoto(jPayload);
      break;

    case 'giveMvs':
      window.Android.giveMvs();
      break;

    case 'clearSound':
      window.Android.clearSound();
      break;

    case 'flushAll':
      window.Android.flushAll();
      break;

    case 'upload':
      window.Android.upload(payload.file, payload.url);
      break;

    case 'setSound':
      window.Android.playSound(jPayload);
      break;

    case 'restartApp':
      window.Android.restartApp(jPayload);
      break;

    default:
      break;
  }
}

export function setNativeConnector(fn: strFn) {
  console.log('setNativeConnector!!!', fn.name);
  window.fromNative = fn;
}

export function removeNativeConnector() {
  console.log('removeNativeConnector!!!');
  window.fromNative = null;
}
