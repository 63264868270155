import React from 'react';
import { useNavigate } from 'react-router';
import { Divider, IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Myfooter } from './Myfooter';
import { Myheader } from './Myheader';
import { MgrFolder } from './MgrFolder';

const useStyles = makeStyles({
  header: {
    paddingTop: 10,
    paddingLeft: 10,
    fontSize: '1.2em',
  },
});

interface Props {}

export const HTMy: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.header}>
        <IconButton onClick={() => navigate('/home')}>
          <ArrowBackIosIcon aria-hidden='true' />
        </IconButton>
        홈으로
      </div>
      <Myheader />
      <Divider style={{ padding: '0.3em' }} />
      <MgrFolder />
      <Myfooter />
    </>
  );
};
