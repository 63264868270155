import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { useSession } from '../../hooks/session';
import { ReactComponent as ClickLogo } from '../HT/clicklogo.svg';
import { ReactComponent as Logo } from '../HT/logo.svg';
import { CallDropDown } from './CallDropDown';
import { UserDropDown } from './UserDropDown';

interface Props {}

const useStyles = makeStyles({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  appbar: {
    background: '#1B2635',
    borderBottom: '1px solid #666',
  },
  logo: {
    width: '25px',
    height: '30px',
  },
  clicked: {
    borderBottom: '1px solid #fff',
  },
});

export const Navi: React.FC<Props> = () => {
  const { session } = useSession();
  const navigate = useNavigate();
  const classes = useStyles();
  const isHometelMgr = session.user.cid === 0 && session.user.zone !== 5;

  const isApproved = session.user.auth !== 9;

  const navItem = useMemo(
    () => [
      <IconButton
        key='logoButton'
        onClick={() => navigate(isApproved ? '/home' : '/waiting')}
      >
        {window.location.pathname === '/home' ? (
          <ClickLogo className={classes.logo} />
        ) : (
          <Logo className={classes.logo} />
        )}
      </IconButton>,
      <CallDropDown key='callDropDown' />,
    ],
    [navigate, window]
  );

  const reversedNavItem = useMemo(() => [...navItem].reverse(), [navItem]);

  return (
    <>
      <AppBar className={classes.appbar} position='sticky'>
        <Toolbar className={classes.item}>
          {/* {isHometelMgr && (
            <>
              <IconButton onClick={() => isApproved && navigate('/driverhome')}>
                {isApproved ? <LockOpenIcon /> : <HttpsIcon />}
              </IconButton>

              {isApproved && (
                <IconButton onClick={() => navigate('/chatlist')}>
                  <ChatBubbleOutlineOutlinedIcon />
                </IconButton>
              )}
            </>
          )} */}

          {isHometelMgr
            ? reversedNavItem
            : [
                ...navItem.slice(0, 1),
                <IconButton
                  key='month-schedule'
                  onClick={() => navigate('/month-schedule')}
                >
                  <TodayIcon
                    color={
                      window.location.pathname === '/month-schedule'
                        ? 'primary'
                        : 'action'
                    }
                  />
                </IconButton>,
                navItem.at(-1),
              ]}
          {isApproved && <UserDropDown />}
        </Toolbar>
      </AppBar>
    </>
  );
};
