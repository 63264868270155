import React from 'react';
import { useMany } from '../../hooks/api';
import { useSession } from '../../hooks/session';
import { IEmp } from '../../models/emp';
import { IFolder } from '../../models/folder';
import { Aco } from './Aco';
import { Checklist } from './Checklist';

interface Props {}

export const MgrFolder: React.FC<Props> = () => {
  const { data: folders } = useMany<IFolder[]>('folder', {
    cmd: 'finds-by-usetype',
    usetype: 3,
  });
  const { session } = useSession();
  const { data: loginuser, refetch: refetchLoginUser } = useMany<IEmp>('emp', {
    cmd: 'read',
    id: session.user.id,
  });
  // console.log('folder>>>>>>>>>>>>', folders);

  return (
    <>
      {folders?.map((f, idx) => (
        <div key={f.id}>
          {/* {f.id === 4 && loginuser?.role === 3 ? ( */}
          {f.id === 4 ? (
            <Checklist
              title={f.name}
              folder={folders[idx]}
              loginuser={loginuser}
            />
          ) : (
            f.id !== 4 && <Aco title={f.name} folder={folders[idx]} />
          )}
        </div>
      ))}
    </>
  );
};
