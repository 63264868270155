import {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useLayoutEffect,
} from 'react';
import clsx from 'clsx';

import {
  Stepper,
  Step,
  StepButton,
  StepContent,
  Paper,
  makeStyles,
  Button,
  Fab,
  CircularProgress,
  Theme,
  Fade,
  TextField,
} from '@material-ui/core';
import { setInterval } from 'timers';

import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { AxiosError } from 'axios';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useLocalStorage from '../../hooks/storage';
import { IUpfile } from '../../models/etc';
import { useMany, useSaver } from '../../hooks/api';
import CkList from '../Home/CkList';
import { ChatList } from '../Chat/Chatlist';
import { ChatRoom } from '../Chat/ChatRoom';
import { ChatInputs } from '../Chat/ChatInputs';
import { IEmp } from '../../models/emp';
import { useSession } from '../../hooks/session';
import { Modal } from './Modal';
import { Photodialog } from '../Chat/Photodialog';

interface Isteps {
  id: number;
  text: string;
  time?: Date | null;
  pw?: string | null;
}

const contents = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath: '/static/img/배경3.jpg',
  },
  {
    label: 'Bird',
    imgPath: '/static/img/그림8.png',
  },
  {
    label: 'Bali, Indonesia',
    imgPath: '/static/img/그림7.png',
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath: '/static/img/그림6.png',
  },
  {
    label: 'Goč, Serbia',
    imgPath: '/static/img/juliana-romao-xdu_0gljaQ8-unsplash.jpg',
  },
  {
    label: 'Goč, Serbias',
    imgPath: '/static/img/testingimg.jpg',
  },
];

const SlideSettings: Settings = {
  // autoplay: true,
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function TestingPlace() {
  // const classes = useStyles();

  const { session } = useSession();

  const { data: loginUser, refetch: refetchLoginUserInfo } = useMany<IEmp>(
    'emp',
    {
      cmd: 'read',
      id: session.user.id,
    }
  );

  const { data: userImg, refetch: refetchUserImg } = useMany<IUpfile>(
    'upfile',
    {
      cmd: 'read',
      id: loginUser?.profileimg,
    }
  );
  // console.log('profileimg확인>>>>>>>', userImg);

  const profileImgSaver = useSaver<IUpfile>(
    'upfile',
    (newpi) => {
      // console.log('newpi>>', newpi);
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const testRef = useRef(null);

  const [imga, setimga] = useState<any>([]);

  // const nowImageUrl = imga && URL.createObjectURL(imga[0]);

  const formData = new FormData();
  // formData.append('json', JSON.stringify({ content: postContent }));

  const [open, setopen] = useState(false);

  const onchange = () => setopen(!open);

  const qwe = 'qwe';
  const [q, setq] = useState<any>();
  // const [q, setq] = useState<any>('file:///Users/sini/Downloads/IMG_0592.JPG');

  useLayoutEffect(() => {
    const qq = document.getElementById('qwe');
    console.log(qq?.clientWidth, qq?.clientHeight);

    if (
      qq?.clientWidth &&
      qq?.clientHeight &&
      qq?.clientWidth > qq?.clientHeight
    ) {
      console.log('22222');
    } else console.log('11111');
  }, [qwe]);

  const [val, setval] = useState<any>();
  const [a, seta] = useState(false);
  const [time, settime] = useState<number>(180);
  const ww = useRef<any>();
  console.log(time);

  const cc = (t: number) => settime(t - 1);

  // const countdo = setTimeout(() => {
  //   cc(time);
  //   if (time > 0) {
  //     // cc(time - 1);
  //   }
  // }, 1000);

  const countdown = (t: number) => {
    setTimeout(() => {
      settime(t - 1);
      if (t > 1) {
        countdown(t - 1);
      }
    }, 1000);
  };

  const asd = useRef<any>(180);

  useEffect(() => {
    if (a)
      ww.current = setTimeout(() => {
        settime(time - 1);
      }, 1000);
    return () => {
      clearTimeout(ww.current);
    };
  });

  useEffect(() => {
    if (!a) settime(180);
  }, [a]);
  // useEffect(() => {
  //   if (a)
  //     ww.current = setInterval(() => {
  //       asd.current -= 1;
  //       settime(time - 1);
  //       ww.current -= 1;
  //     }, 1000);
  //   return () => clearInterval(ww.current);
  // }, [a]);

  // const xxx = setInterval(() => {
  //   if (time > 0) settime(time - 1);
  //   else clearInterval(xxx);
  // }, 1000);

  // const interval = setInterval(() => {
  //   if (a) settime(time - 1);
  //   else clearInterval(interval);
  // }, 1000);

  // const xx = setInterval(() => {
  //   settime(time - 1);
  //   if (time < 1) clearInterval(xx);
  // }, 1000);

  // useEffect(() => {
  //   if (a) {
  //     const xxx = setInterval(() => {
  //       settime(time - 1);
  //       if (time < 1) clearInterval(xxx);
  //     }, 1000);
  //   }
  //   return () => {
  //     settime(180);
  //   };
  // }, [a]);

  return (
    <>
      {/* <Slider {...SlideSettings}>
        {contents.map((ts: any, idx: number) => (
          <img
            key={idx.toString()}
            src={ts.imgPath}
            alt={ts.label}
            style={{
              // width: '100%',
              height: '100%',
            }}
            id={qwe}
          />
        ))}
      </Slider> */}
      <input
        type='file'
        // accept='image/*'
        id='uploadImg'
        // multiple
        // hidden
        // onChange={(evt: ChangeEvent<HTMLInputElement>) => {
        //   console.log(evt.currentTarget.files);
        //   console.log(
        //     evt.currentTarget.files &&
        //       URL.createObjectURL(evt.currentTarget.files[0])
        //   );
        //   // setimga(evt.currentTarget.files);
        //   const qw = [...imga];
        //   if (evt.currentTarget.files) {
        //     for (let a = 0; a < evt.currentTarget.files?.length; a += 1) {
        //       qw.push(evt.currentTarget.files[0]);
        //     }
        //     setimga(qw);
        //   }
        //   console.log(qw);
        // }}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          // setval(evt.currentTarget.files);
          console.log('value>>>>', evt.currentTarget.value);
          console.log(evt.currentTarget.files);
          const dd = document.getElementById('uploadImg');
          console.log(dd);
          // setq(evt.currentTarget.value);
          const fReader = new FileReader();
          if (evt.currentTarget.files) {
            // fReader.readAsDataURL(evt.currentTarget.files[0]);
            // setq(URL.createObjectURL(evt.currentTarget.files[0]));
            // fReader.addEventListener('load', parser.bind(fReader));
            // fReader.readAsText(evt.currentTarget.files[0], 'utf-8');
            console.log(
              fReader.readAsText(evt.currentTarget.files[0], 'utf-8')
            );
          }
          // fReader.onloadend = function (evnt: ProgressEvent<FileReader>) {
          //   setq(evnt.target?.result);
          // };
          // console.log(fReader.readAsDataURL);
          // console.log('qq>>>', q);
        }}
      />
      <div>{q}</div>
      <div style={{ margin: '10em 2em' }}>
        {/* <img src={q} alt='w' id='qweqweqwe' style={{ maxWidth: '80vw' }} /> */}
        {/* <video muted width='640' height='360' controls>
          <source src={q} type='video/mp4' />
          sd
        </video> */}
      </div>
      {/* <TextField
        value={q}
        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
          setq(evt.target.value)
        }
        fullWidth
      /> */}
      <button onClick={() => seta(!a)}>090909</button>
      <button onClick={() => countdown(time)}>1111</button>
      <button
        onClick={() => {
          // clearTimeout(countdown);
          console.log('222');
          settime(180);
        }}
      >
        2222
      </button>
      <div>{time}</div>
      <div>{asd.current}</div>
    </>
  );
}
