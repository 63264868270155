export interface IUpfile {
  id: number;
  name: string;
  sysname: string;
  path: string;
  mime: string;
  isdel?: boolean;
  fsize?: number;
  remark?: string;
}

export interface ICode {
  id: number;
  createdate?: string;
  workdate?: string;
  codegrp: ICodeGrp;
  val: string;
  isdel?: boolean;
  seq?: number;
  flag?: string;
  enval?: string;
  jpval?: string;
  etc1?: string;
  etc2?: string;
}

export interface ICodeGrp {
  id: number;
  createdate?: string;
  workdate?: string;
  name: string;
  remark?: string;
  isdel?: boolean;
  sys?: boolean;
}

export interface IHoliday {
  id: number;
  createdate?: string;
  workdate?: string;
  ymd: string;
  hname: string;
}

export const DefaultHoliday: IHoliday = {
  id: 0,
  ymd: '',
  hname: '',
};

export const DefaultCodeGrp: ICodeGrp = {
  id: 0,
  name: '',
  isdel: false,
  sys: false,
};

export const DefaultCode: ICode = {
  id: 0,
  codegrp: DefaultCodeGrp,
  val: '',
  isdel: false,
  seq: 0,
};

export const DefaultUpfile: IUpfile = {
  id: 0,
  name: '',
  sysname: '',
  path: '',
  mime: '',
  isdel: false,
  fsize: 0,
};

export const upfileLink = (upfile: IUpfile, host: string = '') => {
  if (upfile.path.startsWith('/secure'))
    return `${host}${upfile.path}/${upfile.sysname}`;

  return `${host}/static${upfile.path}/${upfile.sysname}${host}${upfile.path}/${upfile.sysname}`;
};

export type TAreaCode =
  | '010'
  | '02'
  | '031'
  | '030'
  | '050'
  | '060'
  | '070'
  | '080'
  | '0507';

export const AREA_CODES = [
  '010',
  '02',
  '031',
  '030',
  '050',
  '060',
  '070',
  '080',
  '0507',
];
