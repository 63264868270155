import { DefaultCustAddr, ICustAddr } from './customer';

export interface IItem {
  id: number;
  itemtop: number;
  name: string;
  price?: number;
  isoff?: boolean;
  ishide?: boolean;
  seq?: number;
  titleimg?: string;
  weight?: number;
  remark?: string;
  dcprice?: number;
  basecnt?: number;
  maininfo?: string;
  spendmin?: number;
  subinfo?: string;
  abbr?: string;
}

export const DefaultItem: IItem = {
  id: 0,
  itemtop: 0,
  name: '',
  price: 0,
  isoff: false,
  ishide: false,
  seq: 0,
  weight: 0,
  dcprice: 0,
  basecnt: 0,
  spendmin: 0,
};

interface IItemInfo {
  [k: string]: {
    name: string;
    color: string;
    collect?: boolean;
  };
}
export const ItemInfo: IItemInfo = {
  '1:0': { name: '청', color: '#4a6791' },
  '1:1': { name: '청', color: '#4a6791' },
  '2:0': { name: '욕', color: '#42cc1a' },
  '2:1': { name: '욕', color: '#42cc1a' },
  '3:0': { name: '베', color: '#c5cb2f' },
  '4:0': { name: '타', color: '#847828' },
  '3:1': { name: '베', color: '#c5cb2f', collect: true },
  '4:1': { name: '타', color: '#847828', collect: true },
  '5:0': { name: '부', color: '#e3a136' },
  '6:0': { name: '가', color: '#5d2bc2' },
  '7:0': { name: '도', color: '#e35936' },
  '8:0': { name: '가사', color: '#2e6ac9' },
};

export const ServiceStates = [
  { id: 0, text: '-', color: 'blue' },
  { id: 1, text: '-', color: 'blue' },
  { id: 2, text: '-', color: 'red' },
  { id: 3, text: '10분전', color: 'rgb(76, 175, 80)' },
  { id: 4, text: '서비스중', color: 'rgb(63, 81, 181)' },
  { id: 5, text: '서비스완료', color: 'rgb(97, 97, 97)' },
  { id: 9, text: '취소!!', color: 'rgb(253, 216, 53)' },
];

export const serviceState = (state?: number) =>
  ServiceStates.find((st) => st.id === state);

// export const itemidsName = (itemids: string) => {
//   [1,2,3,4].map((item) => )
// }
