import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useMany, useSaver } from '../../hooks/api';
import { IPlace } from '../../models/customer';
import { IBillSvc } from '../../models/bill';
import ImageField from './ImageField';
import { useSession } from '../../hooks/session';

const useStyles = makeStyles({
  root: {
    margin: '4% auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
  },
  inputForm: {
    paddingLeft: '3%',
    paddingRight: '3%',
    // marginBottom: '3%',
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '14px',
      color: '#3F51B5',
      textAlign: 'right',
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      height: '.9vh',
      margin: 0,
    },
  },
  icon: {
    marginLeft: '15%',
    marginRight: '7%',
    width: '24px',
    height: '24px',
  },
  endicon: {
    marginLeft: '-50%',
    width: '16px',
    height: '16px',
  },
  btn: {
    paddingLeft: '3%',
    paddingRight: '3%',
    display: 'flex',
  },
  edit: {
    color: '#E53935',
    textAlign: 'right',
    margin: '2%',
  },
  editcontent: {
    fontSize: '.9em',
  },
  cancelbtn: {
    color: 'black',
    marginRight: '1em',
  },
});

interface Props {
  billservice: IBillSvc;
  refetchBillSvc: () => void;
}

interface list {
  id: string;
  defaultValue: string | undefined;
  placeholder?: string;
  adornment: JSX.Element;
  ref: any;
}

export const BuildingSetting: React.FC<Props> = ({
  billservice,
  refetchBillSvc,
}) => {
  const { waste, enterinfo, parking, placeid } = billservice;
  const classes = useStyles();
  const { socket } = useSession();
  const wasteRef = useRef<any>();
  const parkingRef = useRef<any>();
  const enterinfoRef = useRef<any>();

  const [editOn, setEditOn] = useState<boolean>(false);
  // const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);

  // const waste = wasteRef.current ? wasteRef.current.value : '';
  // const parking = parkingRef.current ? parkingRef.current.value : '';
  // const enterinfo = enterinfoRef.current ? enterinfoRef.current.value : '';

  // const checkValuesForButton = () => {
  //   setShowSubmitButton(
  //     wasteRef.current.value ||
  //       parkingRef.current.value ||
  //       enterinfoRef.current.value
  //   );
  // };
  const { data: place, refetch: refetchPlace } = useMany<IPlace>('place', {
    cmd: 'read',
    id: placeid,
  });

  const placeSaver = useSaver<IPlace>(
    'place',
    (newp) => {
      console.log('newplace>>>>', newp);
      refetchPlace();
      refetchBillSvc();
      socket?.emit('sync', {
        ...newp,
        model: 'place',
      });
    },
    (err: AxiosError) => {
      console.error(err.response?.data);
    }
  );

  const isEditting = () => {
    setEditOn(!editOn);
    console.log('editing>>>>', editOn);
  };
  const updatePlace = () => {
    if (place) {
      const newp = {
        ...place,
        waste: wasteRef.current.value,
        parking: parkingRef.current.value,
        enterinfo: enterinfoRef.current.value,
      };
      placeSaver.mutate(newp);
      isEditting();
    }
  };
  const updatePlaceImg = (
    type: 'midmv' | 'addrimg' | 'placeimg' | 'enterimg',
    upfileId: number | undefined
  ) => {
    if (place) {
      placeSaver.mutate({ ...place, [type]: upfileId });
    }
  };

  // const [infoList, setInfoList] = useState<list[]>([
  const infoList = [
    {
      id: 'enterinfo',
      defaultValue: enterinfo,
      placeholder: '중앙현관 위치',
      adornment: (
        <InputAdornment position='start'>
          <MeetingRoomOutlinedIcon className={classes.icon} />
          <span>중앙현관</span>
        </InputAdornment>
      ),
      ref: enterinfoRef,
    },
    {
      id: 'waste',
      defaultValue: waste,
      placeholder: '분리수거',
      adornment: (
        <InputAdornment position='start'>
          <DeleteOutlinedIcon className={classes.icon} />
          <span>분리수거장</span>
        </InputAdornment>
      ),
      ref: wasteRef,
    },
    {
      id: 'parking',
      defaultValue: parking,
      placeholder: '주차 정보',
      adornment: (
        <InputAdornment position='start'>
          <DriveEtaOutlinedIcon className={classes.icon} />
          <span>주차 정보</span>
        </InputAdornment>
      ),
      ref: parkingRef,
    },
  ];

  return (
    <div className={classes.root}>
      {infoList.map((info) => (
        <div className={classes.container} key={info.id}>
          <TextField
            fullWidth
            id={info.id}
            defaultValue={info.defaultValue}
            placeholder={info.placeholder}
            variant='outlined'
            className={classes.inputForm}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            InputProps={{
              inputRef: info.ref,
              startAdornment: info.adornment,
              readOnly: !editOn,
              endAdornment: editOn && (
                <InputAdornment position='end'>
                  <ClearIcon
                    className={classes.endicon}
                    aria-hidden='true'
                    // onClick={() => {
                    //   const newInfo = [...infoList];
                    //   newInfo[0].defaultValue =
                    //     custAddr && custAddr[0].place.enterinfo;
                    //   setInfoList([...newInfo]);
                    // }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      ))}
      {!editOn && (
        <div className={classes.edit}>
          <IconButton
            onClick={isEditting}
            size='small'
            className={classes.editcontent}
          >
            <EditOutlinedIcon />
            변경
          </IconButton>
        </div>
      )}
      {editOn && (
        <div className={classes.btn}>
          <Button
            fullWidth
            color='inherit'
            className={classes.cancelbtn}
            variant='contained'
            onClick={() => setEditOn(!editOn)}
          >
            취소
          </Button>
          <Button
            fullWidth
            color='secondary'
            variant='contained'
            onClick={() => updatePlace()}
          >
            변경
          </Button>
        </div>
      )}

      <ImageField
        type='placeimg'
        bsId={billservice.id}
        imglink={billservice.placelink}
        updater={updatePlaceImg}
      >
        <ApartmentOutlinedIcon />
        <span>건물 사진</span>
      </ImageField>

      <ImageField
        type='enterimg'
        bsId={billservice.id}
        imglink={billservice.enterlink}
        updater={updatePlaceImg}
      >
        <MeetingRoomOutlinedIcon />
        <span>건물 입구</span>
      </ImageField>

      {/* {showSubmitButton && ( */}
    </div>
  );
};
